import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { MEETINGDETAILLPAGE, PROMODETAILLPAGE } from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";

import BackButtom from "components/BackButtom";
import { Col, Row } from "react-bootstrap";

import { useMeetingRecord } from "store/actions";

const MeetingHistoryDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [data, setData] = useState();
  const [activeTab, setActiveTab] = useState("login");
  const { state } = useLocation();
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
  });
  const toggleTab = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    fetchData(filter);
  }, []);
  const fetchData = (filter) => {
    const payload = {
      ...filter,
      page: filter?.pageNo,
      record_count: filter?.limit,
      meeting_id: id,
    };
    try {
      const callback = (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setData(res);
        }
      };
      dispatch(useMeetingRecord({ data: payload, callback }));
    } catch (error) {
      console.log(error);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  console.log(data, "data========<>");

  return (
    <>
      {data === "true" && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={MEETINGDETAILLPAGE.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <BackButtom />
          <h1>{MEETINGDETAILLPAGE.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.doctor}>Meeting record</Link>
            </div>
            <div className="breadcrumb-item">
              {MEETINGDETAILLPAGE.CURRENT_MODULE}
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">
                    {PROMODETAILLPAGE.TOP_CONTENT}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <Row className="px-4 justify-content-center ">
            <Col className=" col-md-12 my-2 h-100 w-100 card rounded-lg">
              <div className="d-flex justify-content-start border-bottom">
                <h6 className=" p-2 ">Star Pilot Chat</h6>
              </div>
              <div className="d-flex ">
                <div className="col-md-12 my-2 h-100 w-100">
                  <div className="py-3">
                    <div
                      className="bg-light rounded p-3 overflow-auto search-sec"
                      style={{ maxHeight: 400 }}
                    >
                      {data?.starPilotChatHistory.length > 0 &&
                        data?.starPilotChatHistory?.map((item) => (
                          <div className="py-2">
                            <div className=" ans-sub-block">
                              <div className="aiicon d-flex gap-2 justify-content-start align-items-center ">
                                <img
                                  style={{ width: "60px", height: "60px" }}
                                  src={"/assets/img/logo.png"}
                                  alt="profile"
                                  className="profile"
                                />
                                <p
                                  className="mb-2 font_16  font_600"
                                  style={{ color: "#227fd5" }}
                                >
                                  Monte Sereno
                                </p>
                              </div>
                              <div className="bg-[#040404] rounded-lg  mb-2 overflow-auto subblock py-2">
                                <pre
                                  className="mb-2 text-justify  white-space-pre"
                                  style={{ whiteSpace: "pre-wrap" }}
                                >
                                  {item}
                                </pre>
                              </div>
                            </div>
                          </div>
                        ))}
                      {data?.starPilotChatHistory?.length <= 0 && (
                        <p className="m-0 text-center">No Data Found!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start border-bottom">
                <h6 className=" p-2 ">Suggestion Box</h6>
              </div>
              <div className="d-flex ">
                <div className="col-md-12 my-2 h-100 w-100">
                  <div className="py-3">
                    <div
                      className="bg-light rounded p-3 overflow-auto search-sec"
                      style={{ maxHeight: 400 }}
                    >
                      {data?.suggestionBoxChatHistory.length > 0 &&
                        data?.suggestionBoxChatHistory?.map((item) => (
                          <div className="py-2">
                            <div className=" ans-sub-block">
                              <div className="aiicon d-flex gap-2 justify-content-start align-items-center ">
                                <img
                                  style={{ width: "60px", height: "60px" }}
                                  src={"/assets/img/logo.png"}
                                  alt="profile"
                                  className="profile"
                                />
                                <p
                                  className="mb-2 font_16  font_600"
                                  style={{ color: "#227fd5" }}
                                >
                                  Monte Sereno
                                </p>
                              </div>
                              <div className="bg-[#040404] rounded-lg  mb-2 overflow-auto subblock py-2">
                                <pre
                                  className="mb-2 text-justify  white-space-pre"
                                  style={{ whiteSpace: "pre-wrap" }}
                                >
                                  {item}
                                </pre>
                              </div>
                            </div>
                          </div>
                        ))}
                      {data?.suggestionBoxChatHistory?.length <= 0 && (
                        <p className="m-0 text-center">No Data Found!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start border-bottom">
                <h6 className=" p-2 ">Prescription</h6>
              </div>
              <div className="card-body doctor-table-card">
                <div className="table-responsive">
                  <table className="doctor-list-table table table-bordered">
                    <thead>
                      <tr>
                        <th scope="row">Page</th>
                        <th>Name of Drug</th>
                        <th>Strength</th>
                        <th>Strength Type</th>
                        <th>Unit</th>
                        <th>Form</th>
                        <th>Dose</th>
                        <th>Frequency</th>
                        <th>Intakes or Applications</th>
                        <th>Route</th>
                        <th>Refills</th>
                        <th>Quantity</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.prescripitonMain?.length > 0 &&
                        data?.prescripitonMain?.map((item, index) => {
                          return (
                            <tr id={item.id} key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>
                                <h3 className="doc-details">
                                  {item.medication_name}
                                </h3>
                              </td>
                              <td>{item.strength}</td>
                              <td>{item.strength_type}</td>
                              <td>{item.units}</td>
                              <td>{item.form}</td>
                              <td>{item.dose}</td>
                              <td>{item.frequency}</td>
                              <td>{item.intake}</td>
                              <td>{item.route}</td>
                              <td>{item.refills}</td>
                              <td>{item.quantity}</td>
                              <td>{item.comments}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex justify-content-start border-bottom">
                <h6 className=" p-2 ">Temporary Prescription</h6>
              </div>
              <div className="card-body doctor-table-card">
                <div className="table-responsive">
                  <table className="doctor-list-table table table-bordered">
                    <thead>
                      <tr>
                        <th scope="row">Page</th>
                        <th>Name of Drug</th>
                        <th>Strength</th>
                        <th>Strength Type</th>
                        <th>Unit</th>
                        <th>Form</th>
                        <th>Dose</th>
                        <th>Frequency</th>
                        <th>Intakes or Applications</th>
                        <th>Route</th>
                        <th>Refills</th>
                        <th>Quantity</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.prescripitonTemp?.length > 0 &&
                        data?.prescripitonTemp?.map((item, index) => {
                          return (
                            <tr id={item.id} key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>
                                <h6 className="doc-details">
                                  {item.medicine_name}
                                </h6>
                              </td>
                              <td>{item.strength}</td>
                              <td>{item.strength_type}</td>
                              <td>{item.unit}</td>
                              <td>{item.form || item.medicine_type}</td>
                              <td>{item.dose}</td>
                              <td>{item.frequency}</td>
                              <td>
                                {item.intakes_applications || item.intake}
                              </td>
                              <td>{item.route}</td>
                              <td>{item.refills}</td>
                              <td>{item.quantity}</td>
                              <td>{item.comments}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};
export default MeetingHistoryDetail;
