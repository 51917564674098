import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";

const STATUS = {
  active: "Active",
  inactive: "Inactive",
  blocked: "Blocked",
  deleted: "Deleted",
  pending: "Pending",
};
const Columns = ({
  onCliclNavigate,
  handleViewUser,
  handleEdithandler,
  currentPage,
  pageSize,
  handleUserStatus,
  deleteHandler,
}) => [
  {
    dataField: "",
    text: "SrNo",
    formatter: (cell, row, rowIndex) => (
      <span className="text-capitalize cursor-pointer">
        {(currentPage - 1) * pageSize + (rowIndex + 1)}
      </span>
    ),
  },
  {
    dataField: "first_name",
    text: "Name",
    formatter: (_, row) => (
      <span className="text-capitalize cursor-pointer">{row.full_name}</span>
    ),
  },
  {
    dataField: "email1",
    text: "Email",
    formatter: (_, row) => (
      <span className="text-capitalize cursor-pointer">
        {row?.email || "--"}
      </span>
    ),
  },

  {
    dataField: "patient_code",
    text: "Patient Code",
    formatter: (_, row) => (
      <span className="text-capitalize cursor-pointer">
        {row?.patient_code || "--"}
      </span>
    ),
  },

  {
    dataField: "phone_no",
    text: "Phone No",
    formatter: (_, row) => (
      <span className="text-capitalize cursor-pointer">
        {row?.phone_no && row?.country_code
          ? `${row.country_code} ${row.phone_no}`
          : "--"}
      </span>
    ),
  },
  {
    text: "Created At",
    dataField: "createdAt",
    formatter: (_, row) => DATE_FORMAT(row.createdAt),
  },
  {
    text: "Status",
    dataField: "status",
    formatter: (_, row) => (
      <span>
        {row?.status && row.status == "1" ? (
          <button
            className="fas fa-toggle-on"
            style={{
              fontSize: "20px",
              color: "green",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Active"
            onClick={() => handleUserStatus(row, "0")}
          ></button>
        ) : (
          <button
            className="fas fa-toggle-off"
            style={{
              fontSize: "20px",
              color: "red",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Inactive"
            onClick={() => handleUserStatus(row, "1")}
          ></button>
        )}
      </span>
    ),
  },

  {
    text: "Action",
    dataField: "",
    formatter: (_, row) => (
      <>
        <button
          className="fas fa-pen"
          style={{
            fontSize: "16px",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="Edit"
          onClick={() => handleEdithandler(row)}
        ></button>
        <button
          className="fa fa-eye"
          style={{
            fontSize: "20px",
            color: "green",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="View"
          onClick={() => handleViewUser(row)}
        ></button>
        <button
          className="fa fa-trash-alt"
          style={{
            fontSize: "20px",
            color: "red",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="delete"
          onClick={() => deleteHandler(row?.id)}
        ></button>
      </>
    ),
  },
];
export default Columns;
