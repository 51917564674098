import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { PATIENTDETAILLPAGE, PROMODETAILLPAGE, PROMPTDETAIL } from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";
import Table from "components/Table";
import Columns from "./Columns";
import { PROMOGET_DEATILS } from "store/promo/actions";
import BackButtom from "components/BackButtom";
import { Badge, Col, Row } from "react-bootstrap";
import { userHistoryList } from "store/actions";
const PromptDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [data, setData] = useState();
  const { state } = useLocation();
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
  });
  const [activeTab, setActiveTab] = useState("login");
  const toggleTab = (tab) => {
    setActiveTab(tab);
  };
  console.log(id, "ididdididididdidi");
  useEffect(() => {
    fetchData(filter);
  }, [id]);
  const fetchData = () => {
    const payload = {
      ...filter,
      page: filter?.pageNo,
      record_count: filter?.limit,
      user_id: id,
    };
    try {
      const callback = (err, res) => {
        if (err) {
          console.log(err);
        } else {
          console.log(res, "response ------===--=-=---=----=");
          setData(res);
        }
      };
      dispatch(userHistoryList({ data: payload, callback }));
    } catch (error) {
      console.log(error);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  console.log(data?.users, "data=======<>");
  // console.log(state?.row, "row==================<>");
  return (
    <>
      {data === "true" && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={PROMPTDETAIL.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <BackButtom />
          <h1>{PROMPTDETAIL.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.prompt}>Patient</Link>
            </div>
            <div className="breadcrumb-item">
              {PROMPTDETAIL.CURRENT_MODULE}{" "}
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">
                    {PROMPTDETAIL.TOP_CONTENT}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <Row className="px-4 justify-content-center">
            <Col className=" col-md-12 my-2 h-100  card rounded-lg">
              <div className="d-flex justify-content-center border-bottom">
                <h6 className=" p-2 ">Prompt Details</h6>
              </div>
              <div className="d-flex ">
                <div className="col-md-12 my-2 h-100 ">
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Prompt</p>
                    <span className="py-1">Prompt Data</span>
                  </div>
                  <div className="d-flex justify-content-between gap-2  border-bottom">
                    <p className="fw-bolder">Description</p>
                    <span className="py-1"> Discription data </span>
                  </div>
                  {/* <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Doctor Code </p>
                    <span className="py-1">{state?.row?.doctor_code}</span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Patient Code</p>
                    <span className="py-1">{state?.row?.patient_code}</span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Age</p>
                    <span className="py-1">
                      {state?.row?.age ? state?.row?.age : "---"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">License No</p>
                    <span className="py-1">{state?.row?.license_no}</span>
                  </div> */}
                </div>
                {/* <div className="col-md-6 my-2 h-100 w-100 ">
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Country Name</p>
                    <span className="py-1">
                      {state?.row?.country ? state?.row?.country : "---"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between gap-2  border-bottom">
                    <p className="fw-bolder">State</p>
                    <span className="py-1">
                      {state?.row?.state ? state?.row?.state : "---"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">City </p>
                    <span className="py-1">
                      {state?.row?.city ? state?.row?.city : "---"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Country Code</p>
                    <span className="py-1">{state?.row?.country_code}</span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Phone No</p>
                    <span className="py-1">{state?.row?.phone_no}</span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Address</p>
                    <span className="py-1">
                      {state?.row?.address ? state?.row?.address : "---"}
                    </span>
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>

        

          {/* <Table
            data={data?.promoAppliedUsers}
            columns={Columns({})}
            setFilter={setFilter}
            totalCount={data?.totalcount}
            // handleTableChange={handlePageChange}
            filter={filter}
            // toggleConfirmModal={handleUserStatus}
          /> */}
        </div>
      </section>
    </>
  );
};
export default PromptDetail;
