// import { DATE_FORMAT } from "helpers/common";
// import Badge from "react-bootstrap/Badge";
// import React from "react";
// import { Link } from "react-router-dom";
// // const { count, sortedData } = row.userProfiles.reduce((acc, record) => {
// //   // Increment count if linkedQrCodeId is not null
// //   if (row.linkedQrCodeId) {
// //     acc = acc + 1;
// //   }
// // }, 0);
// const STATUS = {
//   active: "Active",
//   inactive: "Inactive",
//   blocked: "Blocked",
//   deleted: "Deleted",
//   pending: "Pending",
// };
// // console.log(row,"row data")
// const Columns = ({ handleUserStatus, handleEditHandler, handleViewUser }) => [
//   // const { count, sortedData } = row.userProfiles.reduce((acc, record) => {
//   //   // Increment count if linkedQrCodeId is not null
//   //   if (row.linkedQrCodeId) {
//   //     acc = acc + 1;
//   //   }

//   //   return acc;
//   // }, 0);
//   // {
//   //   dataField: "firstName",
//   //   text: "First Name",
//   //   formatter: (_, row) => (
//   //     <span className="text-capitalize">{row.firstName}</span>
//   //   ),
//   // },
//   // {
//   //   dataField: "lastName",
//   //   text: "Last Name",
//   //   formatter: (_, row) => (
//   //     <span className="text-capitalize">{row.lastName}</span>
//   //   ),
//   // },
//   {
//     dataField: "email",
//     text: "Email",
//     formatter: (_, row) => <span className="text">{row.email}</span>,
//   },
//   {
//     dataField: "profileid",
//     text: "Profiles",
//     formatter: (_, row) => (
//       <span className="text-capitalize">{row.userProfiles.length}</span>
//     ),
//   },
//   {
//     dataField: "linkedQrCodeId",
//     text: "Linked Qr",
//     formatter: (_, row) => (
//       <span className="text-capitalize">{getLinkedQr(row.userProfiles)}</span>
//     ),
//   },
//   {
//     dataField: "contactInfo",
//     text: "Contact Information",
//     formatter: (_, row) => (
//       <span className="text">
//         {row?.mobileNumber
//           ? `${row?.mobileNumber}`
//           : row?.email}
//       </span>
//     ),
//   },
//   {
//     text: "Created At",
//     dataField: "dateCreated",
//     formatter: (_, row) => DATE_FORMAT(row.dateCreated)
//   },
//   {
//     text: "Last login",
//     dataField: "loginDateTime",
//     formatter: (_, row) => row?.logoutDateTime ? DATE_FORMAT(row?.logoutDateTime) :row?.loginDateTime ? DATE_FORMAT(row?.loginDateTime): "---" ,
//   },
//   {
//     text: "Status",
//     dataField: "status",
//     formatter: (cellContent, row) => (
//       <Badge
//         className={`text-capitalize font-size-13 badge-soft-${
//           row.status === "pending" ? "warning" : "success"
//         }`}
//         color={row.badgeClass}
//       >
//         {STATUS[row.status]}
//       </Badge>
//     ),
//   },
//   {
//     text: "Action",
//     dataField: "status",
//     formatter: (_, row) => (
//       <>
//         {row?.status && row?.status == "active" ? (
//           <button
//             className="fas fa-toggle-on"
//             style={{
//               fontSize: "20px",
//               color: "green",
//               backgroundColor: "#fff",
//               border: "none",
//             }}
//             title="Active"
//             onClick={() => handleUserStatus(row._id, "inactive")}
//           ></button>
//         ) : (
//           <button
//             className="fas fa-toggle-off"
//             style={{
//               fontSize: "20px",
//               color: "red",
//               backgroundColor: "#fff",
//               border: "none",
//             }}
//             title="inactive"
//             onClick={() => handleUserStatus(row._id, "active")}
//           ></button>
//         )}
//         {/* <button
//           className="fas fa-pen"
//           style={{
//             fontSize: "16px",
//             backgroundColor: "#fff",
//             border: "none",
//           }}
//           title="Edit"
//           onClick={() => handleEditHandler(row)}
//         ></button> */}
//         {
//           <button
//             className="fa fa-eye"
//             style={{
//               fontSize: "20px",
//               color: "green",
//               backgroundColor: "#fff",
//               border: "none",
//             }}
//             title="View"
//             onClick={() => handleViewUser(row._id)}
//           ></button>
//         }
//       </>
//     ),
//   },
// ];
// export default Columns;
// const getLinkedQr = (userProfiles) => {
//   return userProfiles.reduce((acc, record) => {
//     // Increment count if linkedQrCodeId is not null
//     if (record.linkedQrCodeId) {
//       acc = acc + 1;
//     }
//     return acc;
//   }, 0);
// };


import React from "react";
import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";

import { customSortCaret } from "constants";

const STATUS = {
  active: "Active",
  inactive: "Inactive",
  blocked: "Blocked",
  deleted: "Deleted",
  pending: "Pending",
};

const Columns = ({ handleUserStatus, handleEditHandler, handleViewUser }) => [
  {
    dataField: "email",
    text: "Email",
    sort: true, 
    sortCaret: customSortCaret,
    formatter: (_, row) => <span className="text">{row.email}</span>,
  },
  {
    dataField: "profileid",
    text: "Profiles",
    sort: false, 
    formatter: (_, row) => (
      <span className="text-capitalize">{row.userProfiles.length}</span>
    ),
  },
  {
    dataField: "linkedQrCodeId",
    text: "Linked Qr",
    sort: false, 
    formatter: (_, row) => (
      <span className="text-capitalize">{getLinkedQr(row.userProfiles)}</span>
    ),
  },
  {
    dataField: "contactInfo",
    text: "Contact Information",
    sort: false, 
    formatter: (_, row) => (
      <span className="text">
        {row?.mobileNumber ? `${row?.mobileNumber}` : row?.email}
      </span>
    ),
  },
  {
    text: "Created At",
    dataField: "dateCreatedUtc",
    sort: true, 
    sortCaret: customSortCaret,
    formatter: (_, row) => DATE_FORMAT(row.dateCreated),
  },
  {
    text: "Last login",
    dataField: "loginDateTime",
    sort: true, 
    sortCaret: customSortCaret,
    formatter: (_, row) => row?.logoutDateTime ? DATE_FORMAT(row?.logoutDateTime) : row?.loginDateTime ? DATE_FORMAT(row?.loginDateTime) : "---"
  },
  {
    text: "Status",
    dataField: "status",
    sort: false, 
    formatter: (cellContent, row) => (
      <Badge
        className={`text-capitalize font-size-13 badge-soft-${row.status === "pending" ? "warning" : "success"}`}
        color={row.badgeClass}
      >
        {STATUS[row.status]}
      </Badge>
    ),
  },
  {
    text: "Action",
    dataField: "",
    formatter: (_, row) => (
      <>
        <button
          className="fa fa-eye"
          style={{
            fontSize: "20px",
            color: "green",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="View"
          onClick={() => handleViewUser(row._id)}
        ></button>
      </>
    ),
  },
];

export default Columns;

const getLinkedQr = (userProfiles) => {
  return userProfiles.reduce((acc, record) => {
    if (record.linkedQrCodeId) {
      acc += 1;
    }
    return acc;
  }, 0);
};
