import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { post} from "helpers/api_helper";

export const getSupportListData = createAsyncThunk(
  "getSupportListData",
  async ({ data, callback }, Thunk) => {
    console.log(data,"data======fhjdfjhdfdjfh=====")
    try {
      let response = await post(`${URL.SUPPORT_API}`, data);
      console.log("response callback==>",response)
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error,"error message ")
      callback && callback(error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const SupportEdit = createAsyncThunk(
    "SupportEdit",
    async ({ data, callback }, Thunk) => {
      console.log(data,"action doctor edit ")
      try {
        let response = await post(URL.SUPPORT_EDIT , data);
        callback && callback(null, response.data);
        return response;
      } catch (error) {
        callback && callback(error?.data?.message || error.message || error);
        return Thunk.rejectWithValue(error);
      }
    }
  );
