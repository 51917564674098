const inputFields = [
  {
    name: "status",
    type: "select",
    label: "Status",
    placeholder: "Select an Option",
    options: [
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" },
    ],
  },
  {
    name: "name",
    type: "text",
    label: " Name",
    placeholder: "Enter Name Promo ",
  },

  // {
  //   name: "description",
  //   type: "text",
  //   label: "Description ",
  //   placeholder: "Enter Description ",
  // },

  {
    name: "price",
    type: "number",
    label: "Price",
    placeholder: "Enter Price ",
  },
  {
    name: "currency",
    type: "select",
    label: "Currency",
    innerWidth: "40px",
    outerHeight: "md",
    options: [
      { value: "GEl", label: "GEL" },
    ],
  },
  {
    name: "type",
    type: "select",
    label: "Type",
    placeholder: "Select an Option",
    options: [
      { value: "flat", label: "Flat" },
      { value: "percentage", label: "Percentage" },
    ],
  },
];

export default inputFields;
