import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  USER_RELATIVE_PROFILE,
  deletePhoto,
  deleteVideo,
  LINK_QR_TO_USER,
  UNLINK_QR_TO_USER,
  ALBUMPHOTOLIST,
} from "store/Relative/actions";
import FadeLoader from "react-spinners/FadeLoader";
import ConfirmModal from "components/Modals/ConfirmModal";
import { Badge, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { QRDETAILS, getSettings } from "store/actions";
import { calculateAge, formatYearsAndMonths } from "helpers/common";
import BackButtom from "components/BackButtom";
import Columns from "./Columns";
import Table from "components/Table";
import Photos from "views/photos/index";
import Videos from "views/videos/index";

const UserRelativeProfile = () => {
  const { loading: profileLoading, relative } = useSelector(
    (state) => state.Relative
  );
  const [activeTab, setActiveTab] = useState("bio");
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmModalVideo, setConfirModalVideo] = useState(false);
  const [confirmModalImage, setConfirModalImage] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  // Assume decimalNumber represents age in years with a fraction representing months
  const decimalNumber = relative?.data?.age;

  // Calculate age in years and months
  const ageData = calculateAge(decimalNumber);

  // Format the age for display
  const formattedAge = formatYearsAndMonths(ageData);

  // link Qr  functionality
  const [qrData, setQrData] = useState([]);
  const [albumdata, setAlbumdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [linking, setLinking] = useState(false);
  const [columns, setColumns] = useState();
  const [photoData, setPhotoData] = useState();
  const [videoData, setVideoData] = useState();
  const [filterAlbum, setFilterAlbum] = useState({
    limit: 10,
    order: 1,
    orderBy: "createdAt",
    pageNo: currentPage,
    search: "",
    userProfileId: userId,
  });
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: 20,
    order: 1,
    orderBy: "dateCreatedUtc",
    search: "",
    status: "pending",
  });
  const { settings, loading } = useSelector((state) => state.Settings);
  useEffect(() => {
    dispatch(getSettings());
  }, []);
  const API_IMAGE_URL = settings?.s3BaseUrl;
  const fetchData = async (filter) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setQrData(res);
          toast.success(res.message);
        }
      };
      dispatch(QRDETAILS({ data: filter, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  // album listing api functions
  const AlbumList = (filterAlbum) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res, "response  data ");
          setAlbumdata(res);
          toast.success(res.message);
        }
      };
      dispatch(ALBUMPHOTOLIST({ data: filterAlbum, callback }));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData(filter);
    AlbumList(filterAlbum);
    setColumns(Columns({ handleViewUser,API_IMAGE_URL }));
  }, [filter, filterAlbum,API_IMAGE_URL]);

  const handleViewUser = (id) => {
    navigate(`/admin/users/profile/album/${id}`);
  };
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const selectOptions = qrData.map((item) => ({
    value: item?.qrCodeID,
    label: item.qrCodeID,
  }));
  const handleInputChange = (inputValue) => {
    setFilter((prevState) => ({
      ...prevState,
      search: inputValue,
    }));
  };

  const handleLink = () => {
    if (selectedOption && !linking) {
      setLinking(true);
      const qrCodeID = selectedOption.value;
      const userProfileId = userId;
      dispatch(
        LINK_QR_TO_USER({
          qrCodeID: qrCodeID,
          userProfileId: userProfileId,
          callback: handleLinkCallback,
        })
      );
    } else if (!selectedOption) {
      toast.error("Please select a user profile");
    }
  };

  const handleLinkCallback = (error, res) => {
    if (error) {
      toast.error("Failed to link QR code to user profile");
    } else {
      toast.success(res.message);
      {
        res && dispatch(USER_RELATIVE_PROFILE({ data: userId }));
      }
    }
    setLinking(false);
  };

  const handleUnLink = () => {
    const type = "unlink";
    const userProfileId = userId;
    dispatch(
      UNLINK_QR_TO_USER({
        qrCodeID: relative?.data?.linkedQrCodeDetails?.qrCodeID,
        userProfileId: userProfileId,
        type: type,
        callback: handleUnLinkCallback,
      })
    );
  };
  const handleUnLinkCallback = (error, res) => {
    if (error) {
      toast.error("Failed to Unlink QR code to user profile");
    } else {
      toast.success(res.message);
      {
        res && dispatch(USER_RELATIVE_PROFILE({ data: userId }));
      }
    }
    setLinking(false);
  };
  // console.log(relative, "relatives=====");
  // images gets by setting
 

  useEffect(() => {
    if (userId) {
      dispatch(USER_RELATIVE_PROFILE({ data: userId }));
    }
  }, [dispatch, userId]);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const handleDeleteVideo = (videoId) => {
    setConfirModalVideo(true);
    setItemToDelete(videoId);
  };

  const handleDeletePhoto = (photoId) => {
    setConfirModalImage(true);
    setItemToDelete(photoId);
  };

  const confirmDeleteVideo = () => {
    dispatch(deleteVideo({ data: itemToDelete })).then(() => {
      // Remove the deleted video from the list
      const updatedVideos = relative.data.videos.filter(
        (video) => video._id !== itemToDelete
      );
      const updatedRelative = {
        ...relative,
        data: { ...relative.data, videos: updatedVideos },
      };
      dispatch(USER_RELATIVE_PROFILE({ data: userId, updatedRelative }));
      toast.success("Video Deleted Successfully");
      setConfirModalVideo(false);
    });
  };

  const confirmDeletePhoto = () => {
    dispatch(deletePhoto({ data: itemToDelete })).then(() => {
      // Remove the deleted photo from the list
      const updatedPhotos = relative.data.photos.filter(
        (photo) => photo._id !== itemToDelete
      );
      const updatedRelative = {
        ...relative,
        data: { ...relative.data, photos: updatedPhotos },
      };
      dispatch(USER_RELATIVE_PROFILE({ data: userId, updatedRelative }));
      toast.success("Image Deleted Successfully");
      setConfirModalImage(false);
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const opts = {
    height: "120",
    width: "200",
    playerVars: {
      autoplay: 0, // 0 or 1 for autoplay
    },
  };
  const deathDate = relative?.data?.deathDate;
  const formattedDate = deathDate
    ? moment(deathDate).format("MMMM DD, YYYY HH:mm A")
    : "---";

  return (
    <>
      {profileLoading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}

      <ConfirmModal
        show={confirmModalVideo}
        handleClose={() => setConfirModalVideo(false)}
        title={"Delete Video"}
        content={"Are you sure you want to delete the video?"}
        handleConfirm={confirmDeleteVideo}
        loading={profileLoading}
      />
      <ConfirmModal
        show={confirmModalImage}
        handleClose={() => setConfirModalImage(false)}
        title={"Delete Image"}
        content={"Are you sure you want to delete the image?"}
        handleConfirm={confirmDeletePhoto}
        loading={profileLoading}
      />
      <section className="section">
        <div className="section-header">
          <BackButtom />
          <h1 className="">Relative Profile</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/users/" + relative?.data?.userId}>
                UserDetail
              </Link>
            </div>
            <div className="breadcrumb-item">relative profile</div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="d-flex justify-content-between px-4">
                <p className="d-flex align-content-center font-monospace  fw-bold ">
                  Profile details
                </p>
                {relative?.data?.linkedQrCodeDetails?.status === "linked" ? (
                  <button
                    className="btn btn-primary px-4"
                    onClick={handleUnLink}
                  >
                    {linking ? "Unlinking..." : "Remove Qr To Profile"}
                  </button>
                ) : (
                  <div className="d-flex justify-content-end gap-2 ">
                    <Select
                      className=""
                      value={selectedOption}
                      onChange={handleChange}
                      options={selectOptions}
                      onInputChange={handleInputChange}
                      name="qrCodeId"
                      placeholder="Choose your Qr code"
                      isSearchable
                    />
                    <button
                      className="btn btn-primary px-3"
                      onClick={handleLink}
                      disabled={!selectedOption || linking}
                    >
                      {linking ? "Linking..." : "Link Qr To Profile"}
                    </button>
                  </div>
                )}
              </div>
              <Row className=" p-4">
                <Col className="col-md-2 my-2 h-100 py-2">
                  <div className="d-flex flex-column align-items-center ">
                    <img
                      src={API_IMAGE_URL + relative?.data?.profileImage}
                      alt="profileImage"
                      className="img-fluid rounded"
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>
                </Col>
                <Col className="col-md-5 my-2 h-100  ">
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Name</p>
                    <span className="py-1">{relative?.data?.fullName}</span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Age</p>
                    <span className="py-1">{formattedAge}</span>
                  </div>
                  <div className="d-flex justify-content-between border-bottom">
                    <p className="fw-bolder">Birthday</p>
                    <span className="py-1">
                      {moment(relative?.data?.birthDate).format(
                        "MMMM DD, YYYY HH:mm A"
                      )}
                    </span>
                  </div>
                  <div className="d-flex gap-2  ">
                    <p className="fw-bolder">BirthPlace</p>
                    <span className="py-1">{relative?.data?.birthPlace}</span>
                  </div>
                </Col>
                <Col className="col-md-5 my-2 h-100">
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Death Day</p>
                    <span className="py-1">{formattedDate}</span>
                  </div>
                  <div className="d-flex gap-2  border-bottom">
                    <p className="fw-bolder">GraveCemetery</p>
                    <span className="py-1">
                      {relative?.data?.graveCemetery}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">QR Code ID</p>
                    <span className="py-1">
                      {relative?.data?.linkedQrCodeDetails?.qrCodeID}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <p className="fw-bolder">QrCode Link Status</p>
                    <span className="py-1">
                      <Badge
                        className={`text-capitalize font-size-13 badge-soft-${relative?.data?.linkedQrCodeDetails?.status?.status}`}
                        color={
                          relative?.data?.linkedQrCodeDetails?.status
                            ?.badgeClass
                        }
                      >
                        {relative?.data?.linkedQrCodeDetails?.status}
                      </Badge>
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="nav nav-tabs pillsTab">
                <li className="nav-item">
                  <button
                    className={`nav-link d-flex align-items-center justify-content-center ${
                      activeTab === "bio" ? "active" : ""
                    }`}
                    onClick={() => toggleTab("bio")}
                  >
                    Bio
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link d-flex align-items-center justify-content-center ${
                      activeTab === "photo" ? "active" : ""
                    }`}
                    onClick={() => toggleTab("photo")}
                  >
                    Photo
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link d-flex align-items-center justify-content-center ${
                      activeTab === "video" ? "active" : ""
                    }`}
                    onClick={() => toggleTab("video")}
                  >
                    Video
                  </button>
                </li>
              </ul>
              <div className="tab-content align-items-center">
                {activeTab === "bio" && (
                  <div className="tab-pane fade show active">
                    <p
                      dangerouslySetInnerHTML={{ __html: relative?.data?.bio }}
                    ></p>
                  </div>
                )}
                {activeTab === "photo" && (
                  <div className="tab-pane fade show active ">
                    <div className="card">
                      <div className="topHead px-3 ">
                        <h6 className="m-0 fw-bold py-2">Album</h6>
                      </div>

                      {!loading && albumdata?.length > 0 && (
                        <Table
                          data={albumdata}
                          columns={columns}
                          setFilter={setFilterAlbum}
                          totalCount={albumdata?.totalcount}
                          handleTableChange={handlePageChange}
                          filter={filterAlbum}
                        />
                      )}
                      {!loading && !albumdata?.length && (
                        <p style={{ textAlign: "center" }}>No album Found</p>
                      )}
                    </div>
                    <Photos userId={userId} />
                  </div>
                )}
                {activeTab === "video" && (
                  <div className="tab-pane fade show active">
                    <Videos userId={userId} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserRelativeProfile;
