import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ALBUMDATALIST } from "store/Relative/actions";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import { getSettings } from "store/actions";
import BackButtom from "components/BackButtom";
const UserRelativeProfile = () => {
  const { albumId: albumId } = useParams();
  const [albumdata, setAlbumdata] = useState();
  const dispatch = useDispatch();

  const [filterAlbum, setFilterAlbum] = useState({
    limit: 10,
    order: 1,
    orderBy: "createdAt",
    pageNo: 1,
    search: "",
    userProfileId: albumId,
  });

  // album listing api functions
  const AlbumList = (filterAlbum) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
        //   console.log(res, "response  data ");
          setAlbumdata(res);
          toast.success(res.message);
        }
      };
      dispatch(ALBUMDATALIST({ data: filterAlbum, callback }));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    AlbumList(filterAlbum);
  }, [filterAlbum]);
  // images gets by setting
  const { settings, loading } = useSelector((state) => state.Settings);
  useEffect(() => {
    dispatch(getSettings());
  }, []);
  const API_IMAGE_URL = settings?.s3BaseUrl;
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="section">
        <div className="section-header">
          <BackButtom />
          <h1 className="">Relative Album</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              {/* <Link to={"/admin/users/profile"}>
                User Relative Profile
              </Link> */}
            </div>
            <div className="breadcrumb-item">relative album</div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <p className="d-flex justify-content-start  fw-bolder" style={{}}>
              {albumdata?.name}
            </p>
            <div className="d-flex gap-4 ">
              {albumdata?.photos?.map((item) => {
                return (
                  <>
                   <div className="border card position-relative rounded">
                    <button className="border-0 p-0 bg-white px-2 position-absolute text-danger" style={{zIndex: 9, top: 5, right: 5}}>
                    <i class="fas fa-trash-alt"></i>
                    </button>
                   <img
                      src={API_IMAGE_URL + item.link}
                      alt="profileImage"
                      className="img-fluid p-2 rounded "
                      style={{ width: "200px", height: "200px" }}
                    />
                    <p className="d-flex justify-content-center">{item.title}</p>
                   </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserRelativeProfile;
