import * as Yup from 'yup';

const Validation = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .min(2, 'Name must be at least 2 characters'),
  // email: Yup.string().email("Must be a email!").required("Email is required!"),
  // countryCode:Yup.string()
  // .required('mobileCode is required'),
  // mobileNumber:Yup.string()
  // .required('mobileNumber is required'),
  // contactPersonName:Yup.string()
  // .required('person name is required'),
  // address:Yup.string()
  // .required('address is required'),
  // bannerImage : Yup.string()
  // .required('bannerImage is required'),
});

export default Validation;
