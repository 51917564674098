import { createAsyncThunk } from "@reduxjs/toolkit"
import { post, get, put } from "helpers/api_helper"
import slice from "./slice"
import * as URL from "helpers/url_helper"
import { toast } from "react-toastify"

// import { updateAdminToken } from "helpers/common"

export const loginUser = createAsyncThunk(
  "admin/login",
  async ({ data, callBack }, Thunk) => {
    try {
      let response = await post(URL.LOGIN_USER, data)
      console.log("response---->",response.token)
      // updateAdminToken(response.token)
      localStorage.setItem("authUser", JSON.stringify(response))
      localStorage.setItem("user_token", response.token)
      callBack && callBack(null, response)
      return response
    } 
    catch (error) {
      console.log(error, "<==sss=error")
      toast.error("Invalid credentials!")
      callBack && callBack(error)

      return Thunk.rejectWithValue(error)
    }
  }
)

export const viewUser = createAsyncThunk(
  "admin/view",
  async (payload, Thunk) => {
    try {
      let response = await get(URL.VIEW_USER)

      return response.data
    } catch (error) {
      return Thunk.rejectWithValue(error)
    }
  }
)

export const updateAdmin = createAsyncThunk(
  "admin/edit/",
  async ({ data, callBack }, Thunk) => {
    try {
      let response = await put(URL.UPDATE_ADMIN + data?._id, data)
      callBack(null, response)
      return response.data
    } catch (error) {
      callBack(error.message)
      return Thunk.rejectWithValue(error)
    }
  }
)

export const resetPass = createAsyncThunk(
  "admin/reset",
  async ({ data, callBack }, Thunk) => {
    try {
      let response = await put(URL.RESET_PASSWORD + data?._id, data)
      callBack(null, response)
      return response.data
    } catch (error) {
      callBack(error.message)
      return Thunk.rejectWithValue(error)
    }
  }
)

export const uploadFile = createAsyncThunk(
  "uploadFile",
  async ({ data, callback }, Thunk) => {
    try { 
      const formData = new FormData()
      formData.append("file", data)
      const config = {
        headers: { "content-type": "multipart/form-data" },
      }
      let response = await post(URL.POST_FILE, formData, config);
      console.log(response, "resposne");
      callback && callback(null, response)
      return response.data
    } 
    catch (error) {
      console.log("error", error);
      callback(error.message)
      return Thunk.rejectWithValue(error)
    }
  }
)

export const { logoutUser } = slice.actions



