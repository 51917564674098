import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { PATIENTDETAILLPAGE} from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";
import Table from "components/Table";
import Columns from "./Columns";
import BackButtom from "components/BackButtom";
import {  Col, Row } from "react-bootstrap";
import { userHistoryList } from "store/actions";
import MeetingHistoryPatient from "components/meetingHistoryPatient";
const PatientDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [data, setData] = useState();
  const { state } = useLocation();
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
  });
  const [activeTab, setActiveTab] = useState("login");
  const toggleTab = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    fetchData(filter);
  }, [id]);
  const fetchData = () => {
    const payload = {
      ...filter,
      page: filter?.pageNo,
      record_count: filter?.limit,
      user_id: id,
    };
    try {
      const callback = (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setData(res);
        }
      };
      dispatch(userHistoryList({ data: payload, callback }));
    } catch (error) {
      console.log(error);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      {data === "true" && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={PATIENTDETAILLPAGE.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <BackButtom />
          <h1>{PATIENTDETAILLPAGE.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.patientview}>Patient</Link>
            </div>
            <div className="breadcrumb-item">
              {PATIENTDETAILLPAGE.CURRENT_MODULE}{" "}
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">
                    {PATIENTDETAILLPAGE.TOP_CONTENT}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <Row className="px-4 justify-content-center">
            <Col className=" col-md-12 my-2 h-100 w-100 card rounded-lg">
              <div className="d-flex justify-content-center border-bottom">
                <h6 className=" p-2 ">Patient Details</h6>
              </div>
              <div className="d-flex ">
                <div className="col-md-6 my-2 h-100 w-100">
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Patient Name</p>
                    <span className="py-1">{state?.row?.full_name}</span>
                  </div>
                  <div className="d-flex justify-content-between gap-2  border-bottom">
                    <p className="fw-bolder">Email</p>
                    <span className="py-1">{state?.row?.email}</span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Doctor Code </p>
                    <span className="py-1">{state?.row?.doctor_code}</span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Patient Code</p>
                    <span className="py-1">{state?.row?.patient_code}</span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Age</p>
                    <span className="py-1">
                      {state?.row?.age ? state?.row?.age : "---"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">License No</p>
                    <span className="py-1">{state?.row?.license_no}</span>
                  </div>
                </div>
                <div className="col-md-6 my-2 h-100 w-100 ">
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Country Name</p>
                    <span className="py-1">
                      {state?.row?.country ? state?.row?.country : "---"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between gap-2  border-bottom">
                    <p className="fw-bolder">State</p>
                    <span className="py-1">
                      {state?.row?.state ? state?.row?.state : "---"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">City </p>
                    <span className="py-1">
                      {state?.row?.city ? state?.row?.city : "---"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Country Code</p>
                    <span className="py-1">{state?.row?.country_code}</span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Phone No</p>
                    <span className="py-1">{state?.row?.phone_no}</span>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom">
                    <p className="fw-bolder">Address</p>
                    <span className="py-1">
                      {state?.row?.address ? state?.row?.address : "---"}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <ul className="nav nav-tabs pillsTab">
                  <li className="nav-item">
                    <button
                      className={`nav-link d-flex align-items-center justify-content-center ${
                        activeTab === "login" ? "active" : ""
                      }`}
                      onClick={() => toggleTab("login")}
                    >
                      Login History
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link d-flex align-items-center justify-content-center ${
                        activeTab === "meeting" ? "active" : ""
                      }`}
                      onClick={() => toggleTab("meeting")}
                    >
                      Meeting History
                    </button>
                  </li>
                </ul>
                <div className="tab-content align-items-center">
                  {activeTab === "login" && (
                    <>
                      {data?.users?.length > 0 ? (
                        <div className="tab-pane fade show active">
                          <Table
                            data={data.users}
                            columns={Columns({ data })}
                            setFilter={setFilter}
                            totalCount={data?.total_record_count}
                            handleTableChange={handlePageChange}
                            filter={filter}
                          />
                        </div>
                      ) : (
                        <p style={{ textAlign: "center" }}>No data Found</p>
                      )}
                    </>
                  )}

                  {activeTab === "meeting" && (
                    <div className="tab-pane fade show active ">
                      <div className="card">
                        <MeetingHistoryPatient user_id={id}  userType={"1"} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PatientDetails;
