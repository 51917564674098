import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  FEEDBACK: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "FEEDBACK",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getFeedbackList.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getFeedbackList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      FEEDBACK: payload,
      totalCount: payload.total_record_count,
    }));
    builder.addCase(Act.getFeedbackList.rejected, (state, action) => ({
      ...state,
      loading: false,
      FEEDBACK: [],
      totalCount: 0,
    }));
    builder.addCase(Act.getFeedbackSingle.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getFeedbackSingle.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false
    }));
    builder.addCase(Act.getFeedbackSingle.rejected, (state, action) => ({
      ...state,
      loading: false, 
    }));
    
  },
});

export default slice;
