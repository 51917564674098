import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { DOCTOR, PATIENT } from "common/viewContent";
import * as Path from "routes/Path/index";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import inputFields from "./inputFields";
import { Link, useNavigate } from "react-router-dom";
import { CountryCode, DoctorEdit, DoctorDel, getDoctorList } from "store/actions";
import EditPatient from "components/Modals/EditPatientModal";
import countryList from "react-select-country-list";
import FormikForm from "components/shared/FormikForm";
import ConfirmModal from "components/Modals/ConfirmModal";
const InputFieldsForSearch = [
  {
    name: "search_text",
    type: "text",
    placeholder: "Search using name,email...",
  },
];
const initialValuesForFilter = {
  search_text: "",
};
const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = countryList().getData();
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);
  const { USER, loading, totalCount } = useSelector((state) => state.Doctor);
  const [doctor, setDoctor] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [editModalShow, setEditModalShow] = useState();
  const [search_text, setSearch_text] = useState();
  const [countryListData, setCountryListData] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
    search_text: "",
  });
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    country: "",
    state: "",
    email: "",
    phone_no: "",
    country_code: "",
    city: "",
  });
  const onCliclNavigate = (id) => {
    navigate(`/admin/feedback/${id}`);
  };
  const fetchCountryData = async () => {
    let data = {};
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setCountryListData(res.data);
        }
      };
      dispatch(
        CountryCode({
          data: data,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const fetchData = async (filter) => {
    const payload = {
      ...filter,
      page: filter?.pageNo,
      record_count: filter?.limit,
      user_role: "1",
    };
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setDoctor(res);
        }
      };
      dispatch(
        getDoctorList({
          data: payload,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  useEffect(() => {
    fetchData(filter);
    fetchCountryData();
    setcolumns(
      Columns({
        onCliclNavigate,
        handleViewUser,
        handleEdithandler,
        handleUserStatus,
        deleteHandler,
        currentPage: filter?.pageNo,
        pageSize,
      })
    );
  }, [filter]);

  const handleEdithandler = (info) => {
    setFormData({ ...formData, ...info });
    setEditModalShow(true);
  };

  useEffect(() => {
    const CountryList = options?.map((item) => {
      return { value: item?.label, label: item?.label };
    });
    inputFields.find((field) => field.type === "select").options =
      CountryList || [];
    const CountryCodeArray = countryListData?.map((obj) => {
      return { value: obj.calling_code, label: obj.calling_code };
    });
    console.log(CountryCodeArray, "<===categoriesArray");
    inputFields.find((field) => field.type === "dynamic_select").options =
      CountryCodeArray || [];
  }, [countryListData]);

  const handleEditSub = (info) => {
    try {
      let data = {
        user_id: info.id,
        first_name: info.first_name,
        middle_name: info.middle_name,
        last_name: info.last_name,
        country: info.country,
        state: info.state,
        email: info.email,
        phone_no: info.phone_no,
        country_code: info?.country_code || info?.country_code,
        city: info.city,
      };
      const callback = (err, res) => {
        if (err) {
          // console.log(err);
          toast.error(err.toString());
        } else {
          setEditModalShow(false);
          fetchData(filter);
          toast.success("User updated successfully!");
        }
      };
      console.log(data, "data");
      dispatch(
        DoctorEdit({
          data: data,
          callback,
        })
      );
      console.log(data, "data");
    } catch (error) {
      console.error(error, "<===err");
    }
  };

  const handleUserStatus = (row, status) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          fetchData(filter);
          toast.success("Status Changed");
        }
      };

      dispatch(
        DoctorEdit({
          data: { user_id: row?.id, status: status },
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };

  const deleteHandler = (id) => {
    setConfirmModal(true);
    setDeleteId(id);
  };
  const handleDeleteConfirm = (event) => {
    event.preventDefault();
    const callback = (err, res) => {
      if (err) {
        toast.error(err);
      } else {
        toast.success(res.message);
        fetchData(filter);
      }
    };
    dispatch(
      DoctorDel({
        data: { user_id: deleteId, is_deleted: "1" },
        callback,
      })
    );
    setConfirmModal(false);
    setDeleteId(null);
  };

  const onClickFilter = (e) => {
    e?.preventDefault();
    setFilter({
      ...filter,
      pageNo: 1,
      limit: pageSize,
      search_text: search_text,
    });
  };

  const clearFilter = () => {
    setFilter((pre) => ({
      ...pre,
      pageNo: 1,
      search_text: "",
    }));
    setSearch_text("");
  };

  const handlePageChange = (pageNumber) => {
    console.log("pageNumber->", pageNumber);
    setCurrentPage(pageNumber);
  };
  const handleViewUser = (row) => {
    navigate(`${row.id}`, { state: { row } });
  };

  console.log(USER, "hgdg");

  return (
    <>
      {confirmModal && (
        <ConfirmModal
          show={confirmModal}
          handleClose={() => setConfirmModal(false)}
          title={"Confirmation"}
          content={"Are you sure you want to delete User"}
          handleConfirm={handleDeleteConfirm}
          loading={""}
        />
      )}
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      {editModalShow && (
        <EditPatient
          show={editModalShow}
          handleClose={() => {
            setEditModalShow(false);
          }}
          title={"Edit Patient"}
          content={""}
          handleConfirm={handleEditSub}
          loading={loading}
          initialValues={formData}
        />
      )}
      <Helmet title={DOCTOR.CURRENT_MODULE} />

      <section className="section">
        <div className="section-header">
          <h1>{PATIENT.CURRENT_MODULE}</h1>

          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{PATIENT.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{PATIENT.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-12">
                <form onSubmit={onClickFilter}>
                  <div className="card-header">
                    <div className="col-lg-4 col-sm-12 col-md-12 my-2 px-0">
                      <input
                        className="form-control"
                        name="search_text"
                        onChange={(e) => setSearch_text(e.target.value)}
                        value={search_text}
                        placeholder="Search using name, email..."
                      />
                    </div>
                  </div>
                  <div className="col-12 my-2 gap-4 ">
                    <div className="d-flex gap-4">
                      <button type="submit" className="btn btn-primary btn-lg">
                        Search
                      </button>
                      <button
                        onClick={() => clearFilter()}
                        type="button"
                        className="btn btn-primary btn-lg"
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/* <div className="col-12 px-0">
                <div className="card-header px-0 ">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                  />
                </div>
              </div> */}
            </div>
          </div>
          {!loading && USER?.users?.length && (
            <Table
              data={doctor?.users}
              columns={columns}
              setFilter={setFilter}
              handleTableChange={handlePageChange}
              totalCount={totalCount}
              filter={filter}
            />
          )}
          {USER?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Patient Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
