import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";

import { get, post, put, del } from "helpers/api_helper";

export const PROMO = createAsyncThunk(
  "PROMO",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.PROMO_LIST, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const ADDPROMO = createAsyncThunk(
  "ADDPROMO",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.PROMO_ADD, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const UPDATEPROMO = createAsyncThunk(
  "UPDATEPROMO",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await put(URL.PROMO_UPDATE + data?._id, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const DELETEPROMO = createAsyncThunk(
  "DELETEPROMO",
  async ({ data, callback }, thunkAPI) => {
    console.log(data, "data++=++++++++++++++++++++");
    try {
      const response = await del(URL.PROMO_UPDATE + data);
      console.log(data, "data----------->");
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const PROMOGET_DEATILS = createAsyncThunk(
  "PROMOGET_DEATILS",
  async ({ data, callback }, Thunk) => {
    console.log(data,"dtaaattatatattatatatattatata")
    try {
      console.log(data,"dtaaattatatattatatatattatata")
      let response = await get(URL.PROMO_GET_DETAILS + data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
