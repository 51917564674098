import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post, del, put } from "helpers/api_helper";

  export const CountryCode = createAsyncThunk(
    "CountryCode",
    async ({ data, callback }, Thunk) => {
      console.log(data,"data======fhjdfjhdfdjfh=====")
      try {
        let response = await get(`${URL.COUNTRY_CODE}`,data);
        console.log("response callback==>",response)
        callback && callback(null, response);
        return response;
      } catch (error) {
        console.log(error,"error message ")
        callback && callback(error);
        return Thunk.rejectWithValue(error);
      }
    }
  );
  