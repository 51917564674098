import React from "react";
import { DATE_FORMAT } from "helpers/common";


import { customSortCaret } from "constants";
import moment from "moment";

const STATUS = {
  active: "Active",
  inactive: "Inactive",
  blocked: "Blocked",
  archived: "Archived",
  pending: "Pending",
};

const Columns = ({ data, authUser, currentPage, pageSize }) => [

  {
    dataField: "srno",
    text: "SrNo",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (cell, row, rowIndex) => (
      <span className="text">{((currentPage - 1) * pageSize) + (rowIndex + 1)}</span>
    ),
  },

  {
    dataField: "name",
    text: "Name",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text">{(row?.userInfo_information && (row?.userInfo_information?.first_name + " " + row?.userInfo_information?.middle_name + " " + row?.userInfo_information?.last_name)) || (authUser?.first_name)}</span>
    ),
  },

  {
    dataField: "email",
    text: "Email",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text">{ (row?.userInfo_information && row?.userInfo_information?.email)  ||  authUser?.email}</span>
    ),
  },


  {
    dataField: "last_login",
    text: "Last Login",
    sort: true,
    formatter: (_, row) => (
      <span className="text">
        {moment(row.last_login).format('YYYY-MM-DD HH:mm:ss ')}
      </span>
    )
  }


  // {
  //   text: "Created At",
  //   dataField: "dateCreatedUtc",
  //   sort: true,
  //   sortCaret: customSortCaret,
  //   formatter: (_, row) => DATE_FORMAT(row.dateCreated),
  // },
];

export default Columns;
