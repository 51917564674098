import React from "react";
import { DATE_FORMAT } from "helpers/common";
import { customSortCaret } from "constants";
const STATUS = {
  active: "Active",
  inactive: "Inactive",
  blocked: "Blocked",
  archived: "Archived",
  pending: "Pending",
};

const Columns = ({data}) => [
  {
    dataField: "name",
    text: "Name",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => {
      const { first_name, middle_name, last_name } = data?.userData || {};
      
      // Join names with a space, filtering out any undefined or empty values
      const fullName = [first_name, middle_name, last_name]
        .filter(Boolean) // Remove any undefined or empty values
        .join(' '); // Join with a single space
  
      return <span className="text">{fullName}</span>;
    },
  },
  
  {
    dataField: "email",
    text: "Email",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text">{data.userData?.email}</span>
    ),
  },

  {
    dataField: "last_login",
    text: "Last Login",
    sort: true,
    formatter: (_, row) => (
      <span className="text">
        {moment(row.last_login).format('YYYY-MM-DD HH:mm:ss ')}
      </span>
    )
  }

  // {
  //   text: "Created At",
  //   dataField: "dateCreatedUtc",
  //   sort: true,
  //   sortCaret: customSortCaret,
  //   formatter: (_, row) => DATE_FORMAT(row.dateCreated),
  // },
];

export default Columns;
