import React from "react";
import { DATE_FORMAT } from "helpers/common";


import { customSortCaret } from "constants";

const STATUS = {
  active: "Active",
  inactive: "Inactive",
  blocked: "Blocked",
  archived: "Archived",
  pending: "Pending",
};

const Columns = ({}) => [
  {
    dataField: "name",
    text: "Name",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text">{row?.shippingDetails?.name}</span>
    ),
  },
  {
    dataField: "name",
    text: "SurName",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text">{row.shippingDetails?.surname}</span>
    ),
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text">{row?.userDetails?.email}</span>
    ),
  },

  {
    text: "Created At",
    dataField: "dateCreatedUtc",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => DATE_FORMAT(row.dateCreated),
  },
];

export default Columns;
