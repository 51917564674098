import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import FadeLoader from "react-spinners/FadeLoader";

//imported
import { DOCTOR, PATIENT, PROMPT } from "common/viewContent";
import * as Path from "routes/Path/index";

import { toast } from "react-toastify";
import Table from "components/Table";

import AddModal from "components/Modals/AddFaq";
import inputFields from "./inputFields";
import Validation from "./Validation";
import { getFeedbackList } from "store/feedback/actions";
import { getLanguageList } from "store/language/actions";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CountryCode, DoctorEdit, getDoctorList } from "store/actions";
import EditDoctor from "components/Modals/EditDoctorModal";
import EditPatient from "components/Modals/EditPatientModal";
import countryList from "react-select-country-list";
import Columns from "./Colums";
import AddPrompt from "components/Modals/AddPrompt";
import EditPrompt from "components/Modals/EditPrompt";
const options = countryList().getData();
const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = countryList().getData();
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);
  const { USER, loading, totalCount } = useSelector((state) => state.Doctor);
  const [doctor, setDoctor] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [editModalShow, setEditModalShow] = useState();
  const [addModalShow,setAddModalShow]=useState()
  const [countryListData, setCountryListData] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
  });
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    country: "",
    state: "",
    phone_no: "",
    country_code: "",
    city: "",
  });

  const onCliclNavigate = (id) => {
    navigate(`/admin/feedback/${id}`);
  };
  const fetchCountryData = async () => {
    let data = {};
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setCountryListData(res.data);
        }
      };
      dispatch(
        CountryCode({
          data: data,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  console.log(countryListData, "ddjhg");

  const fetchData = async (filter) => {
    const payload = {
      ...filter,
      page: filter?.pageNo,
      record_count: filter?.limit,
      user_role: "1",
    };
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setDoctor(res);
        }
      };
      dispatch(
        getDoctorList({
          data: payload,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  useEffect(() => {
    fetchData(filter);
    fetchCountryData();
    setcolumns(
      Columns({
        onCliclNavigate,
        handleViewUser,
        handleEdithandler,
        currentPage: filter?.pageNo,
        pageSize,
      })
    );
  }, [filter]);

  const handleEdithandler = (info) => {
    setFormData({ ...formData, ...info });
    setEditModalShow(true);
  };



 
  const handleEditSub = (info) => {
    try {
      let data = {
        user_id: info.id,
        first_name: info.first_name,
        middle_name: info.middle_name,
        last_name: info.last_name,
        country: info.country,
        state: info.state,
        phone_no: info.phone_no,
        country_code: info?.country_code || info?.country_code,
        city: info.city,
      };
      const callback = (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setEditModalShow(false);
          fetchData(filter);
          toast.success("User updated successfully!");
        }
      };
      console.log(data, "data");
      dispatch(
        DoctorEdit({
          data: data,
          callback,
        })
      );
      console.log(data, "data");
    } catch (error) {
      console.error(error, "<===err");
    }
  };

  const handlePageChange = (pageNumber) => {
    console.log("pageNumber->", pageNumber);
    setCurrentPage(pageNumber);
  };

  const handleViewUser = (row) => {
    navigate(`${row.id}`, { state: { row } });
  };

  
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}

      {editModalShow && (
        <EditPrompt
          show={editModalShow}
          handleClose={() => {
            setEditModalShow(false);
          }}
          title={"Edit Prompt"}
          content={""}
          handleConfirm={handleEditSub}
          loading={loading}
          initialValues={formData}
        />
      )}
         {addModalShow && (
        <AddPrompt
          show={addModalShow}
          handleClose={() => {
            setAddModalShow(false);
          }}
          title={"Add Prompt"}
          content={""}
          handleConfirm={handleEditSub}
          loading={loading}
          initialValues={formData}
        />
      )}
      <Helmet title={PROMPT.CURRENT_MODULE} />

      <section className="section">
        <div className="section-header">
          <h1>{PROMPT.CURRENT_MODULE}</h1>

          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{PROMPT.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{PROMPT.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add Prompt
                  </button>
                </div>
              </div>
              {/* {filter.languageId && (
                <div className="container">
                  <div className="col-6">
                    <FormikForm
                      initialValues={{ select: filter.languageId }}
                      // validationSchema={Validation}
                      onSubmit={handleFilterForm}
                      loading={loading}
                      inputFields={InputFieldsForSearch}
                      // submitting={null}
                      buttonText={"Search"}
                      // is_block={true}
                      col={6}
                      // is_passwordHide={false}
                    />
                  </div>
                </div>
              )} */}
            </div>
          </div>
          {!loading && USER?.users?.length && (
            <Table
              data={doctor?.users}
              columns={columns}
              setFilter={setFilter}
              handleTableChange={handlePageChange}
              totalCount={totalCount}
              filter={filter}
            />
          )}
          {USER?.users?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Prompt Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
