import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  USER: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "USER",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getDoctorList.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getDoctorList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      USER: payload,
      totalCount: payload.total_record_count,
    }));
    builder.addCase(Act.getDoctorList.rejected, (state, action) => ({
      ...state,
      loading: false,
      USER: [],
      totalCount: 0,
    }));
    builder.addCase(Act.DoctorEdit.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.DoctorEdit.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(Act.DoctorEdit.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

    builder.addCase(Act.DoctorDel.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.DoctorDel.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(Act.DoctorDel.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
    
    builder.addCase(Act.userHistoryList.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.userHistoryList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      USER: payload,
      // totalCount: payload.total_record_count,
    }));
    builder.addCase(Act.userHistoryList.rejected, (state, action) => ({
      ...state,
      loading: false,
      USER: [],
      totalCount: 0,
    }));
    builder.addCase(Act.useMeetingHistory.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.useMeetingHistory.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      USER: payload,
      // totalCount: payload.total_record_count,
    }));
    builder.addCase(Act.useMeetingHistory.rejected, (state, action) => ({
      ...state,
      loading: false,
      USER: [],
      totalCount: 0,
    }));
    builder.addCase(Act.useMeetingRecord.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.useMeetingRecord.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      USER: payload,
      // totalCount: payload.total_record_count,
    }));
    builder.addCase(Act.useMeetingRecord.rejected, (state, action) => ({
      ...state,
      loading: false,
      USER: [],
      totalCount: 0,
    }));
  },
});

export default slice;
