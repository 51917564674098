import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FadeLoader from "react-spinners/FadeLoader";

//imported
import { FAQS } from "common/viewContent";
import * as Path from "routes/Path/index";

import { toast } from "react-toastify";
import Table from "components/Table";
import Columns, { selectRow } from "./Columns";
import AddModal from "components/Modals/AddFaq";
import inputFields from "./inputFields";
import Validation from "./Validation";
import { AddFAQ, deleteFAQ, editFAQ, getFAQList } from "store/faq/actions";
import { getLanguageList } from "store/language/actions";


const List = () => {
  const dispatch = useDispatch();

  const { FAQ, loading, totalCount, langList } = useSelector((state) => ({
    FAQ: state?.Faq?.FAQ,
    loading: state?.Faq?.loading,
    totalCount: state?.Faq?.totalCount,
    langList: state?.Language?.langList,
  }));

  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);

  const [formData, setFormData] = useState({
    languageId: "",
    question: "",
    answer: "",
  });
  const [addModalShow, setAddModalShow] = useState(false);

  const [filter, setFilter] = useState({
    pageNo: 1,
    limit: 20,
    order: -1,
    orderBy: "createdAt",
    search: "",
  });

  const [languageFilter, setLanguageFilter] = useState({
    pageNo: 1,
    limit: 200,
    order: -1,
    orderBy: "createdAt"
  });

  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ deleteHandler, handleEdit }));
  }, [filter]);

  useEffect(() => {
    dispatch(getLanguageList({ data: languageFilter }));
  }, []);

  useEffect(() => {
    if (!langList) return; 

    const lang = langList.map((it) => ({
      value: it._id,
      label: it.languageName,
    }));

    inputFields.find((it) => it.name === "languageId").options = lang;

  }, [langList]);


  async function getApiData(query) {
    try {
      dispatch(getFAQList({ data: query }));
    } catch (error) {}
  }

  const deleteHandler = (id) => {
    try {
      const userConfirmed = window.confirm("Are you sure, you want to delete this faq?");
      if (userConfirmed) {
        const callback = (err, res) => {
          if (err) {
            toast.error(err);
          } else {
            getApiData(filter);
            toast.success(res.message);
          }
        };

        dispatch(
          deleteFAQ({
            data: { _id: id },
            callback,
          })
        );
      }
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleEdit = (info) => {
    // console.log("handle edit called------>",info);
    setFormData((prev) => {
      return {
        ...prev,
        _id: info._id,
        languageId: info.languageId,
        question: info.question,
        answer: info.answer,
      };
    });
    setAddModalShow(true);
  };

  const handleSubmit_ = (info) => {
    // console.log("submit called=======>", info)
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setAddModalShow(false);
          getApiData(filter);
          toast.success(res.message);
          setFormData({
            languageId: "",
            question: "",
            answer: "",
          });
        }
      };
      if (info?._id) {
        dispatch(
          editFAQ({
            data: info,
            callback,
          })
        );
      } 
      else {
        dispatch(
          AddFAQ({
            data: info,
            callback,
          })
        );
      }
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  return (
    <>
      <AddModal
        show={addModalShow}
        handleClose={() => {
          setAddModalShow(false);
          setFormData({
            languageId: "",
            question: "",
            answer: "",
          });
        }}
        title={`${formData.hasOwnProperty("_id") ? "Edit" : "Add"} FAQ`}
        content={""}
        handleConfirm={handleSubmit_}
        loading={loading}
        initialValues={formData}
        inputFields={inputFields}
        Validation={Validation}
        size="lg"
      />
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={FAQS.CURRENT_MODULE} />

      <section className="section">
        <div className="section-header">
          <h1>{FAQS.CURRENT_MODULE}</h1>

          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
            <Link to={"/admin/"+ Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{FAQS.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{FAQS.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add FAQ
                  </button>
                </div>
              </div>
              {/* {filter.languageId && (
                <div className="container">
                  <div className="col-6">
                    <FormikForm
                      initialValues={{ select: filter.languageId }}
                      // validationSchema={Validation}
                      onSubmit={handleFilterForm}
                      loading={loading}
                      inputFields={InputFieldsForSearch}
                      // submitting={null}
                      buttonText={"Search"}
                      // is_block={true}
                      col={6}
                      // is_passwordHide={false}
                    />
                  </div>
                </div>
              )} */}
            </div>
          </div>
          {!loading && FAQ?.length > 0 && (
            <Table
              data={FAQ}
              columns={columns}
              setFilter={setFilter}
              totalCount={totalCount}
              filter={filter}
              toggleConfirmModal={deleteHandler}
            />
          )}
          {!loading && FAQ?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Faq Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;