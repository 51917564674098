import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";


const Columns = ({ onCliclNavigate,handleViewUser,handleEdithandler,currentPage,pageSize }) => [
  {
    dataField: "",
    text: "SrNo",
    formatter: (cell, row, rowIndex) => (
      <span className="text-capitalize cursor-pointer">{((currentPage - 1) * pageSize) + (rowIndex + 1)}</span>
    ),
  },
  {
    dataField: "email1",
    text: "Prompt",
    formatter: (_, row) => (
      <span   className="text-capitalize cursor-pointer">{row?.email || "--"}</span>
    ),
  },
  {
    dataField: "patient_code",
    text: "Description",
    formatter: (_, row) => (
      <span   className="text-capitalize cursor-pointer">{row?.patient_code || "--"}</span>
    ),
  },
  {
    text: "Created At",
    dataField: "createdAt",
    formatter: (_, row) => DATE_FORMAT(row.createdAt),
  },
  {
    text: "Action",
    dataField: "",
    formatter: (_, row) => (
      <>
         <button
          className="fas fa-pen"
          style={{
            fontSize: "16px",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="Edit"
          onClick={() => handleEdithandler(row)}
        ></button>
        <button
          className="fa fa-eye"
          style={{
            fontSize: "20px",
            color: "green",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="View"
          onClick={() => handleViewUser(row)}
        ></button>
      </>
    ),
  },
];
export default Columns;
