import { DATE_FORMAT } from "helpers/common";
import React from "react";
import YouTube from "react-youtube";
const Columns = ({ handleViewUser }) => [
  {
    dataField: "link",
    text: "Videos",
    formatter: (_, row) => {
      let videoId = row?.link?.split("v=")[1];
      const ampersandPosition = videoId?.indexOf("&");
      if (ampersandPosition !== -1) {
        videoId = videoId?.substring(0, ampersandPosition);
      }
      const opts = {
        height: "120",
        width: "200",
        playerVars: {
          autoplay: 0,
        },
      };
      return (
        <span className="text-capitalize">
          <div
            className="video-container rounded"
            style={{
              width: "100px",
              height: "100px",
              overflow: "hidden",
            }}
          >
            <YouTube videoId={videoId} opts={opts} allowFullScreen={true} />
          </div>
        </span>
      );
    },
  },
  {
    dataField: "title",
    text: "Title Name",
    formatter: (_, row) => <span className="text-capitalize">{row.title}</span>,
  },

  {
    text: "Created At",
    dataField: "dateCreated",
    formatter: (_, row) => DATE_FORMAT(row.dateCreated),
  },

  {
    text: "Action",
    dataField: "status",
    formatter: (_, row) => (
      <>
        {
          <button
            className="fas fa-trash-alt"
            style={{
              fontSize: "20px",
              color: "red",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="View"
            onClick={() => handleViewUser(row._id)}
          ></button>
        }
      </>
    ),
  },
];
export default Columns;
