import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { PROMODETAILLPAGE } from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";
import Table from "components/Table";
import Columns from "./Columns";
import { PROMOGET_DEATILS } from "store/promo/actions";
import BackButtom from "components/BackButtom";
import { Badge, Col, Row } from "react-bootstrap";
const PromoDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [data, setData] = useState();
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
    order: -1,
    orderBy: "dateCreatedUtc",
    search: "",
    status: "",
  });
  console.log(id, "ididdididididdidi");
  useEffect(() => {
    fetchData();
  }, [id]);
  const fetchData = () => {
    try {
      const callback = (err, res) => {
        if (err) {
          console.log(err);
        } else {
          console.log(res, "response ------===--=-=---=----=");
          setData(res);
        }
      };
      dispatch(PROMOGET_DEATILS({ data: id, callback }));
    } catch (error) {
      console.log(error);
    }
  };
  console.log(data?.data?.name, "data api response ");
  return (
    <>
      {!data?.data && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={PROMODETAILLPAGE.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <BackButtom />
          <h1>{PROMODETAILLPAGE.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.promos}>Promos</Link>
            </div>
            <div className="breadcrumb-item">
              {PROMODETAILLPAGE.CURRENT_MODULE}{" "}
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">
                    {PROMODETAILLPAGE.TOP_CONTENT}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <Row className="px-4">
            <Col className="col-md-5 my-2 h-100 card rounded-lg">
              <div className="d-flex justify-content-center border-bottom">
                <h6 className=" p-2 ">Promo Code Details</h6>
              </div>
              <div className="d-flex justify-content-between  border-bottom">
                <p className="fw-bolder">Promo code name</p>
                <span className="py-1">{data?.data?.name}</span>
              </div>
              <div className="d-flex justify-content-between gap-2  border-bottom">
                <p className="fw-bolder">Promo Price</p>
                <span className="py-1">{data?.data?.price}</span>
              </div>
              <div className="d-flex justify-content-between  border-bottom">
                <p className="fw-bolder">Promo Type</p>
                <span className="py-1">{data?.data?.type}</span>
              </div>
              <div className="d-flex justify-content-between  border-bottom">
                <p className="fw-bolder">Applied Promo Count</p>
                <span className="py-1">{data?.appliedPromoCount}</span>
              </div>
              <div className="d-flex justify-content-between ">
                <p className="fw-bolder">Promo Status</p>
                <span className="py-1">
                  <Badge
                    className={`text-capitalize font-size-13 badge-soft-${data?.data?.status}`}
                    color={data?.data?.status?.badgeClass}
                  >
                    {data?.data?.status}
                  </Badge>
                </span>
              </div>
            </Col>
          </Row>
          <Table
            data={data?.promoAppliedUsers}
            columns={Columns({})}
            setFilter={setFilter}
            totalCount={data?.totalcount}
            // handleTableChange={handlePageChange}
            filter={filter}
            // toggleConfirmModal={handleUserStatus}
          />
          {data?.promoAppliedUsers?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Applied promo users Found</p>
          )}
        </div>
      </section>
    </>
  );
};
export default PromoDetails;
