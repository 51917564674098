import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post, del, put } from "helpers/api_helper";

export const getChemistList = createAsyncThunk(
  "getChemistList",
  async ({ data, callback }, Thunk) => {
    console.log(data,"data======chemist payload")
    try {
      let response = await post(`${URL.GET_USER_LIST}`, data);
      console.log("response callback==>",response)
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error,"error message ")
      callback && callback(error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const ChemistEdit = createAsyncThunk(
  "ChemistEdit",
  async ({ data, callback }, Thunk) => {
    console.log(data,"action chemist edit ")
    try {
      let response = await post(URL.EDIT_DOCTLIST , data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.message);
      return Thunk.rejectWithValue(error);
    }
  }
);
// export const userHistoryList = createAsyncThunk(
//   "userHistoryList",
//   async ({ data, callback }, Thunk) => {
   
//     try {
//       const { pageNo, limit, ...filteredData } = data;
//       let response = await post(`${URL.USER_HISTORY}`, filteredData);
//       console.log("response callback==>",response)
//       callback && callback(null, response);
//       return response;
//     } catch (error) {
//       console.log(error,"error message ")
//       callback && callback(error);
//       return Thunk.rejectWithValue(error.message);
//     }
//   }
// );

