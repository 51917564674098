import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { PROMOS } from "common/viewContent";
import Helmet from "react-helmet";
import FormikForm from "components/shared/FormikForm";
import * as Path from "routes/Path/index";
import { toast } from "react-toastify";
import Select from "react-select";
import Table from "components/Table";
import Columns from "./Columns";
import AddPromo from "components/Modals/AddPromo";
import EditPromo from "components/Modals/EditPromo";
import { ADDPROMO, DELETEPROMO, PROMO, UPDATEPROMO } from "store/promo/actions";
import ConfirmModal from "components/Modals/ConfirmModal";
const useStatusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "archived", label: "Archived" },
];
const Promos = () => {
  const navigate=useNavigate()
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [pageSize, setPageSize] = useState(20);
  const { promodata, loading, totalCount } = useSelector(
    (state) => state.promodata
  );
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
    order: -1,
    orderBy: "dateCreatedUtc",
    search: "",
    status: "",
  });
  
  const [formData, setFormData] = useState({
    name: "",
    currency: "",
    type: "flat",
    price: "",
    status: "active",
  });
  const onClickFilter = (e) => {
    e?.preventDefault();
    setFilter({
      ...filter,
      pageNo: 1,
      limit: pageSize,
      search: search,
      status: status,
    });
  };
  useEffect(() => {
    getApiData(filter);
  }, [filter]);
  async function getApiData(query) {
    try {
      await dispatch(PROMO({ data: query }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const handleUserStatus = (id, status) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          getApiData(filter);
          toast.success("Status Changed");
        }
      };

      dispatch(
        UPDATEPROMO({
          data: { _id: id, status: status },
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const handleSubmit_ = (values) => {
    console.log(values, "values-------------------------------------<>");
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error("Promo Already Exit");
        } else {
          console.log(res);
          setAddModalShow(false);
          getApiData(filter);
          toast.success(res.message);
        }
      };
      dispatch(
        ADDPROMO({
          data: values,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const handleEdithandler = (info) => {
    setFormData({ ...formData, ...info });
    setEditModalShow(true);
  };

  const handleEditSub = (info) => {
    try {
      let data = {
        _id: info?._id,
        name: info.name,
        currency: info.currency,
        type: info.type,
        price: info.price,
        status: info.status,
      };
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setEditModalShow(false);
          getApiData(filter);
          toast.success("User updated successfully!");
          setFormData({});
        }
      };
      dispatch(
        UPDATEPROMO({
          data: data,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const deleteHandler = (id) => {
    setConfirmModal(true);
    setDeleteId(id);
  };

  const handleDeleteConfirm = (event) => {
    event.preventDefault();
    const callback = (err, res) => {
      if (err) {
        toast.error(err);
      } else {
        toast.success(res.message);
        getApiData(filter);
      }
    };
    dispatch(DELETEPROMO({ data: deleteId, callback }));
    setConfirmModal(false);
    setDeleteId(null);
  };

  const handleViewUser=(id)=>{
    navigate(`${id}`)
  }

  const clearFilter = () => {
    setFilter((pre) => ({
      ...pre,
      pageNo: 1,
      search: "",
      status: "",
    }));
    setSearch("");
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  console.log(promodata, "promodata========<>");
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <AddPromo
        show={addModalShow}
        handleClose={() => setAddModalShow(false)}
        title={"Add Promo Code "}
        content={""}
        handleConfirm={handleSubmit_}
        loading={loading}
        initialValues={formData}
      />
      <ConfirmModal
        show={confirmModal}
        handleClose={() => setConfirmModal(false)}
        title={"Confirmation"}
        content={"Are you sure you want to delete the Promo?"}
        handleConfirm={handleDeleteConfirm}
        loading={""}
      />
      <EditPromo
        show={editModalShow}
        handleClose={() => setEditModalShow(false)}
        title={"Edit Promo Code "}
        content={""}
        handleConfirm={handleEditSub}
        loading={loading}
        initialValues={formData}
      />
      <Helmet title={PROMOS.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{PROMOS.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{PROMOS.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{PROMOS.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add Promos
                  </button>
                </div>
              </div>
              <div className="col-12 ">
                <form onSubmit={onClickFilter}>
                  <div className="card-header justify-content-between">
                    <div className="col-lg-4 col-sm-12 col-md-12 my-2 p-0 ">
                      <input
                        className="form-control"
                        name="search"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        placeholder="Search using promo name"
                      />
                    </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                      <Select
                        className="form-group"
                        name={"status"}
                        options={useStatusOptions}
                        onChange={(data) =>
                          setFilter((pre) => ({ ...pre, status: data?.value }))
                        }
                        isClearable
                        placeholder={"Select status"}
                        value={useStatusOptions.find(
                          (option) => option.value === status
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-12 my-2 gap-4 ">
                    <div className="d-flex gap-4">
                      <button type="submit" className="btn btn-primary btn-lg">
                        Search
                      </button>
                      <button
                        onClick={() => clearFilter()}
                        type="button"
                        className="btn btn-primary btn-lg"
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Table
            data={promodata?.data}
            columns={Columns({
              handleUserStatus,
              handleEdithandler,
              deleteHandler,
              handleViewUser
            })}
            setFilter={setFilter}
            totalCount={promodata?.totalcount}
            handleTableChange={handlePageChange}
            filter={filter}
            toggleConfirmModal={handleUserStatus}
          />
          {!loading && promodata?.data?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Promo Found</p>
          )}
        </div>
      </section>
    </>
  );
};
export default Promos;
