import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post, del, put } from "helpers/api_helper";
import { toast } from "react-toastify";
export const getDoctorList = createAsyncThunk(
  "getDoctorList",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(`${URL.GET_USER_LIST}`, data);
      console.log("response callback==>",response)
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error,"error message ")
      callback && callback(error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const DoctorEdit = createAsyncThunk(
  "DoctorEdit",
  async ({ data, callback }, Thunk) => {
   
    try {
      let response = await post(URL.EDIT_DOCTLIST , data);
      console.log("response-->",response)
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.message);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const DoctorDel = createAsyncThunk(
  "DoctorDel",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.DELETE_USER_DATA , data);
      console.log("response-->",response)
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.message);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const userHistoryList = createAsyncThunk(
  "userHistoryList",
  async ({ data, callback }, Thunk) => {
   
    try {
      const { pageNo, limit, ...filteredData } = data;
      let response = await post(`${URL.USER_HISTORY}`, filteredData);
      console.log("response callback==>",response)
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error,"error message ")
      callback && callback(error);
      return Thunk.rejectWithValue(error.message);
    }
  }
);
export const useMeetingHistory = createAsyncThunk(
  "useMeetingHistory",
  async ({ data, callback }, Thunk) => {
    console.log(data,"data=======meeting history")
   
    try {
      const { pageNo, limit, ...filteredData } = data;
      let response = await post(`${URL.MEETING_HISTORY}`, filteredData);
      console.log("response callback==>",response)
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error,"error message ")
      callback && callback(error);
      return Thunk.rejectWithValue(error.message);
    }
  }
);
export const useMeetingRecord = createAsyncThunk(
  "useMeetingRecord",
  async ({ data, callback }, Thunk) => {
    console.log(data,"data=======meeting history")
   
    try {
      const { pageNo, limit, ...filteredData } = data;
      let response = await post(`${URL.MEETING_RECORD}`, filteredData);
      console.log("response callback==>",response)
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error,"error message ")
      callback && callback(error);
      return Thunk.rejectWithValue(error.message);
    }
  }
);



