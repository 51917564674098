import React, { useEffect, useState } from "react";
import { useMeetingHistory } from "store/actions";
import Columns from "./Columns";
import { useDispatch } from "react-redux";
import Table from "components/Table";
import { useNavigate } from "react-router-dom";
const MeetingHistoryPatient = ({ user_id, userType }) => {
  const [meetingData, setMeetingData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const navigate = useNavigate();
  const [columns, setcolumns] = useState();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
  });
  useEffect(() => {
    MeetingHistory(filter);
    setcolumns(
      Columns({
        currentPage: filter?.pageNo,
        pageSize,
        handleViewUser,
      })
    );
  }, [user_id, filter]);
  const MeetingHistory = () => {
    const payload = {
      ...filter,
      page: filter?.pageNo,
      record_count: filter?.limit,
      user_id: user_id,
      userType: userType,
    };
    try {
      const callback = (err, res) => {
        if (err) {
          console.log(err);
        } else {
          console.log(res, "response");
          setMeetingData(res);
        }
      };
      dispatch(useMeetingHistory({ data: payload, callback }));
    } catch (error) {
      console.log(error);
    }
  };
  console.log(meetingData?.meeting_data, "meetingData");
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleViewUser = (row) => {
    console.log(row, "row data");
    navigate(`${row?.meeting_information[0]?.meeting_id}`, { state: { row } });
  };
  return (
    <>
      {meetingData?.meeting_data?.length > 0 ? (
        <div className="tab-pane fade show active">
          <Table
            data={meetingData?.meeting_data}
            columns={columns}
            setFilter={setFilter}
            totalCount={meetingData?.total_record_count}
            handleTableChange={handlePageChange}
            filter={filter}
          />
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>No data Found</p>
      )}
    </>
  );
};

export default MeetingHistoryPatient;
