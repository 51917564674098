import React, { useEffect } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import NotFound from "components/NotFound"
import AuthTheme from "theme/Auth"
import PrivateTheme from "theme/Private"
import DashboardTheme from "theme/SubTheme/dashboard"
import AdminRoute from "./Routes"
import { getSettings } from "store/actions"
import { useDispatch } from "react-redux"

function Routs() {
  const dispatch = useDispatch()
  useEffect(() => {
    // dispatch(getSettings());
  }, []);
  
  return (
    <Routes>
      <Route element={<AuthTheme />}>
        <Route index element={<Navigate to="/login" />} />
        {AdminRoute.map((data, key) => (
          <Route key={key} path={data.path} element={data.component} />
        ))}
      </Route>

    
        <Route path="/admin/" element={<DashboardTheme />}>
          <Route index element={<Navigate to="/admin/dashboard" />} />
          {AdminRoute.map((data, key) => (
            <Route key={key} path={data.path} element={data.component} />
          ))}
        </Route>
    

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Routs
