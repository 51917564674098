import axios from "axios"
import { toast } from "react-toastify"
import CryptoJS from 'crypto-js';
import {logOutRedirectCall} from './common'
var key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_API_ENC_KEY);
var iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_API_ENC_IV);
 
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const API_URL = API_BASE_URL + process.env.REACT_APP_API_BASE_URL_EXTENSION

export const API_VERSION = process.env.REACT_APP_API_VERSION

const axiosApi = axios.create({
  baseURL: API_URL + API_VERSION,
  headers: {
    'api-key': CryptoJS.AES.encrypt(process.env.REACT_APP_API_KEY, key, { iv: iv }).toString(),
    'accept-language': 'en',
    'Content-Type': 'text/plain',
  }
})

// export const updateToken = token => { axiosApi.defaults.headers.common["Authorization"] = "Bearer " + token }

// let obj = null
// if (typeof window !== "undefined") {
//   obj = localStorage.getItem("authToken")
// }
// console.log("obj", obj)
// updateToken(obj ? obj : null)


// Body Encryption Request
axiosApi.interceptors.request.use(function (request) {
  // console.log('request.data: ', request.data);
  request.data = bodyEncryption(request.data, true)
    if(localStorage.getItem("user_token") !== undefined || localStorage.getItem("user_token") !== null){
      request.headers['token']=bodyEncryption(localStorage.getItem("user_token"))
      // console.log(request.headers['token']);
    }
  return request;
});
  
axiosApi.interceptors.response.use(
  function (response) {
    response = bodyDecryption(response.data);
    response = JSON.parse(response)
    console.log(response.code, process.env.REACT_APP_SUCCESS)
    if(response.code!=process.env.REACT_APP_SUCCESS){
      // showMessage(response.message)
    }
    return response;
  }, 
  function (error) {
    let res = error.response;
    console.log(error)
   
    if (res.status == process.env.REACT_APP_UNAUTHORIZED) {
      logOutRedirectCall()
    }else if(res.status == process.env.REACT_APP_VALIDATION_ERROR){
      const response = bodyDecryption(res.data);
      return response 
    }else if(res.status == process.env.REACT_APP_DUPLICATE_VALUE){
      const response = bodyDecryption(res.data);
      return response 
    }else{
      //console.error("Looks like there was a problem. Status Code: " + res.status);
      return Promise.reject(error);
    }
    
  }
);  

function bodyEncryption(request, isStringify)
{
    var request = (isStringify) ? JSON.stringify(request) : request;
    var encrypted = CryptoJS.AES.encrypt(request, key, { iv: iv });
    return encrypted.toString();
}

function bodyDecryption(request)
{
    var decrypted = CryptoJS.AES.decrypt(request.toString(), key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
}




axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

const checkStatusCode = (code, err) => {
  if (code && [403, 501, 502, 503,404].includes(code)) {

    if (axiosApi.defaults.headers.common["token"] !== undefined) {
      LogoutUser()
      throw new Error(err.response.data.message)
    } else {
      throw new Error(err.response.data.message)
    }
  } else {
    throw new Error(err.message)
  }
}

export async function get(url, config = {}) {
  console.log("url", url, axiosApi?.baseURL);
  return await axiosApi
    .get(url, { ...config })
    .then(response => {
      console.log(response,"rres=====")
      // if (response.data.status === "success") {
      //   return response.data
      // } else {
      //   throw new Error(response.data.message)
      // }
      if (response.code == process.env.REACT_APP_SUCCESS) {
        return {...response, status_code: response.code, message: response.message}
      } else {
        throw new Error(response.data.message)
      }
    })
    .catch(e => {
      console.log(e,"rreeeees=====")
      checkStatusCode(!e.response ? null : e.response.status, e)
    })
}


export async function post(url, data, config = {}) {
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => {
      // console.log("response==d=>",(response))
      if (response.code == process.env.REACT_APP_SUCCESS) {
        return {...response.data, status_code: response.code, message: response.message}
      } else {
        // console.log("response===>",JSON.parse(response)?.message      )
        throw new Error(JSON.parse(response)?.message)
      }
    })
    .catch(e => {
      checkStatusCode(!e.response ? null : e.response.status, e)
    })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => {
      if (response.data.status === "success") {
        return response.data
      } else {
        throw new Error(response.data.message)
      }
    })
    .catch(e => {
      checkStatusCode(!e.response ? null : e.response.status, e)
    })
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => {
      if (response.data.status === "success") {
        return response.data
      } else {
        throw new Error(response.data.message)
      }
    })
    .catch(e => {
      checkStatusCode(!e.response ? null : e.response.status, e)
    })
}