import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FadeLoader from "react-spinners/FadeLoader";

//imported
import { LANGUAGE } from "common/viewContent";
import * as Path from "routes/Path/index";

import { toast } from "react-toastify";
import Table from "components/Table";
import Columns, { selectRow } from "./Columns";
import AddModal from "components/Modals/AddLanguage";
import inputFields from "./inputFields";
import Validation from "./Validation";
import {
  addLanguage,
  editLanguage,
  getLanguageList,
  getLanguagesCode,
  deleteLanguage
} from "store/language/actions";

const List = () => {
  const dispatch = useDispatch();
  const { langList, loading, languageCodes } = useSelector((state) => state.Language);
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);

  const [formData, setFormData] = useState({
    languageName: "",
    localeCode: "",
    status: "",
  });

  const [filter, setFilter] = useState({
    pageNo: 1,
    limit: 20,
    order: -1,
    orderBy: "createdAt",
    search: "",
    status: ""
  });

  const [addModalShow, setAddModalShow] = useState(false);

  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ deleteHandler, handleEdit }));
  }, []);

  async function getApiData(query) {
    try {
      dispatch(getLanguageList({ data: query }));
      dispatch(getLanguagesCode({}));
    }
    catch (error) {
      console.log(error)
    }
  }

  const handleStatus = (id, status) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          getApiData(filter);
          toast.success(res.message);
        }
      };

      dispatch(
        editLanguage({
          data: { _id: id, status: status },
          callback,
        })
      );
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleEdit = (info) => {
    // console.log(info);
    setFormData((prev) => {
      return {
        ...prev,
        _id: info._id,
        languageName: info.languageName,
        languageNativeName: info.languageNativeName,
        localeCode: info.localeCode,
        languageCode: info.languageCode,
        flagUrl: info.flagUrl,
        countryCode: info.countryCode,
        countryName: info.countryName,
        status: "active"
      };
    });
    setAddModalShow(true);
  };

  const handleSubmit_ = (info) => {
    // console.log("info ----------->",info);
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } 
        else {
          setAddModalShow(false);
          getApiData(filter);
          toast.success(res.message);
          setFormData({
            languageName: "",
            languageNativeName: "",
            localeCode: "",
            languageCode: "",
            flagUrl: "",
            countryCode: "",
            countryName: "",
            status: "",
          });
        }
      };
      if (info.hasOwnProperty("_id")) {
        dispatch(
          editLanguage({
            data: info,
            callback,
          })
        );
      } 
      else {
        // console.log("info--------->", info)
        dispatch(
          addLanguage({
            data: info,
            callback,
          })
        );
      }
    } 
    catch (error) {
      console.log(error, "<===err");
    }
  };

  const deleteHandler = (id) => {
    try {
      const userConfirmed = window.confirm("Are you sure, you want to delete this language?");
      if (userConfirmed) {
        const callback = (err, res) => {
          if (err) {
            toast.error(err);
          } else {
            getApiData(filter);
            toast.success(res.message);
          }
        };

        dispatch(
          deleteLanguage({
            data: { _id: id },
            callback,
          })
        );
      }
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  const languageOptionsArray = languageCodes.map(obj => {
    const key = Object.keys(obj)[0];
    const value = obj[key]; 
    return { value: key, label: value };
  });
  inputFields.find(field => field.type === 'dynamic_select').options = languageOptionsArray;
  // console.log("inputField", inputFields);

  return (
    <>
      <AddModal
        show={addModalShow}
        handleClose={() => {
          setAddModalShow(false);
          setFormData({
            languageName: "",
            languageNativeName: "",
            localeCode: "",
            languageCode: "",
            flagUrl: "",
            countryCode: "",
            countryName: "",
            status: "",
            _id: ""
          });
        }}
        title={`${formData.hasOwnProperty("_id") ? "Edit" : "Add"} Language`}
        content={""}
        handleConfirm={handleSubmit_}
        loading={loading}
        initialValues={formData}
        inputFields={inputFields}
        Validation={Validation}
        codes={languageCodes}
      // size="lg"
      />
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}


      <Helmet title={LANGUAGE.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{LANGUAGE.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{LANGUAGE.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{LANGUAGE.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add
                  </button>
                </div>
              </div>
              {/* <div className="col-6">
                <div className="card-header">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    // validationSchema={Validation}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    // submitting={null}
                    buttonText={"Search"}
                    // is_block={true}
                    // col={12}
                    // is_passwordHide={false}
                  />
                </div>
              </div> */}
            </div>
          </div>
          {!loading && langList?.length > 0 && (
            <Table
              data={langList}
              columns={columns}
              setFilter={setFilter}
              // total={language?.totalCount}
              // filter={filter}
              toggleConfirmModal={handleStatus}
            />
          )}
          {(!loading && langList?.length === 0) ||
            (langList?.length === 0 && (
              <p style={{ textAlign: "center" }}>No Language Found</p>
            ))}
        </div>
      </section>
    </>
  );
};

export default List;
