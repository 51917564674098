import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  FAQ: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "FAQ",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getFAQList.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getFAQList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      FAQ: payload.data,
      totalCount: payload.totalcount,
    }));
    builder.addCase(Act.getFAQList.rejected, (state, action) => ({
      ...state,
      loading: false,
      FAQ: [],
      totalCount: 0,
    }));


    //add faq 
    builder.addCase(Act.AddFAQ.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.AddFAQ.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
      }));
    builder.addCase(Act.AddFAQ.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

    //edit FAQ 
    builder.addCase(Act.editFAQ.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.editFAQ.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
      }));
    builder.addCase(Act.editFAQ.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

    //delete FAQ 
    builder.addCase(Act.deleteFAQ.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.deleteFAQ.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
      }));
    builder.addCase(Act.deleteFAQ.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

  },
});

export default slice;
