import { combineReducers } from "@reduxjs/toolkit";

//reducers
import authReducer from "./auth/reducer";
import userReducer from "./user/reducer";
import settingReducer from "./settings/reducer";
import levelReducer from "./level/reducer";
import faqReducer from "./faq/reducer";
import pageReducer from "./pages/reducer";
import languageReducer from "./language/reducer";
import badgeReducer from "./badges/reducer";
import avatarReducer from "./avatar/reducer";
import relativeRuducer from "./Relative/reducer"
import createQRCODEReducer from "./createqr/reducers"
import qrpriceReducer from "./qrprice/reducers"
import paymentsreducer from "./payments/reducers"
import dashboardReducer from "./dashboard/reducers"
import marchantReducer from "./Marchant/reducer"
import promoreducer from "./promo/reducer"
import feedbackreducer from "./feedback/reducer"
import doctorReducer from "./doctors/reducers"
import countryReducer from "./country/reducers"
import supportReducer from "./support/reducer"
import chemistReducer from "./Chemist/reducer"

const rootReducer = combineReducers({
  Auth: authReducer,
  Users: userReducer,
  Settings: settingReducer,
  Levels: levelReducer,
  Faq: faqReducer,
  Feedback: feedbackreducer,
  Doctor:doctorReducer,
  Page: pageReducer,
  Language: languageReducer,
  
  Badge: badgeReducer,
  Avatar: avatarReducer,
  Relative:relativeRuducer,
  CreateQr:createQRCODEReducer,
  Qrprice:qrpriceReducer,
  payment:paymentsreducer,
  dashboard:dashboardReducer,
  marchants:marchantReducer,
  promodata:promoreducer,
  countrydata:countryReducer,
  supportData:supportReducer,
  chemistData:chemistReducer,



  
});

export default rootReducer;