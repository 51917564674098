import { DATE_FORMAT } from "helpers/common";
import React from "react";
const Columns = ({ handleViewUser,API_IMAGE_URL }) => [
  {
    dataField: "link",
    text: "Image",
    formatter: (_, row) => (
      <span className="text-capitalize">
        <img
          src={API_IMAGE_URL + row?.link}
          alt="photos"
          className="img-fluid rounded "
          style={{ width: "50px", height: "50px" }}
        />
      </span>
    ),
  },
  {
    dataField: "title",
    text: "Title Name",
    formatter: (_, row) => <span className="text-capitalize">{row.title}</span>,
  },

  {
    text: "Created At",
    dataField: "dateCreated",
    formatter: (_, row) => DATE_FORMAT(row.dateCreated),
  },

  {
    text: "Action",
    dataField: "status",
    formatter: (_, row) => (
      <>
        {
          <button
            className="fas fa-trash-alt"
            style={{
              fontSize: "20px",
              color: "red",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="View"
            onClick={() => handleViewUser(row._id)}
          ></button>
        }
      </>
    ),
  },
];
export default Columns;
