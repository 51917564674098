import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  pagesList: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "Page",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getPageList.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getPageList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      pagesList: payload.data,
      totalCount: payload.totalcount,
    }));
    builder.addCase(Act.getPageList.rejected, (state, action) => ({
      ...state,
      loading: false,
      pagesList: [],
      totalCount: 0,
    }));

    //add content page 
    builder.addCase(Act.AddPage.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.AddPage.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
      }));
    builder.addCase(Act.AddPage.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

    //edit content page 
    builder.addCase(Act.editPage.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.editPage.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
      }));
    builder.addCase(Act.editPage.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

    //delete content page 
    builder.addCase(Act.deletePage.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.deletePage.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
      }));
    builder.addCase(Act.deletePage.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

  },
});

export default slice;
