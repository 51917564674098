import React from "react";
import {  useSelector } from "react-redux";
import { Container, Card, CardBody, Spinner, Alert } from "reactstrap";
// import Settings from "./Form";
import Settings2 from "./General";

const GeneralSettings = () => {
  
  const {  loading, error } = useSelector(
    (state) => state.Settings
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Settings /> */}
          <Settings2/>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GeneralSettings;
