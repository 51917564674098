import Table from "components/Table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { VIDEOLISTDATA } from "store/actions";
import Columns from "./Columns";
const Videos = (props) => {
  const [albumdata, setAlbumdata] = useState();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterAlbum, setFilterAlbum] = useState({
    limit: pageSize,
    order: 1,
    orderBy: "createdAt",
    pageNo: currentPage,
    search: "",
    userProfileId: props?.userId,
  });
  console.log(props, "props===============<>");
  // album listing api functions
  const AlbumList = (data) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          // console.log(res, "response  data ");
          setAlbumdata(res);
          // console.log(res, "data=======<>");
          toast.success(res.message);
        }
      };
      dispatch(VIDEOLISTDATA({ data: data, callback }));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (props?.userId) {
      AlbumList({ ...filterAlbum, userProfileId: props?.userId });

      setColumns(Columns({ handleViewUser }));
    }
  }, [filterAlbum, props?.userId]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleViewUser = () => {};
  return (
    <div>
      {albumdata?.data?.length > 0 && (
        <Table
          data={albumdata?.data}
          columns={columns}
          setFilter={setFilterAlbum}
          totalCount={albumdata?.totalcount}
          handleTableChange={handlePageChange}
          filter={filterAlbum}
        />
      )}
      {!albumdata?.data?.length && (
        <p style={{ textAlign: "center" }}>No videos Found</p>
      )}
    </div>
  );
};

export default Videos;
