import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { QRPRICECREATE } from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";
import Table from "components/Table";
import { toast } from "react-toastify";
import Columns from "./Columns";
import { CreateQRPrice, CreateQRPriceList, deleteQrPrice,EditQrPrice } from "store/actions";
import AddQrPrice from "components/Modals/AddQrPrice";
import EditQrPriceModal from "components/Modals/EditQrPrice";
import ConfirmModal from "components/Modals/ConfirmModal";
import { currencyOption } from "./inputFields";

const QrCreatePriceList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editModalShow, setEditModalShow] = useState(false);
  const { qrprice, loading } = useSelector((state) => state?.Qrprice);
  const [confirmModalQrPrice, setConfirmModalQrPrice] = useState();
  const [columns, setColumns] = useState([]);
  const [addModalShow, setAddModalShow] = useState(false);
  const [qrData, setQrData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
    order: 1,
    orderBy: "dateCreatedUtc",
    search: "",
  });

  console.log(currencyOption, "currencyOption");
  const [formData, setFormData] = useState({
    count: "",
    price: "",
    currency: currencyOption[2],
    currencySymbol: "",
    discount: "",
  });

  const fetchData = async (filter) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setQrData(res);
          toast.success(res.message);
        }
      };
      dispatch(CreateQRPriceList({ data: filter, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };

  useEffect(() => {
    fetchData(filter);
    setColumns(Columns({ handleDeletehandler, handleEdithandler }));
  }, [filter]);

  const handleSubmit_ = (info) => {
    const selectedOption = currencyOption.find(
      (option) => option.value === info.currency
    );
    const selectedCurrencySymbol = selectedOption.symbol;
    let data = {
      count: info.count,
      price: info.price,
      currency: info.currency,
      currencySymbol: selectedCurrencySymbol,
      discount: info.discount,
    };
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err.message);
        } else {
          toast.success("Membership Created successfully");
          fetchData(filter);

          setAddModalShow(false);
        }
      };
      dispatch(
        CreateQRPrice({
          data: data,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };

  const handleDeletehandler = (id) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          fetchData(filter);
          toast.success("Membership Deleted successfully!");
          setConfirmModalQrPrice(false);
        }
      };

      dispatch(
        deleteQrPrice({
          data: id,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };

  const handleEdithandler = (info) => {
    console.log(info, "ghdfjhdfshdf");
    setFormData({ ...formData, ...info });
    setEditModalShow(true);
  };
  const handleEditSub = (info) => {
    console.log(info, "EditQrPriceshdjf");
    try {
      let data = {
        _id: info?._id,
        count: info.count,
        price: info.price,
        currency: info.currency,
        currencySymbol: info.currencySymbol,
        discount: info.discount,
      };
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setEditModalShow(false);
          fetchData(filter);
          toast.success("Memembership updated successfully!");
          setFormData({});
        }
      };
      dispatch(
        EditQrPrice({
          data: data,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <AddQrPrice
        show={addModalShow}
        handleClose={() => setAddModalShow(false)}
        handleConfirm={handleSubmit_}
        title="Add Memembership"
        loading={loading}
        initialValues={formData}
      />
      <EditQrPriceModal
        show={editModalShow}
        handleClose={() => setEditModalShow(false)}
        handleConfirm={handleEditSub}
        title="Edit MememberShip "
        loading={loading}
        initialValues={formData}
      />
      <ConfirmModal
        show={confirmModalQrPrice}
        handleClose={() => setConfirmModalQrPrice(false)}
        title={"Delete Qr Price"}
        content={"Are you sure you want to delete the Memembership?"}
        handleConfirm={handleDeletehandler}
        loading={loading}
      />

      <Helmet title={QRPRICECREATE?.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{QRPRICECREATE?.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">
              {QRPRICECREATE?.CURRENT_MODULE}
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">
                    {QRPRICECREATE?.TOP_CONTENT}
                  </h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Create Qr Price
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!loading && (
            <Table
              data={qrData}
              columns={columns}
              setFilter={setFilter}
              totalCount={qrprice?.totalcount}
              handleTableChange={handlePageChange}
              filter={filter}
            />
          )}
          {!loading && qrprice?.data?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Qr data Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default QrCreatePriceList;
