const PAGE_LIMIT = 10;
const LOGIN = {
  CURRENT_MODULE: "Login",
  TOP_CONTENT: "Welcome back",
  MAIN_CONTENT: "Login to your account",
  BUTTON: "Login",
};
const FORGOT_PASSWORD = {
  CURRENT_MODULE: "Forgot Password",
  MAIN_CONTENT: "We will send a link to reset your password",
  BUTTON: "Forgot Password",
  BACK: "Back to login ?",
};
const RESET_PASSWORD = {
  CURRENT_MODULE: "Reset Password",
  BUTTON: "Reset Password",
  BACK: "Back to login ?",
};
const CHANGE_PASSWORD = {
  CURRENT_MODULE: "Change Password",
  BUTTON: "Submit",
};
const PROFILE = {
  CURRENT_MODULE: "Profile",
};
const DASHBOARD = {
  CURRENT_MODULE: "Dashboard",
};

const EMAIL_TEMPLATE = {
  CURRENT_MODULE: "Email Template",
};

const NOTIFICATION = {
  CURRENT_MODULE: "Notification",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};

const USER = {
  CURRENT_MODULE: "User",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const PROMOS = {
  CURRENT_MODULE: "Promo Code",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const QRCREATE = {
  CURRENT_MODULE: "Generate QR",
  TOP_CONTENT: "Listing",
  ADD: "Create QR Code",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const QRPRICECREATE = {
  CURRENT_MODULE: "Membership",
  TOP_CONTENT: "Listing",
  ADD: "Create QR Code",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const PAYMENTS = {
  CURRENT_MODULE: "Orders",
  TOP_CONTENT: "Listing",
  ADD: "Payment",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const PROMODETAILLPAGE = {
  CURRENT_MODULE: "Doctor View",
  TOP_CONTENT: "Details",
  ADD: "Payment",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const MEETINGDETAILLPAGE = {
  CURRENT_MODULE: "Meeting Record",
  TOP_CONTENT: "Details",
  ADD: "Payment",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const CHEMISTVIEWS = {
  CURRENT_MODULE: "Chemist View",
  TOP_CONTENT: "Details",
  ADD: "Payment",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const PATIENTDETAILLPAGE = {
  CURRENT_MODULE: "Patient View",
  TOP_CONTENT: "Details",
  ADD: "Payment",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const PROMPTDETAIL = {
  CURRENT_MODULE: "Prompt View",
  TOP_CONTENT: "Details",
  ADD: "Payment",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const SUPPORTVIEWS = {
  CURRENT_MODULE: "Support View",
  TOP_CONTENT: "Details",
  ADD: "Payment",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const PAYMENTSDETAILS = {
  CURRENT_MODULE: "Order Details",
  TOP_CONTENT: "Details",
  ADD: "Payment",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};

const FAQ = {
  CURRENT_MODULE: "FAQ",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};

const BADGE = {
  CURRENT_MODULE: "Badges",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};

const SYSTEM_ACCESS = {
  CURRENT_MODULE: "System Access",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const CONTENT_PAGES = {
  CURRENT_MODULE: "Content Pages",
  TOP_CONTENT: "Listing",
};

const EARNING = {
  CURRENT_MODULE: "Transaction Earning",
  TOP_CONTENT: "Listing",
  VIEW: "View",
};

const EMAIL_TEMPLETE = {
  CURRENT_MODULE: "Email Templete",
  TOP_CONTENT: "Listing",
  VIEW: "View",
};
const CONFIGURATION = {
  CURRENT_MODULE: "Configuration",
  TOP_CONTENT: "Detail",
};
const MARCHANT = {
  CURRENT_MODULE: "Merchant",
  TOP_CONTENT: "Listing",
};
const QRHISTORY = {
  CURRENT_MODULE: "Linked QR history",
  TOP_CONTENT: "Listing",
};

const MARCHANTVIEW = {
  CURRENT_MODULE: "Merchant View",
  TOP_CONTENT: "Details",
};

const LEVEL = {
  CURRENT_MODULE: "Levels",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};

const FAQS = {
  CURRENT_MODULE: "FAQ",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};

const FEEDBACKS = {
  CURRENT_MODULE: "Feedback",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const CHEMISTDATA = {
  CURRENT_MODULE: "CHEMIST",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const DOCTOR = {
  CURRENT_MODULE: "Doctor",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const ADMIN = {
  CURRENT_MODULE: "logs",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const SUPPORT = {
  CURRENT_MODULE: "Support",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const PATIENT = {
  CURRENT_MODULE: "Patient",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const PROMPT = {
  CURRENT_MODULE: "Prompt",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};

const LANGUAGE = {
  CURRENT_MODULE: "Languages",
  TOP_CONTENT: "Listing",
};

const AVATAR = {
  CURRENT_MODULE: "Avatar",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};

const AVATAR_CATEGORY = {
  CURRENT_MODULE: "Avatar Categories",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};

const AVATAR_CHARACTERSTIC = {
  CURRENT_MODULE: "Avatar Characterstics",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};

export {
  FEEDBACKS,
  EMAIL_TEMPLATE,
  PAGE_LIMIT,
  LOGIN,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  PROFILE,
  DASHBOARD,
  SYSTEM_ACCESS,
  FAQ,
  CONTENT_PAGES,
  CHANGE_PASSWORD,
  CONFIGURATION,
  USER,
  LEVEL,
  FAQS,
  LANGUAGE,
  BADGE,
  AVATAR,
  AVATAR_CATEGORY,
  AVATAR_CHARACTERSTIC,
  QRCREATE,
  QRPRICECREATE,
  PAYMENTS,
  PAYMENTSDETAILS,
  MARCHANT,
  MARCHANTVIEW,
  QRHISTORY,
  PROMOS,
  PROMODETAILLPAGE,
  DOCTOR,
  PATIENT,
  PATIENTDETAILLPAGE,
  ADMIN,
  SUPPORT,
  SUPPORTVIEWS,
  PROMPT,
  PROMPTDETAIL,
  CHEMISTDATA,
  CHEMISTVIEWS,
  MEETINGDETAILLPAGE
};
