import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";

import { get, post, put, del } from "helpers/api_helper";

export const CreateQRPrice = createAsyncThunk(
  "CreateQRPrice",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.QRPRICE_CREATE, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

//qr details api calling
export const CreateQRPriceList = createAsyncThunk(
  "CreateQRPriceList",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.QRPRICE_LIST, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

// delete qr price
export const deleteQrPrice = createAsyncThunk(
  "deleteQrPrice",
  async ({ data, callback }, thunkAPI) => {
    try {
      const response = await del(URL.QRPRICE_LIST_DELETE + data);
      console.log(data, "data----------->");
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error, null);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const EditQrPrice = createAsyncThunk(
  "EditQrPrice",
  async ({ data, callback }, thunkAPI) => {
    
    try {
      let payload = JSON.parse(JSON.stringify(data));
      delete payload._id;
      const response = await put(URL.QRPEICEEDIT + data._id, data);
      console.log(data, "data----------->");
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error, null);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
