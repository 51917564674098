import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
//imported
import { FEEDBACKS } from "common/viewContent";
import * as Path from "routes/Path/index";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns, { selectRow } from "./Columns";
import AddModal from "components/Modals/AddFaq";
import inputFields from "./inputFields";
import Validation from "./Validation";
import { getFeedbackList } from "store/feedback/actions";
import { getLanguageList } from "store/language/actions";
import { Link, useNavigate } from "react-router-dom";

const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { FEEDBACK, loading, totalCount } = useSelector(
    (state) => state?.Feedback
  );
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);
  const [addModalShow, setAddModalShow] = useState(false);
  const [feedback, setFeedback] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
  });

  const onCliclNavigate = (id) => {
    navigate(`/admin/feedback/${id}`);
  };

  const fetchData = async (filter) => {
    const payload = {
      ...filter,
      page: filter?.pageNo,
      record_count: filter?.limit,
    };
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setFeedback(res);
        }
      };
      dispatch(getFeedbackList({ data: payload, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  useEffect(() => {
    fetchData(filter);
    setcolumns(Columns({ onCliclNavigate, currentPage: filter?.pageNo,pageSize}));
  }, [filter]);
  const handlePageChange = (pageNumber) => {
    console.log("pageNumber->", pageNumber);
    setCurrentPage(pageNumber);
  };

console.log(feedback,"feedback", FEEDBACK, loading, totalCount )
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={FEEDBACKS.CURRENT_MODULE} />

      <section className="section">
        <div className="section-header">
          <h1>{FEEDBACKS.CURRENT_MODULE}</h1>

          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{FEEDBACKS.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{FEEDBACKS.TOP_CONTENT}</h2>
                </div>
              </div>
              {/* <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add Feedna
                  </button>
                </div>
              </div> */}
              {/* {filter.languageId && (
                <div className="container">
                  <div className="col-6">
                    <FormikForm
                      initialValues={{ select: filter.languageId }}
                      // validationSchema={Validation}
                      onSubmit={handleFilterForm}
                      loading={loading}
                      inputFields={InputFieldsForSearch}
                      // submitting={null}
                      buttonText={"Search"}
                      // is_block={true}
                      col={6}
                      // is_passwordHide={false}
                    />
                  </div>
                </div>
              )} */}
            </div>
          </div>
          {!loading && FEEDBACK?.data?.length > 0 && (
            <Table
              data={FEEDBACK.data}
              columns={columns}
              setFilter={setFilter}
              handleTableChange={handlePageChange}
              totalCount={totalCount}
              filter={filter}
            />
          )}
          {!loading && FEEDBACK?.data?.length === 0 && (
            <p style={{ textAlign: "center" }}>No FeedBack Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
