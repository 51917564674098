import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";


const Columns = ({ onCliclNavigate,currentPage,pageSize }) => [
  {
    dataField: "",
    text: "SrNo",
    formatter: (cell, row, rowIndex) => (
      <span className="text-capitalize cursor-pointer hover-lightblue">{((currentPage - 1) * pageSize) + (rowIndex + 1)}</span>
    ),
  },
  {
    dataField: "first_name",
    text: "Name",
    formatter: (_, row) => (
      <span onClick={()=>onCliclNavigate(row?.id)} className="text-capitalize cursor-pointer hover-lightblue">{row.first_name}</span>
    ),
  },
  {
    dataField: "email1",
    text: "Email",
    formatter: (_, row) => (
      <span onClick={()=>onCliclNavigate(row?.id)}  className="text-capitalize cursor-pointer hover-lightblue">{row?.email1 || "--"}</span>
    ),
  },

  {
    dataField: "comments",
    text: "Comments",
    formatter: (_, row) => (
      <span onClick={()=>onCliclNavigate(row?.id)}  className="text-capitalize cursor-pointer hover-lightblue">{row?.comments || "--"}</span>
    ),
  }, 
  {
    text: "Created At",
    dataField: "createdAt",
    formatter: (_, row) => DATE_FORMAT(row.createdAt),
  },
  // {
  //   text: "Action",
  //   // dataField: "Inactive",
  //   formatter: (_, row) => (
  //     <>
  //       <button
  //         className="fas fa-pen"
  //         title="Edit"
  //         style={{
  //           backgroundColor: "#fff",
  //           border: "none",
  //         }}
  //         onClick={() => handleEdit(row)}
  //       ></button>
  //       {/* {!row.Inactive ? (
  //         <button
  //           className="fas fa-toggle-on"
  //           style={{
  //             fontSize: "20px",
  //             color: "green",
  //             backgroundColor: "#fff",
  //             border: "none",
  //           }}
  //           title="Active"
  //           onClick={() => handleStatus(row._id, true)}
  //         ></button>
  //       ) : (
  //         <button
  //           className="fas fa-toggle-off"
  //           style={{
  //             fontSize: "20px",
  //             color: "red", 
  //             backgroundColor: "#fff",
  //             border: "none",
  //           }}
  //           title="Inactive"
  //           onClick={() => handleStatus(row._id, false)}
  //         ></button>
  //       )} */}
  //       {
  //         <button
  //           className="fas fa-trash-alt"
  //           style={{
  //             backgroundColor: "#fff",
  //             border: "none",
  //           }}
  //           title="Inactive"
  //           onClick={() => deleteHandler(row._id)}
  //         ></button>
  //       }
  //     </>
  //   ),
  // },
];
export default Columns;
