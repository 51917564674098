import React, { useState } from "react";
import classNames from "classnames";
import { Link, NavLink } from "react-router-dom";
import { useCustomHistory } from "common/getHistory";
import { mainModule } from "common/modules";
import { Accordion } from "react-bootstrap";

const Sidebar = () => {
  const path = useCustomHistory();
  // const settings = useSelector((state) => state.ICO.Settings.settings)

  const [dropdownStates, setDropdownStates] = useState(false);
  const [dropUrl, setDropurl] = useState("");
  const [modules, setModules] = useState(mainModule);

  //sidebar menu bar
  const sidebarHideShow = () => {
    if (document.body.classList.contains("sidebar-show")) {
      document.body.classList.add("sidebar-gone");
      document.body.classList.remove("sidebar-mini");
      document.body.classList.remove("sidebar-show");
    }
  };

  const sidebarStyle = {
    maxHeight: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    // overflow: 'hidden',
    outline: "none",
  };
  const toggleDropdown = (dropUrl) => {
    console.log(dropUrl, "dropUrl");
    if (path === dropUrl) {
      setDropdownStates(!dropdownStates);
    } else setDropdownStates(true);
  };
  const toggleDropdown_ = (url) => {
    setDropurl(url);
    toggleDropdown(url);
  };

  return (
    <div className="main-sidebar sidebar-style-2" style={sidebarStyle}>
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand">
          {/* <p className="text-white">Logo</p> */}
          <img
            onClick={(e) => {
              e.preventDefault(); // Prevent the default behavior of the Link
              window.location.reload(); // Refresh the page
            }}
            style={{ cursor: "pointer" }}
            src={"/assets/img/logo.png"}
            style={{height: 80,}}
            className="main-sidebar-logo object-fit-contain img-fluid fullIcn"
          />
          <img
            onClick={(e) => {
              e.preventDefault(); // Prevent the default behavior of the Link
              window.location.reload(); // Refresh the page
            }}
            style={{ cursor: "pointer" }}
            src={"/assets/img/logo.png"}
            className="main-sidebar-logo img-fluid icn"
          /> 
        </div>
        <div className="sidebar">
          <ul className="sidebar-menu">
            {modules.length > 0 &&
              modules.map((value, key) => {
                console.log("key", key);
                let module = value.sub_modules ? value.sub_modules : "";
                let sub_module = value.is_modules
                  ? path === value.url ||
                    path === module.form ||
                    path === module.edit ||
                    path === module.view
                  : path === value.url;
                // console.log(dropUrl, "dropUrl")
                return (
                  <>
                    {/* {roleData == 1 && ( */}
                    <div key={key}>
                      {value.is_sidebar && !value.is_dropdown && (
                        <li
                          className={classNames("", { active: sub_module })}
                          to={value.url}
                        >
                          <NavLink
                            className="nav-link"
                            onClick={sidebarHideShow}
                            to={value.url}
                            //   title={value.name}
                          >
                            {/* <i className={value.sidebar_icon}></i>{" "} */}

                            {value.sidebar_icon}
                            <span>{value.name}</span>
                          </NavLink>
                          {/* </OverlayTrigger> */}
                        </li>
                      )}
                      {value.is_dropdown && (
                        <li
                          className={`dropdown ${
                            dropdownStates ? "active" : ""
                          }`}
                          // onClick={() => toggleDropdown(dropUrl)}
                        >
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item
                              className="border-0 bg-transparent"
                              eventKey="1"
                            >
                              <Accordion.Header className="border-0 bg-trnasparent p-0">
                                {value.sidebar_icon}
                                <span className="w-80 p-1">{value.name}</span>
                              </Accordion.Header>
                              <Accordion.Body className="">
                                {value.dropdown_module.length > 0 && (
                                  <ul className="ps-0 mb-0">
                                    {value.dropdown_module.map((drop, i) => {
                                      return (
                                        <li
                                          key={i}
                                          className={`dropdown ${
                                            drop.url === path ? "active" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDropdown_(drop.url)
                                          }
                                        >
                                          <NavLink
                                            className="nav-link"
                                            to={drop.url}
                                          >
                                            {value.sidebar_icon}
                                            {drop.name}
                                          </NavLink>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </li>
                      )}
                    </div>
                    {/* )} */}
                  </>
                );
              })}
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
