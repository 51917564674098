import React from "react";

import Badge from "react-bootstrap/Badge";

import { DATE_FORMAT } from "helpers/common";

const STATUS = {
  active: "Active",
  inactive: "Inactive",
  linked: "linked",
  pending: "Pending",
};

const columns = ({ handleDeletehandler,handleEdithandler }) => [
  {
    dataField: "count",
    text: "Count",
  },
  {
    dataField: "currency",
    text: "Currency",
  },
  {
    dataField: "price",
    text: "Price",
    formatter: (_, row) => (
      <span className="text-capitalize">
        {row?.price?  row.currencySymbol +" " + row.price: "---"}
      </span>
    ),
  },
  {
    dataField: "discountedPrice",
    text: "DiscountedPrice",
    formatter: (_, row) => (
      <span className="text-capitalize">
        {row?.discountedPrice?  row.currencySymbol +" " + row.discountedPrice: "---"}
      </span>
    ),
  },
  {
    dataField: "discount",
    text: "Discount",
    formatter: (_, row) => (
      <span className="text-capitalize">
        {row?.discount?  row.currencySymbol +" " + row.discount: 0}
      </span>
    ),
  },
  {
    dataField: "pricePerUnit",
    text: "PricePerUnit",
    formatter: (_, row) => (
      <span className="text-capitalize">
        {row?.pricePerUnit ? row.currencySymbol + " " + parseFloat(row.pricePerUnit).toFixed(2) : "---"}
      </span>
    ),
  },
  
  {
    dataField: "status",
    text: "Status",
    formatter: (cellContent, row) => (
      <Badge
        className={`text-capitalize font-size-13 badge-soft-${
          row.status === "pending" ? "warning" : "success"
        }`}
      >
        {STATUS[row.status]}
      </Badge>
    ),
  },
  {
    dataField: "createdAt",
    text: "Created At",
    formatter: (_, row) => DATE_FORMAT(row.createdAt),
  },

  {
    text: "Action",
    dataField: "status",
    formatter: (_, row) => (
      <>
        <button
          className="fas fa-pen"
          style={{
            fontSize: "16px",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="Edit"
          onClick={() => handleEdithandler(row)}
        ></button>
        {
          <button
            className="fas fa-trash-alt"
            style={{
              fontSize: "20px",
              color: "red",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="delete"
            onClick={() => handleDeletehandler(row._id)}
          ></button>
        }
      </>
    ),
  },
];
export default columns;
