import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  promodata: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "promodata",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // promo listing
    builder.addCase(Act.PROMO.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.PROMO.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      promodata: payload,
    }));
    builder.addCase(Act.PROMO.rejected, (state, action) => ({
      ...state,
      loading: false,
      promodata: null,
    }));
    // add promo
    builder.addCase(Act.ADDPROMO.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(
      Act.ADDPROMO.fulfilled,
      (state, { payload }) => (
        console.log(payload),
        {
          ...state,
          loading: false,
        }
      )
    );
    builder.addCase(Act.ADDPROMO.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
    // promo update
    builder.addCase(Act.UPDATEPROMO.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.UPDATEPROMO.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(Act.UPDATEPROMO.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
    // delete promo
    builder.addCase(Act.DELETEPROMO.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.DELETEPROMO.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(Act.DELETEPROMO.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
    // promo details get
    builder.addCase(Act.PROMOGET_DEATILS.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.PROMOGET_DEATILS.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      
    }));
    builder.addCase(Act.PROMOGET_DEATILS.rejected, (state, action) => ({
      ...state,
      loading: false,
      
    }));
  },
});

export default slice;
