import React from "react";
import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import { customSortCaret } from "constants";
const STATUS = {
  active: "Active",
  inactive: "Inactive",
  blocked: "Blocked",
  archived: "Archived",
  pending: "Pending",
};

const Columns = ({
  handleUserStatus,
  handleEdithandler,
  deleteHandler,
  handleViewUser,
}) => [
  {
    dataField: "_id",
    text: "Promo Id",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => <span className="text">{row._id}</span>,
  },
  {
    dataField: "name",
    text: "Promo Name",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => <span className="text">{row.name}</span>,
  },
  {
    dataField: "price",
    text: "Promo Price",
    sort: false,
    formatter: (_, row) => <span className="text-capitalize">{row.price}</span>,
  },

  {
    dataField: "type",
    text: "Promo Type",
    sort: false,
    formatter: (_, row) => <span className="text">{row?.type}</span>,
  },
  {
    dataField: "promoAppliedCount",
    text: "Applied Count",
    sort: false,
    formatter: (_, row) => (
      <span className="text">
        {row?.promoAppliedCount ? row?.promoAppliedCount : "---"}
      </span>
    ),
  },

  {
    text: "Created At",
    dataField: "dateCreatedUtc",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => DATE_FORMAT(row.dateCreated),
  },

  {
    text: "Status",
    dataField: "status",
    sort: false,
    formatter: (cellContent, row) => (
      <Badge
        className={`text-capitalize font-size-13 badge-soft-${
          row.status === "pending" ? "warning" : "success"
        }`}
        color={row.badgeClass}
      >
        {STATUS[row.status]}
      </Badge>
    ),
  },
  {
    text: "Action",
    dataField: "",
    formatter: (_, row) => (
      <>
        {/* {row?.status === "active" ? (
          <button
            className="fas fa-toggle-on"
            style={{
              fontSize: "20px",
              color: "green",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Active"
            onClick={() => handleUserStatus(row._id, "inactive")}
          ></button>
        ) : (
          <button
            className="fas fa-toggle-off"
            style={{
              fontSize: "20px",
              color: "red",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="inactive"
            onClick={() => handleUserStatus(row._id, "active")}
          ></button>
        )} */}
        <button
          className="fa fa-eye"
          style={{
            fontSize: "20px",
            color: "green",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="View"
          onClick={() => handleViewUser(row._id)}
        ></button>
        {
          <button
            className="fa fa-pen"
            style={{
              fontSize: "20px",
              color: "black",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="edit"
            onClick={() => handleEdithandler(row)}
          ></button>
        }
        {
          <button
            className="fa fa-trash-alt"
            style={{
              fontSize: "20px",
              color: "red",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="delete"
            onClick={() => deleteHandler(row._id)}
          ></button>
        }
      </>
    ),
  },
];
export default Columns;
