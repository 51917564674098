// inputFields.js
const InputFields = [
  {
    name: "status",
    type: "select",

    placeholder: "Select status",
    options: [
      { value: "Completed", label: "Completed" },
      { value: "Pending", label: "Pending" },
    ],
  },
];

export default InputFields;
