import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";

import { get, post, put, del } from "helpers/api_helper";

export const USER_RELATIVE = createAsyncThunk(
  "USER_RELATIVE",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.USER_RELATIVE, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      s;
      return Thunk.rejectWithValue(error);
    }
  }
);

export const USER_RELATIVE_PROFILE = createAsyncThunk(
  "USER_RELATIVE_PROFILE",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await get(URL.USER_RELATIVE_PROFILE + data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const deletePhoto = createAsyncThunk(
  "deletePhoto",
  async ({ data, callback }, thunkAPI) => {
    try {
      const response = await del(URL.DELETE_PHOTO + data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteVideo = createAsyncThunk(
  "deleteVideo",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await del(URL.DELETE_VIDEO + data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const updateRelativeProfile = createAsyncThunk(
  "updateRelativeProfile",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await put(URL.UPDATE_USERPROFILE + data?._id, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const UPDATEUSER_RELATIVE_STATUS = createAsyncThunk(
  "UPDATEUSER_RELATIVE_STATUS",
  async ({ data, callback }, Thunk) => {
    console.log(data, "data arcieve------->");

    try {
      let payload = JSON.parse(JSON.stringify(data));
      delete payload._id;
      let response = await put(URL.UPDATE_USERPROFILE + data._id, payload);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const updateVideo = createAsyncThunk(
  "updateVideo",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await put(URL.UPDATE_VIDEO + data?._id, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const updateImage = createAsyncThunk(
  "updateImage",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await put(URL.UPDATE_IMAGE + data?._id, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const LINK_QR_TO_USER = createAsyncThunk(
  "LINK_QR_TO_USER",
  async ({ qrCodeID, userProfileId, callback }, Thunk) => {
    try {
      let response = await put(URL.LINK_QR_TO_USERS + qrCodeID, {
        userProfileId,
      });
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const UNLINK_QR_TO_USER = createAsyncThunk(
  "UNLINK_QR_TO_USER",
  async ({ qrCodeID, userProfileId, type, callback }, Thunk) => {
    try {
      let response = await put(URL.LINK_QR_TO_USERS + qrCodeID, {
        userProfileId,
        type,
      });
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }             
  }
);
export const DownloadQrlink = createAsyncThunk(
  "DownloadQrlink",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await get(URL.Downloadlink);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const ALBUMPHOTOLIST = createAsyncThunk(
  "ALBUMPHOTOLIST",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.AlbumphotoList,data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const ALBUMDATALIST = createAsyncThunk(
  "ALBUMDATALIST",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await get(URL.ALBUMGETDATA+data.userProfileId);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const PHOTOLISTDATA = createAsyncThunk(
  "PHOTOLISTDATA",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.PHOTOAPIEND,data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const VIDEOLISTDATA = createAsyncThunk(
  "VIDEOLISTDATA",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.VIDEOAPIEND,data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
