import React from "react";
import { DATE_FORMAT } from "helpers/common";
import { customSortCaret } from "constants";
const STATUS = {
  active: "Active",
  inactive: "Inactive",
  blocked: "Blocked",
  archived: "Archived",
  pending: "Pending",
};

const Columns = ({ currentPage, pageSize, handleViewUser }) => [
  {
    dataField: "",
    text: "SrNo",
    formatter: (cell, row, rowIndex) => (
      <span className="text-capitalize cursor-pointer">
        {(currentPage - 1) * pageSize + (rowIndex + 1)}
      </span>
    ),
  },

  {
    dataField: "email",
    text: "Email",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text">{row?.doctor_information?.doctor_email}</span>
    ),
  },
  {
    dataField: "Doctor_full_name",
    text: "Doctor Name",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text ">
        {/* <img src={row?.patient_information?.profile_image} alt=""  style={{width:"40px",height:"40px" ,rounded:"circle"}} /> */}
        {row?.doctor_information?.doctor_full_name}
      </span>
    ),
  },
  {
    dataField: "doctor_phone_no",
    text: "Patient Number",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text">{row?.doctor_information?.doctor_phone_no}</span>
    ),
  },

  {
    dataField: "appointment_date",
    text: "Appointment Date",
    sort: true,
    formatter: (_, row) => <span className="text">{row.appointment_date}</span>,
  },
  {
    text: "Appointment Time",
    dataField: "appointment_time",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => <span className="text">{row.appointment_time}</span>,
  },
  {
    text: "Status",
    dataField: "status",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text  " style={{ background: "", color: "#227fd5" }}>
        Done
      </span>
    ),
  },
  {
    text: "Action",
    dataField: "",
    formatter: (_, row) => (
      <>
        <button
          className="p-2 shadow rounded-2"
          style={{
            fontSize: "12px",
            color: "white",
            backgroundColor: "#227fd5",
            border: "none",
          }}
          title="Record View"
          onClick={() => handleViewUser(row)}
        >
          Meeting Record
        </button>
      </>
    ),
  },
];

export default Columns;
