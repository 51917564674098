import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import FormikForm from "components/shared/FormikForm";
// import Validation from "views/doctor/Validation";
import InputFields from "views/prompt/inputFields";
import { Country, State, City } from 'country-state-city';

export default function AddPrompt({
  show,
  handleClose,
  handleConfirm,
  title,
  loading,
  initialValues,

}) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
       size="md"
    >
      <Modal.Header className="py-3 text-dark" closeButton>
        <Modal.Title className="fw-bold text-dark">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikForm
          initialValues={initialValues}
          //   validationSchema={Validation}
          onSubmit={handleConfirm}
          loading={loading}
          inputFields={InputFields}
          col={12}
          buttonText={"Submit"}
        />
      </Modal.Body>
    </Modal>
  );
}