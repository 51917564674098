import React from "react";
//imported
import * as Path from "../Path";
//dashboard
import Dashboard from "views/dashboard/Dashboard";

// feedback
import Feedback from "views/feedbacks/List";
import FeedbackDetail from "views/feedbacks/feedbackDetail/index";
// doctor list
import Doctor from "views/doctor/List";
import Patient from "views/patient/List";

//User
import UserList from "views/users/List";
import Login from "views/auth/Login/Login";
import ForgetPassword from "views/auth/Forget-password/ForgotPassword";
import GeneralSettings from "views/settings/General/index";
import LevelSettings from "views/settings/Level/List";
import Profile from "views/auth/profile/index";
import ChangePassword from "views/auth/change_password/index";
import FAQList from "views/faq/List";
import ContentPage from "views/pages/List";
import LanguageList from "views/language/List";
import BadgeList from "views/badges/List";
import AvatarList from "views/avatar/avatarList/List";
import AvatarCategory from "views/avatar/avatarCategory/List";
import AvatarCharacterstic from "views/avatar/avatarCharacterstic/List";
import ProfileSingle from "views/Relative/UserDetails";
import UserRelativeProfile from "views/Relative/UserRelativeProfile";
import QrCodeCreate from "views/createqrcode/QrCodeCreate";
import QrCreatePriceList from "views/QrPrice/QrCreatePriceList";
import Payment from "views/payments/payment";
import PaymentDetailsPage from "views/payments/paymentDetailPage";
import Marchant from "views/marchant/List";
import MarchantView from "views/marchant/MarchantViewDetails/MerchantView";
import QrLinkedHistory from "views/Qrhistory/list";
import AlbumPage from "views/Relative/ViewAlbum/index";
import Promos from "views/promos";
import PromosDetails from "views/promos/promoView/index";
import DoctorDetails from "views/doctor/details";
import PatientDetails from "views/patient/detail";
import AdminLogs from "views/Admin/List";
import Support from "views/Support/List";
import SupportDetails from "views/Support/detail/index";
import Prompt from "views/prompt/list";
import PromptView from "views/prompt/detail/index";
import Chemist from "views/chemist/List";
import ChemistDetails from "views/chemist/detail";
import MeetingHistoryDetail from "components/MeetingHistory/detail";
import MeetingPatientDetail from "components/meetingHistoryPatient/detail";

const routes = [
  {
    path: Path.dashboard,
    exact: true,
    auth: false,
    component: <Dashboard />,
  },
  {
    path: Path.doctor,
    exact: true,
    auth: false,
    component: <Doctor />,
  },
  {
    path: Path.doctorview,
    exact: true,
    auth: false,
    component: <DoctorDetails />,
  },
  {
    path: Path.patientview,
    exact: true,
    auth: false,
    component: <PatientDetails />,
  },

  {
    path: Path.patient,
    exact: true,
    auth: false,
    component: <Patient />,
  },
  {
    path: Path.feedback,
    exact: true,
    auth: false,
    component: <Feedback />,
  },
  {
    path: Path.adminlogs,
    exact: true,
    auth: false,
    component: <AdminLogs />,
  },
  {
    path: Path.support,
    exact: true,
    auth: false,
    component: <Support />,
  },
  {
    path: Path.prompt,
    exact: true,
    auth: false,
    component: <Prompt />,
  },
  {
    path: Path.chemist,
    exact: true,
    auth: false,
    component: <Chemist />,
  },
  {
    path: Path.meetingpatientview,
    exact: true,
    auth: false,
    component: <MeetingPatientDetail />,
  },
  {
    path: Path.meetingview,
    exact: true,
    auth: false,
    component: <MeetingHistoryDetail />,
  },
  {
    path: Path.chemistView,
    exact: true,
    auth: false,
    component: <ChemistDetails />,
  },
  {
    path: Path.promptview,
    exact: true,
    auth: false,
    component: <PromptView />,
  },
  {
    path: Path.supportview,
    exact: true,
    auth: false,
    component: <SupportDetails />,
  },
  {
    path: Path.feedbackDetailPage,
    exact: true,
    auth: false,
    component: <FeedbackDetail />,
  },
  {
    path: Path.users,
    exact: true,
    auth: true,
    component: <UserList />,
  },
  {
    path: Path.login,
    exact: true,
    auth: false,
    fallback: true,
    component: <Login />,
  },
  {
    path: Path.forgot_password,
    exact: true,
    auth: false,
    component: <ForgetPassword />,
  },
  {
    path: Path.changePassword,
    exact: true,
    auth: false,
    component: <ChangePassword />,
  },
  {
    path: Path.general_setting,
    exact: true,
    auth: false,
    component: <GeneralSettings />,
  },
  {
    path: Path.level_setting,
    exact: true,
    auth: false,
    component: <LevelSettings />,
  },
  {
    path: Path.profile,
    exact: true,
    auth: false,
    component: <Profile />,
  },
  {
    path: Path.faq,
    exact: true,
    auth: false,
    component: <FAQList />,
  },
  {
    path: Path.content_pages,
    exact: true,
    auth: false,
    component: <ContentPage />,
  },
  {
    path: Path.language,
    exact: true,
    auth: false,
    component: <LanguageList />,
  },
  {
    path: Path.badges,
    exact: true,
    auth: false,
    component: <BadgeList />,
  },
  {
    path: Path.avatars,
    exact: true,
    auth: false,
    component: <AvatarList />,
  },
  {
    path: Path.avatarCategories,
    exact: true,
    auth: false,
    component: <AvatarCategory />,
  },
  {
    path: Path.avatarCharacterstics,
    exact: true,
    auth: false,
    component: <AvatarCharacterstic />,
  },
  {
    path: Path.singleUser,
    exact: true,
    auth: false,
    component: <ProfileSingle />,
  },
  {
    path: Path.albums,
    exact: true,
    auth: false,
    component: <AlbumPage />,
  },
  {
    path: Path.UserRelativeProfile,
    exact: true,
    auth: false,
    component: <UserRelativeProfile />,
  },
  {
    path: Path.QrCodeCreate,
    exact: true,
    auth: false,
    component: <QrCodeCreate />,
  },
  {
    path: Path.QrPriceCreate,
    exact: true,
    auth: false,
    component: <QrCreatePriceList />,
  },
  {
    path: Path.marchant,
    exact: true,
    auth: false,
    component: <Marchant />,
  },
  {
    path: Path.promos,
    exact: true,
    auth: false,
    component: <Promos />,
  },
  {
    path: Path.promos_View,
    exact: true,
    auth: false,
    component: <PromosDetails />,
  },
  {
    path: Path.Paymentlist,
    exact: true,
    auth: false,
    component: <Payment />,
  },
  {
    path: Path.PaymentDetailPage,
    exact: true,
    auth: false,
    component: <PaymentDetailsPage />,
  },
  {
    path: Path.merchant_view,
    exact: true,
    auth: false,
    component: <MarchantView />,
  },
  {
    path: Path.qrhistory,
    exact: true,
    auth: false,
    component: <QrLinkedHistory />,
  },
];
export default routes;
