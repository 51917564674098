import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { SUPPORT } from "common/viewContent";
import * as Path from "routes/Path/index";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import { Link, useNavigate } from "react-router-dom";
import { getSupportListData, SupportEdit } from "store/actions";
import EditSupport from "components/Modals/EditSupport";
const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);
  const { USER, loading, totalCount } = useSelector((state) => state.Doctor);
  const [doctor, setDoctor] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [datasets, setDatasets] = useState();
  const [editModalShow, setEditModalShow] = useState();
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
  });
  const [formData, setFormData] = useState({
    status: "",
  });

  const fetchData = async (filter) => {
    const payload = {
      ...filter,
      page: filter?.pageNo,
      record_count: filter?.limit,
    };
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setDoctor(res);
        }
      };
      dispatch(
        getSupportListData({
          data: payload,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  useEffect(() => {
    fetchData(filter);
    setcolumns(
      Columns({ handleEdithandler, handleViewUser,currentPage: filter?.pageNo, pageSize })
    );
  }, [filter]);
  const handleEdithandler = (info) => {
    setFormData({ ...formData, ...info });
    setDatasets(info);
    setEditModalShow(true);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleEditSub = (info) => {
    console.log(info,"info==========,>")
    try {
      let data = {
        id: info.id,
        status: info.status,
      };
      const callback = (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setEditModalShow(false);
          fetchData(filter);
          toast.success("User updated successfully!");
        }
      };
      console.log(data, "data");
      dispatch(
        SupportEdit({
          data: data,
          callback,
        })
      );
      console.log(data, "data");
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const handleViewUser = (row) => {
    navigate(`${row.id}`, { state: { row } });
  };

  return (
    <>
      {!doctor && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      {editModalShow && (
        <EditSupport
          show={editModalShow}
          handleClose={() => {
            setEditModalShow(false);
          }}
          title={"Edit Support"}
          content={""}
          handleConfirm={handleEditSub}
          loading={loading}
          initialValues={formData}
        />
      )}
      <Helmet title={SUPPORT.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{SUPPORT.CURRENT_MODULE}</h1>

          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{SUPPORT.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{SUPPORT.TOP_CONTENT}</h2>
                </div>
              </div>
            </div>
          </div>
          {doctor?.users?.length && (
            <Table
              data={doctor?.users}
              columns={columns}
              setFilter={setFilter}
              handleTableChange={handlePageChange}
              totalCount={doctor?.total_record_count}
              filter={filter}
            />
          )}
          {USER?.users?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Patient Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
