import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  COUNTRY: [],
 
};

const slice = createSlice({
  name: "COUNTRY",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.CountryCode.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.CountryCode.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      COUNTRY: payload,
      
    }));
    builder.addCase(Act.CountryCode.rejected, (state, action) => ({
      ...state,
      loading: false,
      COUNTRY: [],
    
    }));
   
  },
});

export default slice;
