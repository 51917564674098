import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { USER } from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";
import { getUsers, updateUser, AddUser } from "store/actions";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import AddUserModal from "components/Modals/AddUser";
import Select from "react-select";

const InputFieldsForSearch = [
  {
    name: "select",
    type: "select",

    placeholder: "Select status",
    options: [
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" },
      { value: "pending", label: "Pending" },
      { value: "deleted", label: "Deleted" },
    ],
  },
];

const useStatusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "pending", label: "Pending" },
  { value: "deleted", label: "Deleted" },
];

const initialValuesForFilter = {
  search: "",
  select: "",
};

const List = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users, loading, totalCount } = useSelector((state) => state.Users);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [userProfileSearch, setUserProfileSearch] = useState("");
  const [status, setStatus] = useState("active");
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
    order: -1,
    orderBy: "dateCreatedUtc",
    search: "",
    status: "active",
    userProfileSearch: "",
  });

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [addModalShow, setAddModalShow] = useState(false);
  useEffect(() => {
    getApiData(filter);
  }, [filter]);
  const onClickFilter = (e) => {
    e?.preventDefault();
    setFilter({
      ...filter,
      pageNo: 1,
      limit: pageSize,
      search: search,
      userProfileSearch: userProfileSearch,
      status: status,
    });
  };

  async function getApiData(query) {
    try {
      await dispatch(getUsers({ data: query }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleUserStatus = (id, status) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          getApiData(filter);
          toast.success("Status Changed");
        }
      };

      dispatch(
        updateUser({
          data: { _id: id, userId: id, status: status },
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const handleViewUser = (id) => {
    navigate(`/admin/users/${id}`);
  };
  const handleSubmit_ = (values) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setAddModalShow(false);
          getApiData(filter);
          toast.success(res.message);
        }
      };
      dispatch(
        AddUser({
          data: values,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const clearFilter = () => {
    setFilter((pre) => ({
      ...pre,
      pageNo: 1,
      search: "",
      status: "active",
      userProfileSearch: "",
    }));
    setSearch("");
    setStatus("active");
    setUserProfileSearch("");
  };
  console.log(users, "users");
  return (
    <>
      <AddUserModal
        show={addModalShow}
        handleClose={() => setAddModalShow(false)}
        title={"Add User"}
        content={""}
        handleConfirm={handleSubmit_}
        loading={loading}
        initialValues={formData}
      />
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={USER.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{USER.CURRENT_MODULE}</h1>

          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{USER.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{USER.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add User
                  </button>
                </div>
              </div>
              <div className="col-12">
                <form onSubmit={onClickFilter}>
                  <div className="card-header">
                    <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                      <input
                        className="form-control"
                        name="search"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        placeholder="Search using name, email..."
                      />
                    </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                      <input
                        className="form-control"
                        name="userProfileSearch"
                        onChange={(e) => setUserProfileSearch(e.target.value)}
                        value={userProfileSearch}
                        placeholder="Search using profile name"
                      />
                    </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                      <Select
                        className="form-group"
                        name={"status"}
                        options={useStatusOptions}
                        onChange={(selectedOption) => {
                          setStatus(selectedOption.value);
                        }}
                        placeholder={"Select status"}
                        value={useStatusOptions.find(
                          (option) => option.value === status
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-12 my-2 gap-4 ">
                    <div className="d-flex gap-4">
                      <button type="submit" className="btn btn-primary btn-lg">
                        Search
                      </button>
                      <button
                        onClick={() => clearFilter()}
                        type="button"
                        className="btn btn-primary btn-lg"
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Table
            data={users}
            columns={Columns({
              handleUserStatus,
              handleViewUser,
            })}
            setFilter={setFilter}
            totalCount={totalCount}
            handleTableChange={handlePageChange}
            filter={filter}
            toggleConfirmModal={handleUserStatus}
          />
          {!loading && users?.user?.length === 0 && (
            <p style={{ textAlign: "center" }}>No User Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
