import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";

import { get, post, put, del } from "helpers/api_helper";

export const PAYMENT_LIST = createAsyncThunk(
  "PAYMENT_LIST",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.PAYMENT_LISTING, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

//payment  details api calling
export const PaymentsDetails = createAsyncThunk(
  "PaymentsDetails",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await get(URL.PAYMENTDETAILS + data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

// delete qr price
// export const deleteQrPrice = createAsyncThunk(
//   "deleteQrPrice",
//   async ({ data, callback }, thunkAPI) => {
//     try {
//       const response = await del(URL.QRPRICE_LIST_DELETE + data);
//       console.log(data,"data----------->")
//       callback && callback(null, response.data);
//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );
export const PaymentStatus = createAsyncThunk(
  "PaymentStatus",
  async ({ data, callback }, Thunk) => {
    try {
      let payload = JSON.parse(JSON.stringify(data));
      delete payload.id;
      let response = await put(URL.PAYMENTDETAILS + data?.id, payload);

      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
