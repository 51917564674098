import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post, del, put } from "helpers/api_helper";

export const getFeedbackList = createAsyncThunk(
  "getFeedbackList",
  async ({ data, callback }, Thunk) => {
    try {
      // // Create a copy of data to avoid directly mutating the original object
      // let payload = { ...data };
      // // Delete pageNo and limit from the payload
      // delete payload.pageNo;
      // delete payload.limit;
      // console.log(payload, "Updated data without pageNo and limit");
      // Make the API call with the modified payload
      let response = await post(`${URL.GET_FEEDBACK_LIST}`, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);



export const getFeedbackSingle = createAsyncThunk(
  "getFeedbackSingle",
  async ({ id, callback }, Thunk) => {
    try {
      // console.log("id==>",id)
      let response = await post(`${URL.GET_FEEDBACK_SINGLE}${id}`, {});
      // console.log("response callback==>",response)
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log("error-->",error)
      return Thunk.rejectWithValue(error);
    }
  }
);
 