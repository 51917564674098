import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import FormikForm from "components/shared/FormikForm";
// import Validation from "views/doctor/Validation";
import InputFields from "views/doctor/inputFields";
import { Country, State, City } from 'country-state-city';

export default function EditDoctor({
  show,
  handleClose,
  handleConfirm,
  title,
  loading,
  initialValues,
}) {

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header className="py-3 text-dark" closeButton>
        <Modal.Title className="fw-bold text-dark">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      

        <FormikForm
          initialValues={initialValues}
          //   validationSchema={Validation}
          onSubmit={handleConfirm}
          loading={loading}
          inputFields={InputFields}
          col={6}
          buttonText={"Submit"}
        />
      </Modal.Body>
    </Modal>
  );
}

// import React, { useState, useEffect } from "react";
// import { Button, Col, Form, Modal, Row } from "react-bootstrap";
// import FormikForm from "components/shared/FormikForm";
// import InputFields from "views/doctor/inputFields";
// import { Country, State, City } from 'country-state-city';
// import { DoctorEdit } from "store/actions";
// import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";

// export default function EditDoctor({
//   show,
//   handleClose,
//   handleConfirm,
//   title,
//   loading,
//   initialValues,
//   editData
// }) {
//   const dispatch = useDispatch();
//   const countries = Country.getAllCountries();
//   const [selectedCountry, setSelectedCountry] = useState(editData?.country );
//   const [selectedState, setSelectedState] = useState(editData?.state);
//   const [stateOptions, setStateOptions] = useState([]);
//   const [cityOptions, setCityOptions] = useState([]);

//   useEffect(() => {
//     if (selectedCountry) {
//       const states = State.getStatesOfCountry(selectedCountry);
//       setStateOptions(states);
//     }
//   }, [selectedCountry]);

//   useEffect(() => {
//     if (selectedCountry && selectedState) {
//       const cities = City.getCitiesOfState(selectedCountry, selectedState);
//       setCityOptions(cities);
//     }
//   }, [selectedCountry, selectedState]);

//   const handleCountryChange = (event) => {
//     const countryCode = event.target.value;
//     setSelectedCountry(countryCode);
//     const states = State.getStatesOfCountry(countryCode);
//     setStateOptions(states);
//     setSelectedState("");
//     setCityOptions([]);
//   };

//   const handleStateChange = (event) => {
//     const stateCode = event.target.value;
//     setSelectedState(stateCode);
//     const cities = City.getCitiesOfState(selectedCountry, stateCode);
//     setCityOptions(cities);
//   };

//   const handleInputChange = (event) => {
//          const { name, value } = event.target;
//         setFormData((prevData) => ({ ...prevData, [name]: value }));
//       };

//   const handleEditSubmit = () => {
//     try {
//       const data = {
//         user_id: editData.id,
//         first_name: editData.first_name,
//         middle_name: editData.middle_name,
//         last_name: editData.last_name,
//         country: selectedCountry,
//         state: selectedState,
//         phone_no: editData.phone_no,
//         country_code: editData?.country_code,
//         city: editData.city,
//       };

//       const callback = (err) => {
//         if (err) {
//           console.error(err);
//         } else {
//           handleClose();
//           toast.success("User updated successfully!");
//         }
//       };

//       dispatch(DoctorEdit({ data, callback }));
//     } catch (error) {
//       console.error("Error updating doctor:", error);
//     }
//   };

//   return (
//     <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered size="lg">
//       <Modal.Header className="py-3 text-dark" closeButton>
//         <Modal.Title className="fw-bold text-dark">{title}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Form>
//           <Row>
//             <Col>
//               <Form.Group className="mb-3">
//                 <Form.Label>First Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="first_name"
//                   value={editData?.first_name || ""}
//                   placeholder="Enter name..."
//                   onChange={(e) => handleInputChange({ ...editData, first_name: e.target.value })}
//                 />
//               </Form.Group>
//             </Col>

//             <Col md="6" lg="6">
//               <Form.Group className="mb-3">
//                 <Form.Label>Midle Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="middle_name"
//                   value={editData?.first_name || ""}
//                   placeholder="Enter name..."
//                   onChange={(e) => handleInputChange({ ...editData, middle_name: e.target.value })}
//                 />
//               </Form.Group>
//             </Col>
//             <Col md="6" lg="6">
//               <Form.Group className="mb-3">
//                 <Form.Label>Midle Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="last_name"
//                   value={editData?.first_name || ""}
//                   placeholder="Enter name..."
//                   onChange={(e) => handleInputChange({ ...editData, last_name: e.target.value })}
//                 />
//               </Form.Group>
//             </Col>
//             <Col md="6" lg="6">
//               <Form.Group className="mb-3">
//                 <Form.Label>Country</Form.Label>
//                 <Form.Select
//                   name="country"
//                   value={selectedCountry}
//                   onChange={handleCountryChange}
//                 >
//                   <option value="">Select Country</option>
//                   {countries.map((country) => (
//                     <option key={country.isoCode} value={country.isoCode}>
//                       {country.name}
//                     </option>
//                   ))}
//                 </Form.Select>
//               </Form.Group>
//             </Col>

//             <Col md="6" lg="6">
//               <Form.Group className="mb-3">
//                 <Form.Label>State</Form.Label>
//                 <Form.Select
//                   name="state"
//                   value={selectedState}
//                   onChange={handleStateChange}
//                   disabled={!stateOptions.length}
//                 >
//                   <option value="">Select State</option>
//                   {stateOptions.map((state) => (
//                     <option key={state.isoCode} value={state.isoCode}>
//                       {state.name}
//                     </option>
//                   ))}
//                 </Form.Select>
//               </Form.Group>
//             </Col>

//             <Col md="6" lg="6">
//               <Form.Group className="mb-3">
//                 <Form.Label>City</Form.Label>
//                 <Form.Select
//                   name="city"
//                   value={editData?.city || ""}
//                   onChange={(e) => handleInputChange({ ...editData, city: e.target.value })}
//                   disabled={!cityOptions.length}
//                 >
//                   <option value="">Select City</option>
//                   {cityOptions.map((city) => (
//                     <option key={city.name} value={city.name}>
//                       {city.name}
//                     </option>
//                   ))}
//                 </Form.Select>
//               </Form.Group>
//             </Col>

//             <Col md="6" lg="6">
//               <Form.Group className="mb-3">
//                 <Form.Label>Phone Number</Form.Label>
//                 <Form.Control
//                   type="number"
//                   name="phone_no"
//                   value={editData?.phone_no || ""}
//                   placeholder="Enter phone number..."
//                   onChange={(e) => handleInputChange({ ...editData, phone_no: e.target.value })}
//                 />
//               </Form.Group>
//             </Col>
//           </Row>
//           <Button onClick={handleEditSubmit}>Submit</Button>
//         </Form>
//       </Modal.Body>
//     </Modal>
//   );
// }
