import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post, del, put } from "helpers/api_helper";

export const getAdminListData = createAsyncThunk(
  "getAdminListData",
  async ({ data, callback }, Thunk) => {
    console.log(data,"data======fhjdfjhdfdjfh=====")
    try {
      let response = await post(`${URL.ADMIN_HISTORY}`, data);
      console.log("response callback==>",response)
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error,"error message ")
      callback && callback(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

