import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  qrprice: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "qrprice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    
    // add qr price
    builder.addCase(Act.CreateQRPrice.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.CreateQRPrice.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      qrprice: payload,
    }));
    builder.addCase(Act.CreateQRPrice.rejected, (state, action) => ({
      ...state,
      loading: false,
      qrprice: null,
    }));
    // qr price list
    builder.addCase(Act.CreateQRPriceList.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.CreateQRPriceList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      qrprice: payload,
    }));
    builder.addCase(Act.CreateQRPriceList.rejected, (state, action) => ({
      ...state,
      loading: false,
      qrprice: null,
    }));
  // delete Qr price
   builder.addCase(Act.deleteQrPrice.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    builder .addCase(Act.deleteQrPrice.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;

    })
    builder.addCase(Act.deleteQrPrice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || action.error;
    });
    
    // qr price edit
    builder.addCase(Act.EditQrPrice.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.EditQrPrice.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      qrprice: payload,
    }));
    builder.addCase(Act.EditQrPrice.rejected, (state, action) => ({
      ...state,
      loading: false,
      qrprice: null,
    }));

     },
});

export default slice;
