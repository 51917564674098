
import * as Yup from 'yup';

const Validation = Yup.object({
    name: Yup.string().required("Name is Required"),
    // description: Yup.string().required("Discription Name is Required"),
    price: Yup.string().required("Price Number Required"),
    type: Yup.string().required("type Required"),
    // discount: Yup.string().required("Discount Required")
});

export default Validation;
