// inputFields.js
const InputFields = [
    {
      name: "first_name",
      type: "text",
      label: "Name",
    },
    {
      name: "middle_name",
      type: "text",
      label: "Middle Name",
    },
  
  
    {
      name: "last_name",
      type: "text",
      label: "Last Name",
    },
    {
      name: "email",
      type: "email",
      label: "Email",
    },
    {
      name: "country",
      type: "select",
      label: "Country Name",
    },
    {
      name: "state",
      type: "text",
      label: "State ",
    },
    {
      name: "city",
      type: "text",
      label: "City Name",
    },
    {
      name: "country_code",
      type: "dynamic_select",
      label: "Country Code",
      placeholder: "",
      options: []
    },
    
    {
      name: "phone_no",
      type: "number",
      label: "PhoneNumber",
    },
  
  ];
  
  export default InputFields;
  
  