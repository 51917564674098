import { Placeholder } from "react-bootstrap";

const InputFields = [
    {
      name: "prompt",
      type: "text",
      label: "Prompt",
      placeholder:"Enter Prompt.."
    },
    {
      name: "description",
      type: "text",
      label: "Description",
      placeholder: "Enter Description",
    
    },
  ];
  
  export default InputFields;
  
  