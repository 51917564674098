const inputFields = [
  {
    name: "username",
    type: "text",
    label: "User Name",
    placeholder: "User Name...",
  },

  {
    name: "email",
    type: "text",
    label: "Email*",
    placeholder: "Email...",
    disabled: true,
  },

  {
    name: "password",
    type: "password",
    label: "Password*",
    placeholder: "Password...",
    disabled: true,
  },
];

export default inputFields;
