import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardBody, Row, Col, Button } from "reactstrap";

function Table(props) {
  const [sort,setSort] = useState({
    order : props?.filter?.order === 1 ? "asc" : "desc" ,
    dataField : props?.filter?.orderBy
  })
  // console.log("propsabc", props)
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: props?.filter?.limit || 10,
    totalSize: props.totalCount,
    custom: true,
    page: props?.filter?.pageNo || 1,
  })
console.log(pageOptions,"pageOptions=========<")
  useEffect(() => {
    setPageOptions((pre)=>({
   ...pre,
      totalSize: props.totalCount,
    
    }))
  }, [props.totalCount])

  
  

  // console.log("pageOptions", pageOptions);
  const handleTableChange = (type, data) => {
    let payFilter ={};
    console.log(data,"data")
    if (type === "pagination") {
      payFilter = {  pageNo: data.page };
      setPageOptions((prev)=>({
        ...prev,
        page: data.page,
      }));
    }
    if (type === "sort") {
      payFilter = { 
        order: data.sortOrder === "asc" ? 1 : -1 ,
        orderBy:data.sortField
      };
      console.log("data on sort",payFilter)
      setSort({
        order : data.sortOrder,
        dataField : data.sortField
      })
    }
    props.setFilter((prevState) => ({
      ...prevState,
      ...payFilter
    }));
  };


  if (props.columns.length <= 0) return <React.Fragment></React.Fragment>;
  const navigate = useNavigate();

  
  // console.log("pageOptions", pageOptions)
  return (
    <Card>
      
      <CardBody>
      {props.title && <h6 className="mb-4">{props.title}</h6>}
        <PaginationProvider pagination={paginationFactory(pageOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="users"
              data={props.data}
              columns={props.columns}
              toggleConfirmModal
              bootstrap4
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  {props.children}

                  <div className="clearfix">
                    {props.newButtonLink && (
                      <Button
                        type="button"
                        color="success"
                        className="float-end"
                        onClick={() => navigate(props.newButtonLink)}
                      >
                        <i className="mdi mdi-plus mr-1" />
                        {i18n.t(props.newButtonText)}
                      </Button>
                    )}
                  </div>

                  <div className="table-responsive">
                    <BootstrapTable
                      responsive
                      remote
                      sort={sort}
                      
                      // defaultSorted={[{ 
                      //    dataField: props?.filter?.orderBy, 
                      //    order: props?.filter?.order === 1 ? "asc" : "desc"  
                      // }]}
                      bordered={false}
                      striped={false}
                      classes="table table-centered table-nowrap"
                      headerWrapperClasses={"thead-light"}
                      onTableChange={handleTableChange}
                      selectRow={props.selectRow}
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                      {...props.tableProps}
                    />
                  </div>

                  <Row className="align-items-md-center mt-30">
                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                      <PaginationListStandalone {...paginationProps} />
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
}

Table.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  newButtonLink: PropTypes.string,
  newButtonText: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  handleTableChange: PropTypes.func,
  tableProps: PropTypes.object,
  filter: PropTypes.object,
  totalCount: PropTypes.number,
};

Table.defaultProps = {
  title: '',
  total: 0,
  data: [],
  columns: [],
  tableProps: {},
  newButtonText: "Add New",
  totalCount: 0
};

export default Table;
