import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { ADMIN } from "common/viewContent";
import * as Path from "routes/Path/index";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import { Link } from "react-router-dom";
import { getAdminListData } from "store/admin/actions";
import { Form } from "react-bootstrap";
import { ClimbingBoxLoader } from "react-spinners";
const List = () => {
  const dispatch = useDispatch();
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);
  const { USER, loading, totalCount } = useSelector((state) => state.Doctor);
  const [doctor, setDoctor] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [historyType, setHistoryType] = useState(4);

  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
    historyType: historyType
  });
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const fetchData = async (filter) => {
    const payload = {
      ...filter,
      page: filter?.pageNo,
      record_count: filter?.limit,
      historyType: filter?.historyType
    };
    try {
      const callback = (err, res) => {
        if (err) {
          // toast.error(err.toString());
          setDoctor({
            total_record_count: 0,
            users: []
          })
        } else {
          console.log("res==>", res)
          setDoctor(res);
        }
      };
      dispatch(
        getAdminListData({
          data: payload,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  useEffect(() => {
    fetchData(filter);
    setcolumns(Columns({ currentPage: filter?.pageNo, pageSize, authUser }));
  }, [filter]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const setOnchange = async()=>{
    try {
      setFilter((prev)=>({
        // pageNo: prev?.pageNo,
        // limit: prev?.limit,
        pageNo: currentPage,
        limit: pageSize,
        historyType: historyType
      }))
    } catch (error) {
      console.log("error-->",error)
    }
  }
useEffect(()=>{
  setOnchange();
},[historyType])
 
  return (
    <>
      {!doctor && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={ADMIN.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{ADMIN.CURRENT_MODULE}</h1>

          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{ADMIN.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{ADMIN.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-12">
                <form>
                  <div className="card-header">
                    <div className="col-lg-4 col-sm-12 col-md-12 my-2 px-0">
                      {/* <input
                        className="form-control"
                        name="search_text"
                        onChange={(e) => setSearch_text(e.target.value)}
                        value={search_text}
                        placeholder="Search using name, email..."
                      /> */}
                      <label htmlFor="" className="form-label m-0 text-muted fw-normal">Select Login Type</label>
                      <Form.Select onChange={(e)=> { setHistoryType(e.target.value);  }} defaultValue={historyType} className="form-control" aria-label="Default select example">
                        <option value="1">Patient</option>
                        <option value="2">Doctor</option>
                        <option value="3">Chemist</option>
                        <option value="4">Admin</option>
                      </Form.Select>
                    </div>
                  </div>
                  {/* <div className="col-12 my-2 gap-4 ">
                    <div className="d-flex gap-4">
                      <button type="submit" className="btn btn-primary btn-lg">
                        Search
                      </button>
                      <button
                        onClick={() => clearFilter()}
                        type="button"
                        className="btn btn-primary btn-lg"
                      >
                        Clear Search
                      </button>
                    </div>
                  </div> */}
                </form>
              </div>
              {/* <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add Feedna
                  </button>
                </div>
              </div> */}
              {/* {filter.languageId && (
                <div className="container">
                  <div className="col-6">
                    <FormikForm
                      initialValues={{ select: filter.languageId }}
                      // validationSchema={Validation}
                      onSubmit={handleFilterForm}
                      loading={loading}
                      inputFields={InputFieldsForSearch}
                      // submitting={null}
                      buttonText={"Search"}
                      // is_block={true}
                      col={6}
                      // is_passwordHide={false}
                    />
                  </div>
                </div>
              )} */}
            </div>
          </div>
          {doctor?.users?.length > 0 && (
            <Table
              data={doctor?.users}
              columns={columns}
              setFilter={setFilter}
              handleTableChange={handlePageChange}
              s
              totalCount={doctor?.total_record_count}
              filter={filter}
            />
          )}
          {doctor?.users?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Data Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
