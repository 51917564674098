import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettings, updateSettings } from "store/actions";
import { Card, CardBody, CardTitle } from "reactstrap";
import { toast } from "react-toastify";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { CONFIGURATION } from "common/viewContent";
import * as Path from "routes/Path/index";
import { Form, Formik } from "formik";

const GeneralSetting = () => {
  const dispatch = useDispatch();
  const { settings, loading } = useSelector((state) => state.Settings);
 const formikRef = useRef();
  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  
  useEffect(() => {
    if (settings && Object.keys(settings).length !== 0) {
        formikRef.current.setValues({
        _id: settings?._id,
        contactEmailAddress: settings.contactEmailAddress || "",
        contactPhoneNumber: settings.contactPhoneNumber || "",
        paymentMode: settings.paymentMode || "",
        s3BaseUrl: settings.s3BaseUrl || "",
        youtube_tutorial_link: settings.youtube_tutorial_link || "",
        socialmedia_link : {
            facebook : settings?.socialmedia_link?.facebook || "",
            tiktok : settings?.socialmedia_link?.tiktok || "",
            instagram : settings?.socialmedia_link?.instagram || "",
        },
        payment_methods: {
            stripe: {
              secret_key: settings?.payment_methods?.stripe?.secret_key ||  "",
              public_key: settings?.payment_methods?.stripe?.public_key ||  "",
              is_active : settings?.payment_methods?.stripe?.is_active || false
            },
            paypal: {
              client_id: settings?.payment_methods?.paypal?.client_id ||  "",
              client_secret: settings?.payment_methods?.paypal?.client_secret ||  "",
              is_active : settings?.payment_methods?.paypal?.is_active || false
            },
            russian_bank: {
                username: settings?.payment_methods?.russian_bank?.username ||  "",
                password: settings?.payment_methods?.russian_bank?.password ||  "", 
                is_active : settings?.payment_methods?.russian_bank?.is_active || false
            },
            poland_bank: {
              secret_key: settings?.payment_methods?.poland_bank?.secret_key ||  "",
              public_key: settings?.payment_methods?.poland_bank?.public_key ||  "",
                is_active : settings?.payment_methods?.poland_bank?.is_active || false
            },
            georgian_bank : {
                username: settings?.payment_methods?.georgian_bank?.username ||  "",
                password: settings?.payment_methods?.georgian_bank?.password ||  "", 
                is_active : settings?.payment_methods?.georgian_bank?.is_active || false
            }
          }, 
      });
    }
  }, [settings]);

  const handleSubmit = (values) => {
    console.log(values, " values");
    const payload = {
      _id: settings?._id,
      ...values
    };

    dispatch(
      updateSettings({
        data: payload,

        callback: (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Settings updated successfully");
          }
        },
      })
    );
  };

  return (
    <React.Fragment>
      <Helmet title={CONFIGURATION.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{CONFIGURATION.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">Configuration</div>
          </div>
        </div>
      </section>
      <Card>
        <CardBody className="spinner-content">
          <CardTitle>
            <b>General Settings</b>
          </CardTitle>
          <Formik
          innerRef={formikRef}
        initialValues={{
            _id: settings?._id,
            contactEmailAddress: "",
            contactPhoneNumber:  "",
            paymentMode:  "",
            s3BaseUrl: "",
            youtube_tutorial_link: "",
            socialmedia_link : {
                facebook : "",
                tiktok : "",
                instagram : "",
            },
            payment_methods: {
                stripe: {
                  secret_key:  "",
                  public_key:  "",
                  is_active : false
                },
                paypal: {
                  client_id:  "",
                  client_secret:  "",
                  is_active : false
                },
                russian_bank: {
                    username:   "",
                    password:   "",
                    is_active : false 
                },
                poland_bank: {
                    secret_key:  "",
                    public_key:  "", 
                    is_active : false
                },
                georgian_bank : {
                    username:   "",
                    password:  "", 
                    is_active : false 
                }
              }
        }}
        // enableReinitialize
        validationSchema={null}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form className="col-12" >
            {/* {console.log("errors",errors)} */}
            {console.log(values, "values")}
            <div className="row justify-content-between col-12">
            <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Payment Mode</label>
                      <div className="switch-l square-switch">
                                  <input
                                    type="checkbox"
                                    id="payment-mode"
                                    switch="none"
                                    name="App_mode"
                                    value={values.paymentMode}
                                    checked={(values.paymentMode === "live")}
                                    onChange={(e) => {
                                      setFieldValue("paymentMode",values.paymentMode === "live" ? "sandbox" : "live")
                                    }}
                                  />
                                  <label
                                    htmlFor="payment-mode"
                                    data-on-label={"live"}
                                    data-off-label={"sandbox"}
                                  />
                                </div>
                    </>
                  
                  {errors["paymentMode"] && touched["paymentMode"] && (
                    <p className="invalid-feedback">{errors["paymentMode"]}</p>
                  )}
                </div>
                <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Contact Phone Number</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["contactPhoneNumber"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"contactPhoneNumber"}
                        onChange={handleChange}
                        placeholder={"Contact phone number..."}
                        onBlur={handleBlur}
                        value={values["contactPhoneNumber"]}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["contactPhoneNumber"] && touched["contactPhoneNumber"] && (
                    <p className="invalid-feedback">{errors["contactPhoneNumber"]}</p>
                  )}
                </div>
                <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Contact Email Address</label>
                      <input
                        type={"email"}
                        className={`form-control ${
                          errors["contactEmailAddress"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"contactEmailAddress"}
                        onChange={handleChange}
                        placeholder={"Contact Email Address..."}
                        onBlur={handleBlur}
                        value={values["contactEmailAddress"]}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["contactEmailAddress"] && touched["contactEmailAddress"] && (
                    <p className="invalid-feedback">{errors["contactEmailAddress"]}</p>
                  )}
                </div>
                <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>s3BaseUrl</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["s3BaseUrl"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"s3BaseUrl"}
                        onChange={handleChange}
                        placeholder={"s3BaseUrl..."}
                        onBlur={handleBlur}
                        value={values["s3BaseUrl"]}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["s3BaseUrl"] && touched["s3BaseUrl"] && (
                    <p className="invalid-feedback">{errors["s3BaseUrl"]}</p>
                  )}
                </div>
                <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Youtube tutorial link</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["youtube_tutorial_link"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"youtube_tutorial_link"}
                        onChange={handleChange}
                        placeholder={""}
                        onBlur={handleBlur}
                        value={values["youtube_tutorial_link"]}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["youtube_tutorial_link"] && touched["youtube_tutorial_link"] && (
                    <p className="invalid-feedback">{errors["youtube_tutorial_link"]}</p>
                  )}
                </div>
                <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Facebook</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["socialmedia_link.facebook"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"facebook"}
                        onChange={(e)=>{
                            setFieldValue("socialmedia_link.facebook",e.target.value) 
                         }}
                        placeholder={""}
                        onBlur={handleBlur}
                        value={values.socialmedia_link.facebook}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["socialmedia_link.facebook"] && touched["socialmedia_link.facebook"] && (
                    <p className="invalid-feedback">{errors["socialmedia_link.facebook"]}</p>
                  )}
                </div>
                <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Instagram</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["socialmedia_link.instagram"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"instagram"}
                        onChange={(e)=>{
                            setFieldValue("socialmedia_link.instagram",e.target.value) 
                         }}
                        placeholder={""}
                        onBlur={handleBlur}
                        value={values.socialmedia_link.instagram}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["socialmedia_link.instagram"] && touched["socialmedia_link.instagram"] && (
                    <p className="invalid-feedback">{errors["socialmedia_link.instagram"]}</p>
                  )}
                </div>
                <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Tiktok</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["socialmedia_link.tiktok"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"tiktok"}
                        onChange={(e)=>{
                           setFieldValue("socialmedia_link.tiktok",e.target.value)
                        }}
                        placeholder={""}
                        onBlur={handleBlur}
                        value={values.socialmedia_link.tiktok}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["socialmedia_link.tiktok"] && touched["socialmedia_link.tiktok"] && (
                    <p className="invalid-feedback">{errors["socialmedia_link.tiktok"]}</p>
                  )}
                </div>
                <hr/>
               <div className="row">
                 <h5>Payment methods</h5>
               </div>
               <div className="row mt-2">
               <b>Stripe</b>
               <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Status</label>
                      <div className="switch-l square-switch">
                                  <input
                                    type="checkbox"
                                    id="stripe-status"
                                    switch="none"
                                    name="App_mode"
                                    value={values?.payment_methods?.stripe?.is_active}
                                    checked={(values?.payment_methods?.stripe?.is_active)}
                                    onChange={(e) => {
console.log("valuesvalues",values)
                                      setFieldValue("payment_methods.stripe.is_active",e.target.checked)
                                    }}
                                  />
                                  <label
                                    htmlFor="stripe-status"
                                    data-on-label={"Active"}
                                    data-off-label={"InActive"}
                                  />
                                </div>
                    </>
                  
                  {errors["payment_methods.stripe.is_active"] && touched["payment_methods.stripe.is_active"] && (
                    <p className="invalid-feedback">{errors["payment_methods.stripe.is_active"]}</p>
                  )}
                </div>
               <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Secret Key</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["payment_methods.stripe.secret_key"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"payment_methods.stripe.secret_key"}
                        onChange={(e)=>{
                           setFieldValue("payment_methods.stripe.secret_key",e.target.value)
                        }}
                        placeholder={""}
                        onBlur={handleBlur}
                        value={values.payment_methods.stripe.secret_key}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["payment_methods.stripe.secret_key"] && touched["payment_methods.stripe.secret_key"] && 
                (
                    <p className="invalid-feedback">{errors["payment_methods.stripe.secret_key"]}</p>
                  )}
                </div>
                <div
                  className={`form-group col-md-4`}
              
                >
                    <>
                      <label>Public Key</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["payment_methods.stripe.public_key"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"payment_methods.stripe.public_key"}
                        onChange={(e)=>{
                           setFieldValue("payment_methods.stripe.public_key",e.target.value)
                        }}
                        placeholder={""}
                        onBlur={handleBlur}
                        value={values.payment_methods.stripe.public_key}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["payment_methods.stripe.public_key"] && touched["payment_methods.stripe.public_key"] && 
                (
                    <p className="invalid-feedback">{errors["payment_methods.stripe.public_key"]}</p>
                  )}
                </div>
               </div>
               <div className="row">
               <b>Paypal</b>
               <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Status</label>
                      <div className="switch-l square-switch">
                                  <input
                                    type="checkbox"
                                    id="paypal-status"
                                    switch="none"
                                    name="App_mode"
                                    value={values?.payment_methods?.paypal?.is_active}
                                    checked={(values?.payment_methods?.paypal?.is_active)}
                                    onChange={(e) => {
                                      setFieldValue("payment_methods.paypal.is_active",e.target.checked)
                                    }}
                                  />
                                  <label
                                    htmlFor="paypal-status"
                                    data-on-label={"Active"}
                                    data-off-label={"InActive"}
                                  />
                                </div>
                    </>
                  
                  {errors["payment_methods.paypal.is_active"] && touched["payment_methods.paypal.is_active"] && (
                    <p className="invalid-feedback">{errors["payment_methods.paypal.is_active"]}</p>
                  )}
                </div>
               <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Client Id</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["payment_methods.paypal.client_id"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"payment_methods.paypal.client_id"}
                        onChange={(e)=>{
                           setFieldValue("payment_methods.paypal.client_id",e.target.value)
                        }}
                        placeholder={""}
                        onBlur={handleBlur}
                        value={values.payment_methods.paypal.client_id}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["payment_methods.paypal.client_id"] && touched["payment_methods.paypal.client_id"] && 
                (
                    <p className="invalid-feedback">{errors["payment_methods.paypal.client_id"]}</p>
                  )}
                </div>
                <div
                  className={`form-group col-md-4`}
              
                >
                    <>
                      <label>Client Secret</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["payment_methods.paypal.client_secret"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"payment_methods.paypal.client_secret"}
                        onChange={(e)=>{
                           setFieldValue("payment_methods.paypal.client_secret",e.target.value)
                        }}
                        placeholder={""}
                        onBlur={handleBlur}
                        value={values.payment_methods.paypal.client_secret}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["payment_methods.paypal.client_secret"] && touched["payment_methods.paypal.client_secret"] && 
                (
                    <p className="invalid-feedback">{errors["payment_methods.paypal.client_secret"]}</p>
                  )}
                </div>
               </div>
               <div className="row">
                <b>Georgain bank</b>
                <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Status</label>
                      <div className="switch-l square-switch">
                                  <input
                                    type="checkbox"
                                    id="georgian-bank"
                                    switch="none"
                                    name="App_mode"
                                    value={values?.payment_methods?.georgian_bank?.is_active}
                                    checked={values?.payment_methods?.georgian_bank?.is_active}
                                    onChange={(e) => {
                                    setFieldValue("payment_methods.georgian_bank.is_active",e.target.checked)
                                    }}
                                  />
                                  <label
                                    htmlFor="georgian-bank"
                                    data-on-label={"Active"}
                                    data-off-label={"InActive"}
                                  />
                                </div>
                    </>
                  
                  {errors["payment_methods.georgian_bank.is_active"] && touched["payment_methods.georgian_bank.is_active"] && (
                    <p className="invalid-feedback">{errors["payment_methods.georgian_bank.is_active"]}</p>
                  )}
                </div>
               <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>username</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["payment_methods.georgian_bank.username"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"payment_methods.georgian_bank.username"}
                        onChange={(e)=>{
                           setFieldValue("payment_methods.georgian_bank.username",e.target.value)
                        }}
                        placeholder={""}
                        onBlur={handleBlur}
                        value={values.payment_methods.georgian_bank.username}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["payment_methods.georgian_bank.username"] && touched["payment_methods.georgian_bank.username"] && 
                (
                    <p className="invalid-feedback">{errors["payment_methods.georgian_bank.username"]}</p>
                  )}
                </div>
                <div
                  className={`form-group col-md-4`}
              
                >
                    <>
                      <label>password</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["payment_methods.georgian_bank.password"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"payment_methods.georgian_bank.password"}
                        onChange={(e)=>{
                           setFieldValue("payment_methods.georgian_bank.password",e.target.value)
                        }}
                        placeholder={""}
                        onBlur={handleBlur}
                        value={values.payment_methods.georgian_bank.password}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["payment_methods.georgian_bank.password"] && touched["payment_methods.georgian_bank.password"] && 
                (
                    <p className="invalid-feedback">{errors["payment_methods.georgian_bank.password"]}</p>
                  )}
                </div>
               </div>
               <div className="row">
                <b>Poland bank</b>
                <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Status</label>
                      <div className="switch-l square-switch">
                                  <input
                                    type="checkbox"
                                    id="poland_bank"
                                    switch="none"
                                    name="App_mode"
                                    value={values?.payment_methods?.poland_bank?.is_active}
                                    checked={values?.payment_methods?.poland_bank?.is_active}
                                    onChange={(e) => {
                                    setFieldValue("payment_methods.poland_bank.is_active",e.target.checked)
                                    }}
                                  />
                                  <label
                                    htmlFor="poland_bank"
                                    data-on-label={"Active"}
                                    data-off-label={"InActive"}
                                  />
                                </div>
                    </>
                  
                  {errors["payment_methods.poland_bank.is_active"] && touched["payment_methods.poland_bank.is_active"] && (
                    <p className="invalid-feedback">{errors["payment_methods.poland_bank.is_active"]}</p>
                  )}
                </div>
                <div
                  className={`form-group col-md-4 `}
              
                >
                    <>
                      <label>Secret Key</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["payment_methods.poland_bank.secret_key"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"payment_methods.poland_bank.secret_key"}
                        onChange={(e)=>{
                           setFieldValue("payment_methods.poland_bank.secret_key",e.target.value)
                        }}
                        placeholder={""}
                        onBlur={handleBlur}
                        value={values.payment_methods.poland_bank.secret_key}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["payment_methods.poland_bank.secret_key"] && touched["payment_methods.poland_bank.secret_key"] && 
                (
                    <p className="invalid-feedback">{errors["payment_methods.poland_bank.secret_key"]}</p>
                  )}
                </div>
                <div
                  className={`form-group col-md-4`}
              
                >
                    <>
                      <label>Public Key</label>
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors["payment_methods.poland_bank.public_key"] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={"payment_methods.poland_bank.public_key"}
                        onChange={(e)=>{
                           setFieldValue("payment_methods.poland_bank.public_key",e.target.value)
                        }}
                        placeholder={""}
                        onBlur={handleBlur}
                        value={values.payment_methods.poland_bank.public_key}
                        // disabled={}
                        autoComplete="off"
                      />
                    </>
                  
                  {errors["payment_methods.poland_bank.public_key"] && touched["payment_methods.poland_bank.public_key"] && 
                (
                    <p className="invalid-feedback">{errors["payment_methods.poland_bank.public_key"]}</p>
                  )}
                </div>
               </div>
                <div
                  className={`form-group`}
              
                >
                    <button type="submit" className="btn btn-primary btn-lg">
                        Submit
                    </button> 
                </div>
            </div>
          </Form>
        )}
      </Formik>
      
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default GeneralSetting;
