import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { USER } from "common/viewContent";
import * as Path from "routes/Path/index";
import { toast } from "react-toastify";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import FadeLoader from "react-spinners/FadeLoader";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { calculateAge, formatYearsAndMonths } from "helpers/common";
import FormikForm from "components/shared/FormikForm";
import Columns from "./Columns";
import Table from "components/Table";
import BackButtom from "components/BackButtom";
import { getFeedbackSingle } from "store/feedback/actions";
const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search using first name, last name, email...",
  },
  {
    name: "select",
    type: "select",
    label: "",
    placeholder: "Select status",
    options: [
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" },
      { value: "pending", label: "Pending" },
      { value: "deleted", label: "Deleted" },
    ],
  },
];
const initialValuesForFilter = {
  search: "",
  select: "",
};

const FeedbackDetail = () => {
  const { id } = useParams();
  const [step, setStep] = useState("1");
  const [useAiva, setUseAiva] = useState("1");
  const [recommendAiva, setRecommendAiva] = useState("1");
  const [comments, setComments] = useState("");
  const [rating, setRating] = useState("1");
  const [showModal, setShowModal] = useState(false);
  const [PR3, setPR3] = useState("1");
  const [PR4, setPR4] = useState("1");
  const [F1, setF1] = useState("1");
  const [F2, setF2] = useState("1");
  const [F3, setF3] = useState("1");
  const [F4, setF4] = useState("1");
  const [F5, setF5] = useState("1");
  const [F6, setF6] = useState("1");
  const [F7, setF7] = useState("1");
  const [F8, setF8] = useState("1");
  const [F9, setF9] = useState("1");
  const [F10, setF10] = useState("1");
  const [F11, setF11] = useState("1");
  const [F12, setF12] = useState("1");
  const [F13, setF13] = useState("1");
  const [F14, setF14] = useState("1");
  const [F15, setF15] = useState("1");
  const [MR2, setMR2] = useState("1");
  const [MR3, setMR3] = useState("1");
  const [MR4, setMR4] = useState("1");
  const [R1, setR1] = useState("1");
  const [R2, setR2] = useState("1");
  const [R3, setR3] = useState("1");
  const [R4, setR4] = useState("1");
  const [feedbackData, setFeedbackData] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    relation: "",
    birthDate: "",
    surname: "",
  });
  const [editUserRelative, setEditUserRelative] = useState(null);

  const handleClose = () => setShow(false);

  const { loading: profileLoading, user } = useSelector((state) => state.Users);
  const { loading, relative, totalCount } = useSelector(
    (state) => state.Relative
  );
  const [relative1, setRelative1] = useState();
  const [columns, setColumns] = useState();

  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    if (id) {
      dispatch(
        getFeedbackSingle({
          id,
          callback: (err, res) => {
            if (err) {
              toast.error(err);
            } else {
              setFeedbackData(res.data);
              ForSet(res?.data);
              // toast.success(res.message);
            }
          },
        })
      );
    }
  }, [id]);

  function ForSet(data) {
    setUseAiva(data?.use_aiva || data?.use_aiva.toString() || "1");
    setRecommendAiva(
      data?.recommend_aiva || data?.recommend_aiva.toString() || "1"
    );
    setComments(data?.comments || "");
    setRating((data?.overall_rating && data?.overall_rating.toString()) || "1");
    setPR3(
      (data?.would_you_sugget_sick_friends_relatives &&
        data?.would_you_sugget_sick_friends_relatives.toString()) ||
        "1"
    );
    setPR4(
      (data?.would_you_like_particular_pastors &&
        data?.would_you_like_particular_pastors.toString()) ||
        "1"
    );
    setF1(
      (data?.ai_vital_sign_app && data?.ai_vital_sign_app.toString()) || "1"
    );
    setF2((data?.ai_doc_1 && data?.ai_doc_1.toString()) || "1");
    setF3((data?.ai_doc_2 && data?.ai_doc_2.toString()) || "1");
    setF4((data?.ai_doc_3 && data?.ai_doc_3.toString()) || "1");
    setF5(
      (data?.gpt_automatic_star_pilot_realtime &&
        data?.gpt_automatic_star_pilot_realtime.toString()) ||
        "1"
    );
    setF6(
      (data?.one_stop_health_wellness_care &&
        data?.one_stop_health_wellness_care.toString()) ||
        "1"
    );
    setF7(
      (data?.online_offline_services_b2b2c_marketplace &&
        data?.online_offline_services_b2b2c_marketplace.toString()) ||
        "1"
    );
    setF8(
      (data?.human_doctor_conference_room &&
        data?.human_doctor_conference_room.toString()) ||
        "1"
    );
    setF9(
      (data?.b2b2c_marketplace && data?.b2b2c_marketplace.toString()) || "1"
    );
    setF10(
      (data?.starpilot_safety_qc_check &&
        data?.starpilot_safety_qc_check.toString()) ||
        "1"
    );
    setF11(
      (data?.error_proof_prescription &&
        data?.error_proof_prescription.toString()) ||
        "1"
    );
    setF12(
      (data?.qc_safety_checked_prescription &&
        data?.qc_safety_checked_prescription.toString()) ||
        "1"
    );
    setF13(
      (data?.qc_on_doctor_prevent_mistake &&
        data?.qc_on_doctor_prevent_mistake.toString()) ||
        "1"
    );
    setF14(
      (data?.qc_on_patient_report_diagnosis &&
        data?.qc_on_patient_report_diagnosis.toString()) ||
        "1"
    );
    setF15(
      (data?.real_time_guidance_of_doctor &&
        data?.real_time_guidance_of_doctor.toString()) ||
        "1"
    );
    setMR2(
      (data?.would_you_participate_ongoing_discussions &&
        data?.would_you_participate_ongoing_discussions.toString()) ||
        "1"
    );
    setMR3(
      (data?.would_you_submit_questions_to_doctors &&
        data?.would_you_submit_questions_to_doctors.toString()) ||
        "1"
    );
    setMR4(
      (data?.would_you_attend_webinars &&
        data?.would_you_attend_webinars.toString()) ||
        "1"
    );
    setR1(
      (data?.total_healthcare_costs_to_pay_monthly &&
        data?.total_healthcare_costs_to_pay_monthly.toString()) ||
        "1"
    );
    setR2((data?.would_you_pay && data?.would_you_pay.toString()) || "1");
    setR3(
      (data?.would_you_use_platform_to_pay &&
        data?.would_you_use_platform_to_pay.toString()) ||
        "1"
    );
    setR4(
      (data?.would_you_recomment_this_to_pay_monthly &&
        data?.would_you_recomment_this_to_pay_monthly.toString()) ||
        "1"
    );
  }
  const handleViewUser = (id) => {
    navigate(`/admin/users/profile/${id}`);
  };
  return (
    <>
      {!feedbackData && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={USER?.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <BackButtom />
          <h1 className="">Feedback Detail</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.users}>Users</Link>
            </div>
            <div className="breadcrumb-item">UserDetail </div>
          </div>
        </div>
        <Container>
          <Row className="">
            <Col className="" md={6}>
              {user && (
                <Card>
                  <Card.Body>
                    <Card.Text>
                      <p>
                        <strong>Name:</strong> {user.data.name}
                      </p>
                      <p>
                        <strong>Email:</strong> {user.data.email}
                      </p>
                      <p>
                        <strong>Phone:</strong> {user.data.mobileNumber}
                      </p>
                      <p>
                        <strong>DateCreated:</strong> {formattedDate}
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      {user && (
        <section className="section  ">
          <div className="container">
            <h4 className="">Relative Profiles</h4>
            <div className="card">
              <div className="row justify-content-between">
                <div className="col-12">
                  <div className="card-header">
                    <FormikForm
                      initialValues={initialValuesForFilter}
                      onSubmit={handleFilterForm}
                      loading={loading}
                      inputFields={InputFieldsForSearch}
                      buttonText={"Search"}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <Table
              data={relative1}
              columns={columns}
              setFilter={setFilter}
              // total={pageSize}
              totalCount={totalCount}
              handleTableChange={handlePageChange}
              filter={filter}
              toggleConfirmModal={handleUserStatus}
            /> */}
            <table className="table">
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Relative Name</th>
                  <th>Surname</th>
                  <th>Age(Years)</th>
                  <th>Relationship</th>
                  <th>Status</th>
                  <th>QrLinkStatus</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {relative1?.length > 0 &&
                  relative1.map((relative, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{relative.name}</td>
                      <td>{relative.surname}</td>
                      <td>
                        {relative.age &&
                          formatYearsAndMonths(calculateAge(relative.age))}
                      </td>
                      <td>{relative.relation}</td>
                      <td>
                        <Badge
                          className={`text-capitalize font-size-13 badge-soft-${relative.status}`}
                          color={relative.badgeClass}
                        >
                          {relative.status}
                        </Badge>
                      </td>
                      <td>
                        <Badge
                          className={`text-capitalize font-size-13 badge-soft-${relative.status}`}
                          color={relative.badgeClass}
                        >
                          {relative?.linkedQrCodeDetails?.status}
                        </Badge>
                      </td>
                      <td className="d-flex">
                        {relative.status && relative.status == "active" ? (
                          <button
                            className="fas fa-toggle-on"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              backgroundColor: "#fff",
                              border: "none",
                            }}
                            title="Active"
                            onClick={() =>
                              handleUserStatus(relative._id, "inactive")
                            }
                          ></button>
                        ) : (
                          <button
                            className="fas fa-toggle-off"
                            style={{
                              fontSize: "20px",
                              color: "red",
                              backgroundColor: "#fff",
                              border: "none",
                            }}
                            title="Inactive"
                            onClick={() =>
                              handleUserStatus(relative._id, "active")
                            }
                          ></button>
                        )}
                        <button
                          className=""
                          title="View"
                          onClick={() => handleViewUser(relative?._id)}
                          style={{
                            fontSize: "20px",
                            color: "green",
                            backgroundColor: "#fff",
                            border: "none",
                          }}
                        >
                          <i className="fa fa-eye"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {relative1?.length === 0 && (
              <p style={{ textAlign: "center" }}>No Relative Found</p>
            )}
          </div>
        </section>
      )}
      <section className="feedbackDetail section py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="bg-white rounded shadow p-4">
                <div className="form-fields ">
                  <div className="row justify-content-center pt-3 pb-5">
                    <div className="col-lg-10 col-xl-10 col-12">
                      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                      <ul className="list-unstyled mx-auto ps-0 mb-0 d-flex align-items-center pb-5 justify-content-center">
                        <li onClick={() => setStep("1")}
                          className={`p-1 text-center rounded cursor-pointer`}
                          style={{ fontSize: 12 }}
                        >
                          Step 1
                          <div
                            className="mt-2 rounded"
                            style={{
                              background:
                                parseInt(step) >= 1 ? "#216fc8" : "#ddd",
                              height: 5,
                              minWidth: 80,
                            }}
                          ></div>
                        </li>
                        <li onClick={() => setStep("2")}
                          className={`p-1 text-center rounded cursor-pointer`}
                          style={{ fontSize: 12 }}
                        >
                          Step 2
                          <div
                            className="mt-2 rounded"
                            style={{
                              background:
                                parseInt(step) >= 2 ? "#216fc8" : "#ddd",
                              height: 5,
                              minWidth: 80,
                            }}
                          ></div>
                        </li>
                        <li
                        onClick={() => setStep("3")}
                          className={`p-1 text-center rounded cursor-pointer`}
                          style={{ fontSize: 12 }}
                        >
                          Step 3
                          <div
                            className="mt-2 rounded"
                            style={{
                              background: step >= "3" ? "#216fc8" : "#ddd",
                              height: 5,
                              minWidth: 80,
                            }}
                          ></div>
                        </li>
                        <li onClick={() => setStep("4")}
                          className={`p-1 text-center rounded cursor-pointer`}
                          style={{ fontSize: 12 }}
                        >
                          Step 4
                          <div
                            className="mt-2 rounded"
                            style={{
                              background: step >= "4" ? "#216fc8" : "#ddd",
                              height: 5,
                              minWidth: 80,
                            }}
                          ></div>
                        </li>
                        <li onClick={() => setStep("5")}
                          className={`p-1 text-center rounded cursor-pointer`}
                          style={{ fontSize: 12 }}
                        >
                          Step 5
                          <div
                            className="mt-2 rounded"
                            style={{
                              background: step >= "5" ? "#216fc8" : "#ddd",
                              height: 5,
                              minWidth: 80,
                            }}
                          ></div>
                        </li>
                        <li onClick={() => setStep("6")}
                          className={`p-1 text-center rounded cursor-pointer`}
                          style={{ fontSize: 12 }}
                        >
                          Step 6
                          <div
                            className="mt-2 rounded"
                            style={{
                              background: step >= "6" ? "#216fc8" : "#ddd",
                              height: 5,
                              minWidth: 80,
                            }}
                          ></div>
                        </li>
                        <li onClick={() => setStep("7")}
                          className={`p-1 text-center rounded cursor-pointer`}
                          style={{ fontSize: 12 }}
                        >
                          Step 7
                          <div
                            className="mt-2 rounded"
                            style={{
                              background:
                                parseInt(step) >= 7 ? "#216fc8" : "#ddd",
                              height: 5,
                              minWidth: 80,
                            }}
                          ></div>
                        </li>
                        <li onClick={() => setStep("8")}
                          className={`p-1 text-center rounded cursor-pointer`}
                          style={{ fontSize: 12 }}
                        >
                          Step 8
                          <div
                            className="mt-2 rounded"
                            style={{
                              background: step >= "8" ? "#216fc8" : "#ddd",
                              height: 5,
                              minWidth: 80,
                            }}
                          ></div>
                        </li>
                      </ul>
                      <div className="feedback-sec profle_input_fields">
                        {step == "1" ? (
                          <>
                            <p className="font_22 font_600 mb-0">
                              Monte Sereno Health Feedback
                            </p>
                            <hr className="mb-2" />
                            <Row className="px-4 justify-content-center ">
                              <Col className=" col-md-12 my-1 h-100 w-100 card rounded-lg">
                                <div className="d-flex ">
                                  <div className="col-md-6 my-2 h-100 w-100">
                                    <div className="d-flex justify-content-between  border-bottom">
                                      <p className="fw-bolder"> Name</p>
                                      <span className="py-1">
                                        {feedbackData?.first_name || ""}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between gap-2  border-bottom">
                                      <p className="fw-bolder">Middle Name</p>
                                      <span className="py-1">
                                        {feedbackData?.middle_name || ""}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between  border-bottom">
                                      <p className="fw-bolder">Last Name </p>
                                      <span className="py-1">
                                        {feedbackData?.last_name || ""}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between  border-bottom">
                                      <p className="fw-bolder">Email</p>
                                      <span className="py-1">
                                        {feedbackData?.email1 || ""}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between  border-bottom">
                                      <p className="fw-bolder">Email 2</p>
                                      <span className="py-1">
                                        {feedbackData?.email2 || ""}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between  border-bottom">
                                      <p className="fw-bolder">Phone Number</p>
                                      <span className="py-1">
                                        {feedbackData?.phone_no1 || ""}
                                      </span>
                                    </div>

                                    <div className="d-flex justify-content-between  border-bottom">
                                      <p className="fw-bolder">
                                        What Else Would You Like Aiva To Do?
                                      </p>
                                      <span className="py-1">
                                        {feedbackData?.like_aiva_to_do || ""}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="rating"
                                        className="form-label mb-0"
                                      >
                                        Rating review
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= rating
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="fw-bolder mb-0">
                                        Would You Live Aiva
                                      </p>
                                      <div className="d-flex gap-4">
                                        <span className="d-flex align-items-center gap-1">
                                          <label
                                            className="form-check-label"
                                            htmlFor="useAivaYes"
                                          >
                                            {useAiva ? "Yes" : "No"}
                                          </label>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 my-2 h-100 w-100 ">
                                    <div className="d-flex justify-content-between  border-bottom">
                                      <p className="fw-bolder">
                                        Phone Number 2
                                      </p>
                                      <span className="py-1">
                                        {feedbackData?.phone_no2 || ""}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between gap-2  border-bottom">
                                      <p className="fw-bolder">Country</p>
                                      <span className="py-1">
                                        {feedbackData?.country || ""}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between  border-bottom">
                                      <p className="fw-bolder">State </p>
                                      <span className="py-1">
                                        {feedbackData?.state || ""}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between  border-bottom">
                                      <p className="fw-bolder">City</p>
                                      <span className="py-1">
                                        {feedbackData?.city || ""}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between  border-bottom">
                                      <p className="fw-bolder">
                                        Would You Use Aiva?
                                      </p>
                                      <span className="py-1">
                                        {feedbackData?.ease_of_use || ""}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between  border-bottom">
                                      <p className="fw-bolder">Date</p>
                                      <span className="py-1">
                                        {(feedbackData &&
                                          feedbackData?.date &&
                                          moment(feedbackData.date).format(
                                            "YYYY-MM-DD"
                                          )) ||
                                          ""}
                                      </span>
                                    </div>

                                    <div className="d-flex justify-content-between  border-bottom">
                                      <p className="fw-bolder flex flex-wrap">
                                        Comment & Suggestion
                                      </p>
                                      <span className="py-1">
                                        {comments || ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div className="row justify-content-center">
                              <div className=" col-md-6">
                                <button
                                  onClick={() => setStep("2")}
                                  className="submit-btn mt-3 w-100"
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                            {/* <div className="row">
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="firstName" className="form-label">First Name</label>
                                <input readOnly value="" type="text" className="form-control" placeholder="Please enter first name" />

                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="middleInitial" className="form-label">Middle Initial</label>
                                <input readOnly value="" type="text" className="form-control" placeholder="Please enter middle initial" />

                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="lastName" className="form-label">Last Name</label>
                                <input readOnly value={feedbackData?.last_name || ""} type="text" className="form-control" placeholder="Please enter last name" />

                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="email1" className="form-label">Email1</label>
                                <input readOnly value="" type="email" className="form-control" placeholder="Please enter email" />

                              </div>
                            </div>

                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="email2" className="form-label">Email2</label>
                                <input readOnly value="" type="email" className="form-control" placeholder="Please enter email" />

                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="phoneNumber1" className="form-label">Phone Number1</label>
                                <input readOnly value="" type="text" className="form-control" placeholder="Please enter phone number" />

                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="phoneNumber2" className="form-label">Phone Number2</label>
                                <input readOnly value="" type="text" className="form-control" placeholder="Please enter phone number" />

                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="country" className="form-label">Country</label>
                                <input readOnly value="" type="text" className="form-control" placeholder="Please enter country name" />

                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="state" className="form-label">State</label>
                                <input readOnly value="" type="text" className="form-control" placeholder="Please enter state name" />

                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="city" className="form-label">City</label>
                                <input readOnly value="" type="text" className="form-control" placeholder="Please enter city name" />

                              </div>
                            </div>

                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="easeOfUse" className="form-label">Ease Of Use</label>
                                <input readOnly value="" type="text" className="form-control" placeholder="Please enter ease of use" />

                              </div>
                            </div>

                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="feedback" className="form-label">What Else Would You Like Aiva To Do?</label>
                                <input readOnly value="" type="text" className="form-control" placeholder="Please enter what else you want AIVA to do?" />

                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label className="form-label">Would You Use Aiva?</label>
                                <div className="d-flex gap-3 align-items-center">
                                  <div className="form-check form-custom d-flex align-items-center gap-1">
                                    <input
                                      className="form-check-input radio"
                                      type="radio"
                                      name="useAiva"
                                      id="useAivaYes"
                                      checked={useAiva === "1"}
                                      value="1"
                                    />
                                    <label className="form-check-label" htmlFor="useAivaYes">
                                      Yes
                                    </label>
                                  </div>

                                  <div className="form-check form-custom d-flex align-items-center gap-1">
                                    <input
                                      className="form-check-input radio"
                                      type="radio"
                                      name="useAiva"
                                      id="useAivaNo"
                                      checked={useAiva === "0"}
                                      value="0"
                                    />
                                    <label className="form-check-label" htmlFor="useAivaNo">
                                      No
                                    </label>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label className="form-label">Would You Recommend Aiva to Friends and Family?</label>
                                <div className="d-flex gap-3 align-items-center">
                                  <div className="form-check form-custom d-flex align-items-center gap-1">
                                    <input
                                      className="form-check-input radio"
                                      type="radio"
                                      name="recommendAiva"
                                      id="recommendAivaYes"
                                      value="1"
                                      checked={recommendAiva === "1"}
                                    />
                                    <label className="form-check-label" htmlFor="recommendAivaYes">
                                      Yes
                                    </label>
                                  </div>

                                  <div className="form-check form-custom d-flex align-items-center gap-1">
                                    <input
                                      className="form-check-input radio"
                                      type="radio"
                                      name="recommendAiva"
                                      id="recommendAivaNo"
                                      checked={recommendAiva === "0"}
                                    />
                                    <label className="form-check-label" htmlFor="recommendAivaNo">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="date" className="form-label">Date</label>
                                <input readOnly value=""
                                  type="date" className="form-control" />

                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                              <div className="mb-3">
                                <label htmlFor="rating" className="form-label">Rating review</label>
                                <div className="star-rating mb-3">
                                  <input readOnly checked={rating === "5"} id="star-5" type="radio" name="rating" value="5"

                                  />
                                  <label htmlFor="star-5" title="5 stars">
                                    <i className="active fa fa-star" aria-hidden="true"></i>
                                  </label>
                                  <input readOnly checked={rating === "4"} id="star-4" type="radio" name="rating" value="4"
                                  />
                                  <label htmlFor="star-4" title="4 stars">
                                    <i className="active fa fa-star" aria-hidden="true"></i>
                                  </label>
                                  <input readOnly checked={rating === "3"} id="star-3" type="radio" name="rating" value="3"
                                  />
                                  <label htmlFor="star-3" title="3 stars">
                                    <i className="active fa fa-star" aria-hidden="true"></i>
                                  </label>
                                  <input readOnly checked={rating === "2"} id="star-2" type="radio" name="rating" value="2" />
                                  <label htmlFor="star-2" title="2 stars">
                                    <i className="active fa fa-star" aria-hidden="true"></i>
                                  </label>
                                  <input readOnly checked={rating === "1"} id="star-1" type="radio" name="rating" value="1" />
                                  <label htmlFor="star-1" title="1 stars">
                                    <i className="active fa fa-star" aria-hidden="true"></i>
                                  </label>

                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-xl-12 col-12">
                              <div className="mb-3">
                                <label htmlFor="comments" className="form-label">Comments & Suggestions</label>
                                <textarea
                                  className="form-control"
                                  placeholder="Leave a comment here"
                                  id="comments"
                                  value={comments}
                                  readOnly
                                  name="comments"
                                  style={{ height: "100px" }}

                                />
                              </div>
                            </div>
                          </div>  */}
                          </>
                        ) : step == "2" ? (
                          <>
                            <div>
                              <p className="font_22  font_600 text-blue  mb-0">
                                WHAT FEATURES WOULD MAKE YOU USE DIFFERENT PARTS
                                OF THE PLATFORM?
                              </p>
                              <hr className="mb-2" />
                              <div className="row">
                                <div className="col-lg-6 col-xl-6 col-12">
                                  <div className="mb-3">
                                    {/* <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      1. AI Vital Signs App
                                    </label> */}
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        1. AI Vital Signs App
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F1
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        AI Doctor 1
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F2
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        AI Doctor 2
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F3
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        AI Doctor 3
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F4
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        3. GPT starpilot automatic real-time
                                        guidance of worldwide doctors
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F5
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        4. ONE-STOP HEALTH AND WELLNESS CARE{" "}
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F6
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        5. Seamless Online and Offline Services
                                        B2B2C Marketplace - Every service in One
                                        place - One-Stop{" "}
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F7
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        6. Human Doctor in Conference Room{" "}
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F8
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        7. B2B2C Marketplace{" "}
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F9
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        8. StarPilot QC and Safety services{" "}
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F10
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        9. Error-proof Prescription{" "}
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F11
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        10. QC/Safety Checked Prescription{" "}
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F12
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        11. QC on doctor to prevent mistakes
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F13
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        12. QC on patient to help symptom
                                        reporting and diagnosis
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F14
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        13. Real-time guidance of doctor by
                                        checking patient record and web info
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                          <label
                                            key={star}
                                            htmlFor={`star-${star}`}
                                            title={`${star} stars`}
                                            className="me-1"
                                          >
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                              style={{
                                                color:
                                                  star <= F15
                                                    ? "#FFD700"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </label>
                                        ))}
                                        <input
                                          type="hidden"
                                          name="rating"
                                          id="rating"
                                          value={rating}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row justify-content-center">
                              <div className=" col-md-6">
                                <button
                                  onClick={() => setStep("3")}
                                  type="submit"
                                  className="submit-btn mt-3 w-100"
                                >
                                  Next
                                </button>
                              </div>
                              <div className=" col-md-6">
                                <button
                                  type="button"
                                  className="submit-btn mt-3 w-100"
                                  onClick={() => setStep("1")}
                                >
                                  Previous
                                </button>
                              </div>
                            </div>
                            {/* </form> */}
                          </>
                        ) : step == "3" ? (
                          <>
                            <div className="mt-3">
                              <p className="font_22  font_600 text-blue  mb-0">
                                AI VITAL SIGNS AND AI DOCTOR APPS
                              </p>
                              <hr className="mb-4" />
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        Vital Signs App
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[
                                          "Once/week",
                                          "Twice/week",
                                          "Once/day",
                                          "Twice/day",
                                          "Over three times/day",
                                        ].map((text, index) => (
                                          <span key={index} className="py-1">
                                            {feedbackData?.vital_signs_app ===
                                            index + 1
                                              ? text
                                              : null}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        AI doctors
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[
                                          "Once/week",
                                          "Twice/week",
                                          "Once/day",
                                          "Twice/day",
                                          "Over three times/day",
                                        ].map((text, index) => (
                                          <span key={index} className="py-1">
                                            {feedbackData?.ai_doctors_apps ===
                                            index + 1
                                              ? text
                                              : null}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        Are the AI Vitals and doctor apps easy
                                        to use?
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[
                                          "Not Very Easy",
                                          "Not Easy",
                                          "Somewhat Easy",
                                          "Easy",
                                          "Very Easy",
                                        ].map((text, index) => (
                                          <span key={index} className="py-1">
                                            {feedbackData?.ai_vital_and_doctor_apps_are_easy ===
                                            index + 1
                                              ? text
                                              : null}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        Would you use these apps to prevent you
                                        from getting sick?
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[
                                          "Not Very Easy",
                                          "Not Easy",
                                          "Somewhat Easy",
                                          "Easy",
                                          "Very Easy",
                                        ].map((text, index) => (
                                          <span key={index} className="py-1">
                                            {feedbackData?.would_you_use_to_prevent_you_from_sick ===
                                            index + 1
                                              ? text
                                              : null}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-md-6">
                                <button
                                  onClick={() => setStep("4")}
                                  className="submit-btn mt-3 w-100"
                                >
                                  Next
                                </button>
                              </div>
                              <div className=" col-md-6">
                                <button
                                  type="button"
                                  className="submit-btn mt-3 w-100"
                                  onClick={() => setStep("2")}
                                >
                                  Previous
                                </button>
                              </div>
                            </div>
                            {/* </form> */}
                          </>
                        ) : step == "4" ? (
                          <>
                            <div className="mt-3">
                              <p className="font_22 font_600 text-blue mb-0">
                                HOW LONG WOULD YOU STAY ON THE PLATFORM
                                SECTIONS?
                              </p>
                              <hr className="mb-4" />
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        On the platform
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[
                                          "5 - 10 minutes",
                                          "10 - 20 minutes",
                                          "20 - 30 minutes",
                                          "30 - 40 minutes",
                                          "Over 50 minutes",
                                        ].map((text, index) => (
                                          <span key={index} className="py-1">
                                            {feedbackData?.on_the_platform ===
                                            index + 1
                                              ? text
                                              : null}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        On the vital signs and AI doctors
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[
                                          "5 - 10 minutes",
                                          "10 - 20 minutes",
                                          "20 - 30 minutes",
                                          "30 - 40 minutes",
                                          "Over 50 minutes",
                                        ].map((text, index) => (
                                          <span key={index} className="py-1">
                                            {feedbackData?.on_the_vital_signs_ai_doctors ===
                                            index + 1
                                              ? text
                                              : null}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        On the Human Doctor and Pharmacy
                                        sections and Videoconference centers
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[
                                          "5 - 10 minutes",
                                          "10 - 20 minutes",
                                          "20 - 30 minutes",
                                          "30 - 40 minutes",
                                          "Over 50 minutes",
                                        ].map((text, index) => (
                                          <span key={index} className="py-1">
                                            {feedbackData?.on_the_human_doctors_pharmacy_video_conference ===
                                            index + 1
                                              ? text
                                              : null}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        On the Medical Community Support Rooms
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[
                                          "5 - 10 minutes",
                                          "10 - 20 minutes",
                                          "20 - 30 minutes",
                                          "30 - 40 minutes",
                                          "Over 50 minutes",
                                        ].map((text, index) => (
                                          <span key={index} className="py-1">
                                            {feedbackData?.on_the_medical_community_support_room ===
                                            index + 1
                                              ? text
                                              : null}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        On the Prayer Support Rooms
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[
                                          "5 - 10 minutes",
                                          "10 - 20 minutes",
                                          "20 - 30 minutes",
                                          "30 - 40 minutes",
                                          "Over 50 minutes",
                                        ].map((text, index) => (
                                          <span key={index} className="py-1">
                                            {feedbackData?.on_the_prayer_support_room ===
                                            index + 1
                                              ? text
                                              : null}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row justify-content-center">
                              <div className=" col-md-6">
                                <button
                                  onClick={() => setStep("5")}
                                  className="submit-btn mt-3 w-100"
                                >
                                  Next
                                </button>
                              </div>
                              <div className=" col-md-6">
                                <button
                                  type="button"
                                  className="submit-btn mt-3 w-100"
                                  onClick={() => setStep("3")}
                                >
                                  Previous
                                </button>
                              </div>
                            </div>
                          </>
                        ) : step == "5" ? (
                          <>
                            <div className="mt-3">
                              <p className="font_22  font_600 text-blue  mb-0">
                                PRAYER SUPPORT ROOMS
                              </p>
                              <hr className="mb-4" />
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        1. How regularly would you visit?{" "}
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[
                                          "Once/week",
                                          "Twice/week",
                                          "Once/day",
                                          "Twice/day",
                                          "Over three times/day",
                                        ].map((text, index) => (
                                          <span key={index} className="py-1">
                                            {feedbackData?.how_regulary_would_you_visit ===
                                            index + 1
                                              ? text
                                              : null}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        2. Would you participate in the 24/7
                                        ongoing prayers?{" "}
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[
                                          "Once/week",
                                          "Twice/week",
                                          "Once/day",
                                          "Twice/day",
                                          "Over three times/day",
                                        ].map((text, index) => (
                                          <span key={index} className="py-1">
                                            {feedbackData?.would_you_participate_ongoing_prayers ===
                                            index + 1
                                              ? text
                                              : null}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        3. Would you submit names of sick
                                        friends and relatives so that the
                                        community can pray for them (all privacy
                                        protected)?
                                      </label>
                                      <div className="d-flex gap-3 align-items-center">
                                        <div className="d-flex gap-4">
                                          <span className="d-flex align-items-center gap-1">
                                            <label
                                              className="form-check-label"
                                              htmlFor="useAivaYes"
                                            >
                                              {PR3 === "1"
                                                ? "Yes"
                                                : PR3 === "0"
                                                ? "No"
                                                : ""}
                                            </label>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        4. Would you like particular Pastors to
                                        participate in prayer sessions?
                                      </label>
                                      <div className="d-flex gap-3 align-items-center">
                                        <div className="d-flex gap-4">
                                          <span className="d-flex align-items-center gap-1">
                                            <label
                                              className="form-check-label"
                                              htmlFor="useAivaYes"
                                            >
                                              {PR4 === "1"
                                                ? "Yes"
                                                : PR4 === "0"
                                                ? "No"
                                                : ""}
                                            </label>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-lg-6 col-xl-6 col-12">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      4. Would you like particular Pastors to
                                      participate in prayer sessions?
                                    </label>
                                    <div className="d-flex gap-3 align-items-center">
                                      <div className="form-check form-custom d-flex align-items-center gap-1">
                                        <input
                                          className="form-check-input radio"
                                          type="radio"
                                          name="flexRadioDefault1"
                                          id="flexRadioDefault5"
                                          value="1"
                                          checked={PR4 == "1"}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexRadioDefault5"
                                        >
                                          Yes
                                        </label>
                                      </div>
                                      <div className="form-check form-custom d-flex align-items-center gap-1">
                                        <input
                                          className="form-check-input radio"
                                          type="radio"
                                          name="flexRadioDefault1"
                                          id="flexRadioDefault5"
                                          value="0"
                                          checked={PR4 == "0"}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexRadioDefault5"
                                        >
                                          No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="mb-3">
                                  <div className="d-flex justify-content-between align-items-center border-bottom">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      5. What other features would you like to
                                      see in the prayer room?
                                    </label>
                                    <div className="d-flex gap-3 align-items-center">
                                      <div className="d-flex gap-4">
                                        <span className="d-flex align-items-center gap-1">
                                          <label
                                            className="form-check-label"
                                            htmlFor="useAivaYes"
                                          >
                                            {
                                              feedbackData?.what_other_features_would_you_like_to_see
                                            }
                                          </label>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-md-6">
                                <button
                                  onClick={() => setStep("6")}
                                  className="submit-btn mt-3 w-100"
                                >
                                  Next
                                </button>
                              </div>
                              <div className=" col-md-6">
                                <button
                                  type="button"
                                  className="submit-btn mt-3 w-100"
                                  onClick={() => setStep("4")}
                                >
                                  Previous
                                </button>
                              </div>
                            </div>
                          </>
                        ) : step == "6" ? (
                          <>
                            <div className="mt-3">
                              <p className="font_22  font_600 text-blue  mb-0">
                                MEDICAL COMMUNITY SUPPORT ROOMS
                              </p>
                              <hr className="mb-4" />

                              <div className="row">
                                <div className="col-lg-12">
                               
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        1. How regularly would you visit?{" "}
                                      </label>
                                      <span className="py-1 d-flex">
                                        {[
                                          "Once/week",
                                          "Twice/week",
                                          "Once/day",
                                          "Twice/day",
                                          "Over three times/day",
                                        ].map((text, index) => (
                                          <span key={index} className="py-1">
                                            {feedbackData?.how_regulary_would_you_visit ===
                                            index + 1
                                              ? text
                                              : null}
                                          </span>
                                        ))}
                                      </span>
                                    
                                  </div>
                                </div>
                                <div className="col-lg-12 ">
                                  <div className="mb-3 mx-0">
                                  
                                        <div className="d-flex justify-content-between align-items-center border-bottom">
                                          <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            2.Would you participate in the 24/7
                                            ongoing discussions with specialist
                                            doctors and other people having
                                            medical challenges?
                                          </label>
                                          <span className="py-1 d-flex">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                              <label
                                                key={star}
                                                htmlFor={`star-${star}`}
                                                title={`${star} stars`}
                                                className="me-1"
                                              >
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                  style={{
                                                    color:
                                                      star <= MR2
                                                        ? "#FFD700"
                                                        : "#ccc",
                                                  }}
                                                ></i>
                                              </label>
                                            ))}
                                            <input
                                              type="hidden"
                                              name="rating"
                                              id="rating"
                                              value={rating}
                                              readOnly
                                            />
                                          </span>
                                        </div>
                                    
                                  </div>
                                </div>
                                <div className="col-lg-12 col-xl-12 col-12">
                                  <div className="mb-3">
                                   
                                        <div className="d-flex justify-content-between align-items-center border-bottom">
                                          <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            3. Would you submit questions to
                                            doctors?{" "}
                                          </label>
                                          <span className="py-1 d-flex">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                              <label
                                                key={star}
                                                htmlFor={`star-${star}`}
                                                title={`${star} stars`}
                                                className="me-1"
                                              >
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                  style={{
                                                    color:
                                                      star <= MR3
                                                        ? "#FFD700"
                                                        : "#ccc",
                                                  }}
                                                ></i>
                                              </label>
                                            ))}
                                            <input
                                              type="hidden"
                                              name="rating"
                                              id="rating"
                                              value={rating}
                                              readOnly
                                            />
                                          </span>
                                        </div>
                                      </div>
                                   
                                </div>
                                <div className="col-lg-12 col-xl-12 col-12">
                                  <div className="mb-3">
                                   
                                     
                                        <div className="d-flex justify-content-between align-items-center border-bottom">
                                          <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            4. Would you attend webinars offered
                                            by specialists in the fields (e.g.
                                            Cardiovasular, hypertension,
                                            diabetes, cancer, sexual health and
                                            reproduction, wellness and
                                            nutrition, mental health, well
                                            mother and child?)
                                          </label>
                                          <span className="py-1 d-flex">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                              <label
                                                key={star}
                                                htmlFor={`star-${star}`}
                                                title={`${star} stars`}
                                                className="me-1"
                                              >
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                  style={{
                                                    color:
                                                      star <= MR4
                                                        ? "#FFD700"
                                                        : "#ccc",
                                                  }}
                                                ></i>
                                              </label>
                                            ))}
                                            <input
                                              type="hidden"
                                              name="rating"
                                              id="rating"
                                              value={rating}
                                              readOnly
                                            />
                                          </span>
                                        </div>
                                     
                                  </div>
                                </div>

                                <div className="col-lg-12 col-xl-12 col-12">
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        5. What features would you like to see
                                        IN THE Medical Community Support Room?
                                      </label>
                                      <div className="d-flex gap-3 align-items-center">
                                        <div className="d-flex gap-4">
                                          <span className="d-flex align-items-center gap-1">
                                            <label
                                              className="form-check-label"
                                              htmlFor="useAivaYes"
                                            >
                                              {
                                                feedbackData?.features_want_to_see_medical_support_room
                                              }
                                            </label>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-md-6">
                                <button
                                  onClick={() => setStep("7")}
                                  className="submit-btn mt-3 w-100"
                                >
                                  Next
                                </button>
                              </div>
                              <div className=" col-md-6">
                                <button
                                  type="button"
                                  className="submit-btn mt-3 w-100"
                                  onClick={() => setStep("5")}
                                >
                                  Previous
                                </button>
                              </div>
                            </div>
                          </>
                        ) : step == "7" ? (
                          <>
                            <div className="mt-3">
                              <p className="font_22  font_600 text-blue  mb-0">
                                HOW MUCH WOULD YOU BE READY TO PAY MONTHLY TO
                                USE THE PLATFORM?
                              </p>
                              <hr className="mb-4" />
                              <div className="row">
                                <div className="col-lg-12 col-xl-12 col-12">
                                  <div className="mb-3">
                                    <div className="col-12">
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between align-items-center border-bottom">
                                          <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            1. What % of your total healthcare
                                            costs is NGN1000/month?
                                          </label>
                                          <span className="py-1 d-flex">
                                            {[
                                              { star: 1, label: "0-2%" },
                                              { star: 2, label: "2-4%" },
                                              { star: 3, label: "4-6%" },
                                              { star: 4, label: "6-8%" },
                                              { star: 5, label: ">10%" },
                                            ]
                                              .filter(
                                                (item) =>
                                                  item.star === parseInt(R1, 10)
                                              )
                                              .map((item) => (
                                                <div
                                                  key={item.star}
                                                  className="d-flex align-items-center me-2"
                                                >
                                                  <label 
                                                    htmlFor={`star-${item.star}`}
                                                    title={`${item.star} stars`}
                                                    className="me-1 "
                                                    style={{marginBottom:"0px"}}
                                                  >
                                                    <i
                                                      className="fa fa-star"
                                                      aria-hidden="true"
                                                      style={{
                                                        color: "#FFD700",
                                                      }}
                                                    ></i>
                                                  </label>
                                                  <p className="mb-0">
                                                    {item.label}
                                                  </p>
                                                </div>
                                              ))}
                                            <input
                                              type="hidden"
                                              name="rating"
                                              id="rating"
                                              value={R1}
                                              readOnly
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-xl-12 col-12">
                                  <div className="mb-3">
                                    <div className="col-12">
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between align-items-center border-bottom">
                                          <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            2. Would you pay N1000/month if it
                                            helped prevent illness and saved you
                                            N1250/month in healthcare costs?{" "}
                                          </label>
                                          <span className="py-1 d-flex">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                              <label
                                                key={star}
                                                htmlFor={`star-${star}`}
                                                title={`${star} stars`}
                                                className="me-1"
                                              >
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                  style={{
                                                    color:
                                                      star <= R2
                                                        ? "#FFD700"
                                                        : "#ccc",
                                                  }}
                                                ></i>
                                              </label>
                                            ))}
                                            <input
                                              type="hidden"
                                              name="rating"
                                              id="rating"
                                              value={rating}
                                              readOnly
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12 col-xl-12 col-12">
                                  <div className="mb-3">
                                    <div className="col-12">
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between align-items-center border-bottom">
                                          <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            3. Would you use this platform
                                            regularly?
                                          </label>
                                          <span className="py-1 d-flex">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                              <label
                                                key={star}
                                                htmlFor={`star-${star}`}
                                                title={`${star} stars`}
                                                className="me-1"
                                              >
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                  style={{
                                                    color:
                                                      star <= R3
                                                        ? "#FFD700"
                                                        : "#ccc",
                                                  }}
                                                ></i>
                                              </label>
                                            ))}
                                            <input
                                              type="hidden"
                                              name="rating"
                                              id="rating"
                                              value={rating}
                                              readOnly
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-xl-12 col-12">
                                  <div className="mb-3">
                                    <div className="col-12">
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between align-items-center border-bottom">
                                          <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            4. Would you recommend this platform
                                            to friends and family?
                                          </label>
                                          <span className="py-1 d-flex">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                              <label
                                                key={star}
                                                htmlFor={`star-${star}`}
                                                title={`${star} stars`}
                                                className="me-1"
                                              >
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                  style={{
                                                    color:
                                                      star <= R4
                                                        ? "#FFD700"
                                                        : "#ccc",
                                                  }}
                                                ></i>
                                              </label>
                                            ))}
                                            <input
                                              type="hidden"
                                              name="rating"
                                              id="rating"
                                              value={rating}
                                              readOnly
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row justify-content-center">
                              <div className="col-md-6">
                                <button
                                  onClick={() => setStep("8")}
                                  className="submit-btn mt-3 w-100"
                                >
                                  Next
                                </button>
                              </div>
                              <div className=" col-md-6">
                                <button
                                  type="button"
                                  className="submit-btn mt-3 w-100"
                                  onClick={() => setStep("6")}
                                >
                                  Previous
                                </button>
                              </div>
                            </div>
                          </>
                        ) : step == "8" ? (
                          <>
                            <div className="mt-3">
                              <p className="font_22  font_600 text-blue  mb-0">
                                GENERAL COMMENTS
                              </p>
                              <hr className="mb-4" />
                              <div className="col-lg-12 col-xl-12 col-12">
                                <div className="mb-3">
                                  <div className="d-flex justify-content-between  border-bottom">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      What are your “WOW” items you really love
                                      about the platform?
                                    </label>
                                    <span className="py-1">
                                      {feedbackData?.item_you_love_about_platform ||
                                        ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-xl-12 col-12">
                                <div className="mb-3">
                                  <div className="d-flex justify-content-between  border-bottom">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      What are your “NO-WAY” items you don't
                                      like and want to see improved
                                    </label>
                                    <span className="py-1">
                                      {feedbackData?.item_dont_like_but_improve ||
                                        ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-xl-12 col-12">
                                <div className="mb-3">
                                  <div className="d-flex justify-content-between  border-bottom">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      What factors do you think will help
                                      address our universal primary care
                                      objectives?
                                    </label>
                                    <span className="py-1">
                                      {feedbackData?.universal_primary_care_objectives ||
                                        ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-xl-12 col-12">
                                <div className="mb-3">
                                  <div className="d-flex justify-content-between  border-bottom">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      What works well on the platform?
                                    </label>
                                    <span className="py-1">
                                      {feedbackData?.what_works_well_on_platform ||
                                        ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-xl-12 col-12">
                                <div className="mb-3">
                                  <div className="d-flex justify-content-between  border-bottom">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      What does not work well on the platform?
                                    </label>
                                    <span className="py-1">
                                      {feedbackData?.what_does_works_well_on_platform ||
                                        ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-xl-12 col-12">
                                <div className="mb-3">
                                  <div className="d-flex justify-content-between  border-bottom">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      What else would you like to see as part of
                                      platform services?
                                    </label>
                                    <span className="py-1">
                                      {feedbackData?.part_of_platform_services ||
                                        ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-xl-12 col-12">
                                <div className="mb-3">
                                  <div className="d-flex justify-content-between  border-bottom">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      What would make you revisit the platform
                                      regularly?
                                    </label>
                                    <span className="py-1">
                                      {feedbackData?.why_visit_regular || ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-xl-12 col-12">
                                <div className="mb-3">
                                  <div className="d-flex justify-content-between  border-bottom">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      What would you like to change on these
                                      vitals and doctor apps?
                                    </label>
                                    <span className="py-1">
                                      {feedbackData?.changes_on_vitals_apps ||
                                        ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className=" col-md-6">
                                <button
                                  type="button"
                                  className="submit-btn mt-3 w-100"
                                  onClick={() => setStep("7")}
                                >
                                  Previous
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {/* <div className="row justify-content-center">
                          <div className="col-12 col-md-6">
                            <button type="submit" className="submit-btn mt-3 w-100">Submit</button>
                          </div>
                        </div> */}

                        {/* {step == "5" && <>
                        <div className="row justify-content-center">
                          <div className="col-12 col-md-6">
                            <button type="submit" className="submit-btn mt-3 w-100">Submit</button>
                          </div>
                        </div>
                      </>} */}
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FeedbackDetail;
