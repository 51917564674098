// inputFields.js
export const currencyOption = [{ value: "GEL", label: "GEL", symbol: "₾" }];

const InputFields = [
  {
    name: "count",
    type: "number",
    label: "QR Plate Count",
  },
  {
    name: "price",
    type: "number",
    label: "Price",
  },
  {
    name: "currency",
    type: "select",
    label: "Currency",
    innerWidth: "40px",
    outerHeight: "md",
    options:currencyOption
  },
  {
    name: "discount",
    type: "number",
    label: "Discount",
  },
];

export default InputFields;
