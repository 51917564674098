import React from "react";
import { DATE_FORMAT } from "helpers/common";


import { customSortCaret } from "constants";
import moment from "moment";

const STATUS = {
  active: "Active",
  inactive: "Inactive",
  blocked: "Blocked",
  archived: "Archived",
  pending: "Pending",
};

const Columns = ({data,authUser,currentPage,pageSize,handleEdithandler,handleViewUser}) => [
  {
    dataField: "srno",
    text: "SrNo",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (cell, row, rowIndex) => (
      <span className="text">{((currentPage - 1) * pageSize) + (rowIndex + 1)}</span>
    ),
  },
  
  {
    dataField: "email",
    text: "Email",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text">{row.email}</span>
    ),
  },
  {
    dataField: "subject",
    text: "Subject",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text">{row.subject}</span>
    ),
  },
  {
    dataField: "message",
    text: "Message",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text">{row.message}</span>
    ),
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    sortCaret: customSortCaret,
    formatter: (_, row) => (
      <span className="text">{row.status}</span>
    ),
  },
  

  {
    dataField: "createdAt",
    text: "Created At",
    sort: true,
    formatter: (_, row) => (
      <span className="text">
        {moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss ')}
      </span>
    )
  },
  
  {
    text: "Action",
    dataField: "",
    formatter: (_, row) => (
      <>
        <button
          className="fas fa-pen"
          style={{
            fontSize: "16px",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="Edit"
          onClick={() => handleEdithandler(row)}
        ></button>
        <button
          className="fa fa-eye"
          style={{
            fontSize: "20px",
            color: "green",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="View"
          onClick={() => handleViewUser(row)}
        ></button>
      </>
    ),
  },
];

export default Columns;
