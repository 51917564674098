import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { PATIENTDETAILLPAGE, SUPPORTVIEWS } from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";

import BackButtom from "components/BackButtom";
import { Badge, Col, Row } from "react-bootstrap";
import { userHistoryList } from "store/actions";
const MessageDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [data, setData] = useState();
  const { state } = useLocation();
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
  });
  useEffect(() => {
    fetchData(filter);
  }, [id]);
  const fetchData = () => {
    const payload = {
      ...filter,
      page: filter?.pageNo,
      record_count: filter?.limit,
      user_id: id,
    };
    try {
      const callback = (err, res) => {
        if (err) {
          console.log(err);
        } else {
          console.log(res, "response ------===--=-=---=----=");
          setData(res);
        }
      };
      dispatch(userHistoryList({ data: payload, callback }));
    } catch (error) {
      console.log(error);
    }
  };
  console.log(state.row,"gfhjdgfjdfgjdg")
  return (
    <>
      {data === "true" && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={SUPPORTVIEWS.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <BackButtom />
          <h1>{SUPPORTVIEWS.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.patientview}>Patient</Link>
            </div>
            <div className="breadcrumb-item">
              {SUPPORTVIEWS.CURRENT_MODULE}{" "}
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{SUPPORTVIEWS.TOP_CONTENT}</h2>
                </div>
              </div>
            </div>
          </div>
          <Row className="px-4 justify-content-center">
            <Col className=" col-md-12 my-2 h-100 rounded-lg">
              <div className="col-md-6 my-2 h-100 card">
                <div className="d-flex justify-content-center border-bottom">
                  <h6 className=" p-2 ">Support Details</h6>
                </div>
                <div className="d-flex justify-content-between  border-bottom">
                  <p className="fw-bolder">Email</p>
                  <span className="py-1">{state?.row?.email}</span>
                </div>
                <div className="d-flex justify-content-between gap-2  border-bottom">
                  <p className="fw-bolder">Subject</p>
                  <span className="py-1">{state?.row?.subject}</span>
                </div>
                <div className="d-flex justify-content-between  border-bottom">
                  <p className="fw-bolder">Message </p>
                  <span className="py-1">{state?.row?.message}</span>
                </div>
                <div className="d-flex justify-content-between  border-bottom">
                  <p className="fw-bolder">Status</p>
                  <span className="py-1">{state?.row?.status}</span>
                </div>
                <div className="d-flex justify-content-between  border-bottom">
                  <p className="fw-bolder">Image</p>
                  <span className="py-1">{state?.row?.file&&<img
                      src={state?.row?.file}
                      alt="profileImage"
                      className="img-fluid p-2 rounded "
                      style={{ width: "200px", height: "200px" }}
                    />} </span>
                </div>
               
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};
export default MessageDetails;
