import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
CHEMIST: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "CHEMIST",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getChemistList.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getChemistList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      CHEMIST: payload,
      totalCount: payload.total_record_count,
    }));
    builder.addCase(Act.getChemistList.rejected, (state, action) => ({
      ...state,
      loading: false,
      CHEMIST: [],
      totalCount: 0,
    }));
    builder.addCase(Act.ChemistEdit.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.ChemistEdit.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(Act.ChemistEdit.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
    // builder.addCase(Act.userHistoryList.pending, (state, action) => ({
    //   ...state,
    //   loading: true,
    // }));
    // builder.addCase(Act.userHistoryList.fulfilled, (state, { payload }) => ({
    //   ...state,
    //   loading: false,
    //   USER: payload,
    //   // totalCount: payload.total_record_count,
    // }));
    // builder.addCase(Act.userHistoryList.rejected, (state, action) => ({
    //   ...state,
    //   loading: false,
    //   USER: [],
    //   totalCount: 0,
    // }));
  },
});

export default slice;
