import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { QRCREATE } from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";
import Table from "components/Table";
import { toast } from "react-toastify";
import Columns from "./Columns";
import {
  CreateQR,
  DownloadQrlink,
  QRDETAILS,
  getSettings,
} from "store/actions";
import QrLinkProfile from "components/Modals/QrLinkProfile";
const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search using Qr Code Id.....",
  },
];
const initialValuesForFilter = {
  search: "",
};
const QrCodeCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createqr, loading } = useSelector((state) => state?.CreateQr);
  const { settings } = useSelector((state) => state.Settings);

  const [qrData, setQrData] = useState([]);
  const [columns, setColumns] = useState();
  const [addModalShow, setAddModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: 20,
    order: 1,
    orderBy: "dateCreatedUtc",
    search: "",
  });
  const [qrCodeId, setQrCodeId] = useState(null);
  useEffect(() => {
    dispatch(getSettings());
  }, []);
  const API_IMAGE_URL = settings?.s3BaseUrl;
  console.log(API_IMAGE_URL, "API_IMAGE_URL");
  const handleClick = () => {
    try {
      const callback = (err, res) => {
        console.log(res, "response qr create");
        if (err) {
          toast.error(err.message);
        } else {
          console.log(res);
          toast.success("Created Qr Successfully");
          fetchData(filter);
        }
      };
      dispatch(CreateQR({ callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const fetchData = async (filter) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setQrData(res);
          toast.success(res.message);
        }
      };
      dispatch(QRDETAILS({ data: filter, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  useEffect(() => {
    fetchData(filter);
    setColumns(Columns({ handlelinkQr, API_IMAGE_URL: settings?.s3BaseUrl }));
  }, [filter, settings]);
  const onClickFilter = (e) => {
    e?.preventDefault();
    setFilter({
      ...filter,

      search: search,
    });
  };

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      pageNo: 1,
      orderBy: "dateCreatedUtc",
      search: info.search,
    });
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const qrDataList = qrData?.map((item) => ({
    ...item,
    link: API_IMAGE_URL + item?.link,
  }));
  const handleClick1 = async () => {
    dispatch(DownloadQrlink({ callback }));
  };
  const callback = (err, res) => {
    if (err) {
      console.error(err);
      toast.error(err);
    } else {
      toast.success(res.message);
      console.log(res.message);
    }

    const url = res;

    window.open(url, "_blank");
  };

  const handlelinkQr = (id) => {
    setQrCodeId(id);
    console.log(id, "qr id======");
    setAddModalShow(true);
  };
  const clearFilter = () => {
    setFilter((prev) => ({
      ...prev,
      search: "",
    }));

    setSearch("");
  };

  const handleSubmit_ = () => {};

  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}

      <QrLinkProfile
        show={addModalShow}
        handleClose={() => {
          setAddModalShow(false);
        }}
        title={"Link Qr Profile"}
        content={""}
        handleConfirm={handleSubmit_}
        loading={loading}
        initialValues={"formData"}
        id={qrCodeId}
      />
      <Helmet title={QRCREATE?.CURRENT_MODULE} />

      <section className="section">
        <div className="section-header">
          <h1>{QRCREATE?.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>QR Create</Link>
            </div>
            <div className="breadcrumb-item">{QRCREATE?.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{QRCREATE?.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button className="btn btn-primary" onClick={handleClick}>
                    QR CREATE
                  </button>
                </div>
              </div>
              <div className="col-6">
                <form onSubmit={onClickFilter}>
                  <div className="card-header">
                    <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                      <input
                        className="form-control"
                        name="search"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        placeholder="Search using name, email..."
                      />
                    </div>
                  </div>
                  <div className="col-12 my-2 gap-4 ">
                    <div className="d-flex gap-4">
                      <button type="submit" className="btn btn-primary btn-lg">
                        Search
                      </button>
                      <button
                        onClick={() => clearFilter()}
                        type="btn"
                        className="btn btn-primary btn-lg"
                      >
                        Clear filter
                      </button>
                    </div>
                  </div>
                </form>

                {/* <div className="card-header w-100 ">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                  />
                </div> */}
              </div>
              <div className="col-6">
                <div className=" d-flex py-4 px-2 justify-content-end ">
                  <button className="btn btn-primary" onClick={handleClick1}>
                    DownloadSvgQrZip
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!loading && createqr?.data?.length > 0 && (
            <Table
              data={qrDataList}
              columns={columns}
              setFilter={setFilter}
              totalCount={createqr?.totalcount}
              handleTableChange={handlePageChange}
              filter={filter}
            />
          )}

          {!loading && createqr?.data?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Qr data Found</p>
          )}
        </div>
      </section>
    </>
  );
};
export default QrCodeCreate;
