import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  SUPPORTDATA: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "SUPPORTDATA",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getSupportListData.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getSupportListData.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      SUPPORTDATA: payload,
      totalCount: payload.total_record_count,
    }));
    builder.addCase(Act.getSupportListData.rejected, (state, action) => ({
      ...state,
      loading: false,
      SUPPORTDATA: [],
      totalCount: 0,
    }));
    builder.addCase(Act.SupportEdit.pending, (state, action) => ({
        ...state,
        loading: true,
      }));
      builder.addCase(Act.SupportEdit.fulfilled, (state, { payload }) => ({
        ...state,
        loading: false,
      }));
      builder.addCase(Act.SupportEdit.rejected, (state, action) => ({
        ...state,
        loading: false,
      }));
   
  },
});

export default slice;
